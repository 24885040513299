import { MailOutline, PersonOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { GROUP_CHECKBOXES, RADIO, TEXT } from "../../../components";
// eslint-disable-next-line
const emailRegex = new RegExp("[a-z0-9]+@[a-z0-9]+.[a-z]{2,3}");
export const dutyOfCareFormConfig = [
  {
    sectionTitle: (
      <Typography variant="h5">Risk as a factor of Duty of Care</Typography>
    ),
    key: "Risk as a factor of Duty of Care",
    description:
      "Please indicate whether pre-production or filming will involve the following:",
    formFields: [
      {
        type: RADIO,
        label:
          "How much control does the production team have over the participants' situation?",
        id: "degreeOfControl",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "No Control - Observational  Documentary / Actuality",
            value: "Actuality",
          },
          {
            label:
              "Some Control - Actuality that has a degree of being produced or directed",
            value: "ProducedActuality",
          },
          {
            label: "Total Control - Constructed reality",
            value: "ConstructedReality",
          },
          {
            label: "Total Control - Scripted drama / Drama reconstruction",
            value: "TotalControl",
          },
        ],
      },
      {
        type: GROUP_CHECKBOXES,
        label:
          "Who are your on-screen participants and are any of them vulnerable? Please select all that apply.",
        id: "participantDemographic",
        vertical: true,
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "Participants are 'celebrities' or professional actors who already have a public image and have access to personal management/representation",
            value: "Celebrity",
          },
          {
            label:
              "Participants are 'celebrities' or professional actors but may be considered a vulnerable person",
            value: "VulnerableCeleb",
          },
          {
            label:
              "Participants are not widely known by the public and any vulnerabilities are unknown that this stage",
            value: "GeneralPublic",
          },
          {
            label:
              "Participants are not widely known by the public, but have an active social media presence which is important to them, and any vulnerabilities are unknown that this stage",
            value: "Influencer",
          },
          {
            label:
              "Participants are not widely known by the public and are considered a vulnerable person",
            value: "VulnerableGeneralPublic",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Does the show's content potentially expose on-screen  participants to confrontation, emotional challenges, or surprises? For example, if a programme involves emotionally challenging or traumatic story lines.",
        id: "exposure",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "Low exposure to emotionally challenging situations or subject matter/material, with no unexpected/surprise elements",
            value: "Low",
          },
          {
            label:
              "Some exposure to emotionally challenging situations or subject matter/material, or an unexpected/surprise element and material that might cause some temporary discomfort",
            value: "Medium",
          },
          {
            label:
              "Exposure to potential confrontation, emotionally challenging and unexpected situations and/or strong subject matter/material",
            value: "High",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Is the programme filmed in a remote, mentally challenging or isolating location?",
        id: "participantLocation",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "On-screen participants are not required to stay away from home for any period of time",
            value: "Home",
          },
          {
            label:
              "May be away from home for more than a night, but not in a remote location or in shared accommodation, and will have access to normal support network",
            value: "AwayWithHomeContact",
          },
          {
            label:
              'On-screen participants required to live away from home without access to normal support network, or in a potentially "alien" environment or in shared accommodation for a sustained period of time',
            value: "Away",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Are your on-screen participants housed in shared accommodation with other participants, strangers, or potentially incompatible individuals?",
        id: "livingArrangements",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Participants are not required to live away from home",
            value: "Alone",
          },
          {
            label:
              "Participants are provided with a private accomodation/hotel",
            value: "Private",
          },
          {
            label:
              "Participants have a private bedroom within a shared accomodation",
            value: "Shared",
          },
          {
            label: "A shared bedroom with another participant",
            value: "Dormitory",
          },
        ],
      },
      {
        type: RADIO,
        label: "What is the popularity/profile of the programme?",
        id: "popularityProfile",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "Low public, press or social media interest in the participants &/or the programme",
            value: "Low",
          },
          {
            label:
              "Some public, press or social media interest in the participants &/or the programme",
            value: "Medium",
          },
          {
            label:
              "High level of public, press or social media interest in the participants &/or the programme",
            value: "High",
          },
          {
            label:
              "Production actively encourages the viewers to engage with the production via social media",
            value: "Active",
          },
        ],
      },
      {
        type: TEXT,
        label:
          "Please detail below if you are working with a Mental Health Advisor on this production:",
        id: "mentalHealthAdvisor",
        placeholder: "Forename Surname",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        icon: <PersonOutline />,
      },
      {
        type: TEXT,
        id: "mentalHealthAdvisorEmail",
        placeholder: "example@itv.com",
        validationRegex: emailRegex,
        helperText: "Please enter a valid email",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        icon: <MailOutline />,
      },
    ],
  },
];
