import { FC, useMemo } from "react";
import { Container, Stack } from "@mui/system";
import { useParams } from "react-router-dom";
import { Paper, Typography, Chip } from "@mui/material";
import {
  FormStatusProps,
  FromStatus,
} from "../../containers/NotificationForm/FormStatus";
import { TITLES, SERIES } from "../../constants";
import { useSeries, useTitleMetadata, useProductionDetails } from "../../hooks";
import StudiosBreadcrumbs from "../../componentsV2/StudiosBreadcrumbs/StudiosBreadcrumbs";

export interface FormHeaderProps {
  formTitle: string;
  summary?: FormStatusProps["summary"];
  showFormStatus?: boolean;
  showFormSubmission?: boolean;
  isFormSubmitted?: boolean;
  isFormProvisional?: boolean;
}

export const FormHeader: FC<FormHeaderProps> = ({
  formTitle,
  summary,
  showFormStatus = false,
  showFormSubmission = false,
  isFormSubmitted,
  isFormProvisional,
}) => {
  const { programmeCcid, seriesCcid, titleCcid } = useParams();
  const { data: seriesData } = useSeries(programmeCcid, false);
  const { data: titleData } = useTitleMetadata(titleCcid);
  const type = titleCcid ? TITLES : SERIES;
  const ccid = titleCcid || seriesCcid;
  const { data: productionData, isLoading } = useProductionDetails(ccid, type);
  const provisionalLabel = isFormProvisional ? "Provisional" : "Final";

  const matchedSeries = useMemo(
    () =>
      seriesData?.seriesDetails?.find((series) => series.ccid === seriesCcid),
    [seriesCcid, seriesData?.seriesDetails],
  );

  const seriesName = useMemo(
    () =>
      matchedSeries?.seriesNumber
        ? `Series ${matchedSeries?.seriesNumber} - `
        : "",
    [matchedSeries],
  );

  const titleName = useMemo(() => {
    let result = "";

    if (titleData?.episodeNumber) {
      result += `Episode ${titleData.episodeNumber} - `;
    }

    if (titleData?.titleName) {
      result += `${titleData.titleName} - `;
    }

    return result;
  }, [titleData]);

  const coreCatData = productionData?.basicInfo.coreCatalogueData;
  return (
    <Paper sx={{ pt: 2, mb: 2, borderRadius: 0 }}>
      <Container fixed disableGutters>
        <StudiosBreadcrumbs />
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" marginY={2}>
            {seriesName}
            {titleName}
            {formTitle}
          </Typography>

          {showFormStatus && summary && <FromStatus summary={summary || {}} />}
          {showFormSubmission && isFormSubmitted && (
            <Chip color="primary" label={`${provisionalLabel} - Submitted`} />
          )}
          {showFormSubmission && !isFormSubmitted && (
            <Chip
              sx={{ color: "white", bgcolor: "rgba(2, 136, 209, 1)" }}
              label={`${provisionalLabel} - Unsubmitted`}
            />
          )}
        </Stack>
        {isLoading || !coreCatData ? (
          <></>
        ) : (
          <Stack direction="row" spacing={2} paddingBottom="20px">
            <div>
              <Typography display="inline">Programme: &nbsp;</Typography>
              {coreCatData.brandName || coreCatData.titleName}
            </div>
            <div>
              <Typography display="inline">Genre: &nbsp;</Typography>
              {coreCatData.genre}
            </div>
            <div>
              <Typography display="inline">
                {coreCatData.seriesId ? "Series" : "Episode"} id: &nbsp;
              </Typography>
              {coreCatData.seriesId || coreCatData.episodeId}
            </div>
            {coreCatData.numberOfEpisodes ? (
              <div>
                <Typography display="inline">
                  Number of episodes: &nbsp;
                </Typography>
                {coreCatData.numberOfEpisodes}
              </div>
            ) : (
              <></>
            )}
            {coreCatData.intendedDurationMinutes ? (
              <div>
                <Typography display="inline">
                  {type === SERIES
                    ? "Duration of first Episode"
                    : "Episode duration"}
                  : &nbsp;
                </Typography>
                {coreCatData.intendedDurationMinutes}
              </div>
            ) : (
              <></>
            )}
          </Stack>
        )}
      </Container>
    </Paper>
  );
};
