/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { DynamicForm, FormHeader, SideNav } from "../../components";
import { SERIES, TITLES } from "../../constants";
import { useMetadataDropdown } from "../../hooks";
import { useUpdateCloseDown } from "../../hooks/mutations/useUpdateCloseDown";
import { useCloseDown } from "../../hooks/queries/useCloseDown";
import {
  getLatestDate,
  initSelectOptions,
  transFormDataBaseOnTypeAndValue,
} from "../NotificationForm/notificationForm.helper";
import {
  productionFormConfig,
  budgetAndScheduleLearningFormConfig,
  retentionFormConfig,
  riskLearningFormConfig,
  sustainabilityFormConfig,
} from "./formConfigs";
import theme from "../../utils/theme";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

const initialGroupMenuSetting: any = [
  {
    label: "Production",
    id: "production",
    formConfig: productionFormConfig,
  },
  {
    label: "Budget & Schedule Learnings",
    id: "budget",
    formConfig: budgetAndScheduleLearningFormConfig,
  },
  {
    label: "Risk Learnings",
    id: "riskLearnings",
    formConfig: riskLearningFormConfig,
  },
  {
    label: "Sustainability",
    id: "sustainability",
    formConfig: sustainabilityFormConfig,
  },
  {
    label: "Retention",
    id: "retention",
    formConfig: retentionFormConfig,
  },
];

export const CloseDownForm: FC = () => {
  const navigate = useNavigate();
  const { seriesCcid, titleCcid, programmeCcid } = useParams();
  const type = titleCcid ? TITLES : SERIES;
  const ccid = titleCcid || seriesCcid;

  const redirectUrl =
    type === TITLES
      ? `/special/${ccid}`
      : `/programmes/${programmeCcid}/series/${seriesCcid}`;

  const [currentTab, setCurrentTab] = useState("production");
  const formContainer = useRef<HTMLDivElement>();
  const [groupMenuSetting, setGroupMenuSetting] = useState(
    initialGroupMenuSetting,
  );
  const initialFormData = useMemo(
    () =>
      initialGroupMenuSetting.reduce((acc: any, current: any) => {
        acc[current.id] = {};
        return acc;
      }, {}),
    [],
  );
  const [formData, setFormData] = useState<any>(initialFormData);
  const [step, setStep] = useState<number>(1);
  const [formConfig, setFormConfig] = useState<any>(
    initialGroupMenuSetting[0].formConfig,
  );
  const [autoSaveAt, setAutoSaveAt] = useState<string>("");

  const { data: metadataDropdown } = useMetadataDropdown();
  const { data: closeDownFormData } = useCloseDown(ccid, type);

  const submitHandler = useCallback(() => {
    navigate(redirectUrl);
  }, [navigate, redirectUrl]);

  const valueChangeHandler = useCallback(
    (id: string | number, newValue: any, currentFormData: any) => {
      const copyFromData = { ...currentFormData };

      if (!copyFromData[currentTab]) {
        copyFromData[currentTab] = {};
      }

      copyFromData[currentTab][id] = newValue;

      setFormData(copyFromData);
    },
    [currentTab],
  );

  const setAutoSaveDate = useCallback((response: any) => {
    const latestDate = getLatestDate(response) || new Date().toISOString();
    setAutoSaveAt(latestDate);
  }, []);

  const { mutate: updateCloseDown, isLoading: isSubmitting } =
    useUpdateCloseDown(
      (response) => {
        setAutoSaveDate(response);
        enqueueSnackbar("Content Autosaved", { variant: "success" });
      },
      () => {
        enqueueSnackbar("There is an error on saving the form data", {
          variant: "error",
          action: SnackbarDismiss,
        });
      },
    );

  const blurHandler = useCallback(
    (id: any, newValue: any) => {
      // code to save update in the backend.
      let matchedFormField;

      for (const { formFields } of formConfig) {
        for (const formField of formFields) {
          if (formField.id === id) {
            matchedFormField = formField;
            break;
          }
        }

        if (matchedFormField) {
          break;
        }
      }

      if (matchedFormField) {
        const changes: any = {};

        changes[currentTab] = {
          [id]: transFormDataBaseOnTypeAndValue(matchedFormField, newValue),
        };
        updateCloseDown({ ccid, formData: changes, type });
      }
    },
    [ccid, currentTab, formConfig, type, updateCloseDown],
  );

  useEffect(() => {
    if (metadataDropdown) {
      initSelectOptions(metadataDropdown, groupMenuSetting);
      setGroupMenuSetting([...groupMenuSetting]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadataDropdown]);

  useEffect(() => {
    setStep(groupMenuSetting.findIndex(({ id }: any) => id === currentTab) + 1);
  }, [currentTab, groupMenuSetting]);

  useEffect(() => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    setFormConfig([...groupMenuSetting[step - 1]?.formConfig]);
  }, [groupMenuSetting, step]);

  useEffect(() => {
    if (formContainer.current) {
      formContainer.current.scrollTop = 0;
    }
  }, [currentTab]);

  useEffect(() => {
    if (closeDownFormData) {
      setFormData(closeDownFormData);
    }
  }, [closeDownFormData]);

  return (
    <>
      <FormHeader formTitle="Close-down form" />
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 186px)",
          overflowY: "auto",
        }}
        ref={formContainer}
      >
        <Container fixed disableGutters>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SideNav
                formData={formData}
                isSubmitting={false}
                groupMenuSetting={groupMenuSetting}
                onStepChange={(tab: string) => {
                  setCurrentTab(tab);
                }}
                submitButtonLabel="Return To Task Page"
                autoSaveAt={autoSaveAt}
                activeStep={currentTab}
                onSubmitForm={submitHandler}
              />
            </Grid>
            <Grid item xs={9}>
              <Box paddingBottom={2}>
                <Typography fontSize="1.5rem" marginTop="0.5rem">
                  Section {step} of {groupMenuSetting.length}:{" "}
                  {groupMenuSetting[step - 1]?.label}{" "}
                </Typography>
                <Box marginTop="1rem" paddingBottom="1.5rem">
                  <DynamicForm
                    config={formConfig}
                    onValueChange={(id, newValue) =>
                      valueChangeHandler(id, newValue, formData)
                    }
                    onBlur={(id, newValue) => {
                      blurHandler(id, newValue);
                    }}
                    formData={formData?.[currentTab] || {}}
                  />
                  <Typography fontStyle="italic" marginTop="1rem">
                    Indicates a required field{" "}
                    <Typography
                      display="inline-block"
                      color={theme.palette.sunsetOrange}
                    >
                      *
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
