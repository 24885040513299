import dayjs from "dayjs";
import * as Yup from "yup";

function validateDate(value: string) {
  if (!value) return true;

  try {
    const date = dayjs(value, "YYYY-MM-DD");
    return date.isValid();
  } catch (_) {
    return false;
  }
}

export const basicInfoSchemaV3 = Yup.object().shape({
  narrative: Yup.string().required("This is a required field"),
  productionStatus: Yup.string().required("This is a required field"),
  productionType: Yup.string().required("This is a required field"),
  otherProductionType: Yup.string().when(
    "$basicInfo.productionType",
    ([productionType], schema) => {
      if (productionType === "Other") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  broadcaster: Yup.string().required("This is a required field"),
  otherBroadcaster: Yup.string().when(
    "$basicInfo.broadcaster",
    ([broadcaster], schema) => {
      if (broadcaster === "Other") {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  contentType: Yup.string().required("This is a required field"),
  recordingType: Yup.string(),
  currency: Yup.string().required("This is a required field"),
  budget: Yup.number().required("This is a required field"),
  specialPurposeVehicle: Yup.boolean().required("This is a required field"),
  specialPurposeVehicleName: Yup.string().when(
    "$basicInfo.specialPurposeVehicle",
    ([specialPurposeVehicle], schema) => {
      if (specialPurposeVehicle) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  castEstimate: Yup.string().required("This is a required field"),
  crewEstimate: Yup.string().required("This is a required field"),
  creativeLead: Yup.string().required("This is a required field"),
  creativeLeadEmail: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  productionContact: Yup.string().required("This is a required field"),
  productionContactEmail: Yup.string()
    .email("Invalid email")
    .required("This is a required field"),
  preProductionDate: Yup.string()
    .required("This is a required field")
    .test("validDate", "Invalid date", validateDate),
  principalStartDate: Yup.string()
    .required("This is a required field")
    .test("validDate", "Invalid date", validateDate),
  principalFinishDate: Yup.string()
    .required("This is a required field")
    .test("validDate", "Invalid date", validateDate),
  startPostDate: Yup.string()
    .required("This is a required field")
    .test("validDate", "Invalid date", validateDate),
  productionCloseDate: Yup.string()
    .required("This is a required field")
    .test("validDate", "Invalid date", validateDate),
  additionalInfo: Yup.string(),
  spotId: Yup.string(),
});

export type BasicInfoSectionV3 = Yup.InferType<typeof basicInfoSchemaV3>;
