import { FC } from "react";
import {
  Autocomplete,
  TextField,
  AutocompleteRenderInputParams,
} from "@mui/material";
import { FieldProps } from "formik";

interface Option {
  value: string;
  label: string;
}

interface AutocompleteProps extends FieldProps {
  options: string[] | Option[];
  label: string;
  multiple: boolean;
  handleAutoSaveBlur?: (id: string, value: string) => void;
  disabled: boolean;
  readOnly: boolean;
}

interface ErrorType {
  value?: string;
}

export const CustomAutocompleteField: FC<AutocompleteProps> = ({
  field,
  options,
  form: { touched, errors, setFieldValue, handleBlur },
  label,
  multiple,
  handleAutoSaveBlur,
  disabled,
  readOnly,
}) => {
  function getError(): string | undefined {
    if (typeof errors[field.name] === "object") {
      const nestedError = errors[field.name] as ErrorType;
      return nestedError.value;
    }
    if (typeof errors[field.name] === "string") {
      return errors[field.name]?.toString();
    }
    return undefined;
  }

  return (
    <Autocomplete
      options={options}
      value={field.value}
      multiple={multiple}
      disabled={disabled}
      size="small"
      readOnly={readOnly}
      onChange={(_, option) => {
        setFieldValue(field.name, option);
      }}
      onBlur={(e) => {
        if (handleAutoSaveBlur) handleAutoSaveBlur(field.name, field.value);

        handleBlur(e);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          required
          name={field.name}
          label={label}
          size="small"
          error={touched[field.name] && !!errors[field.name]}
          helperText={
            touched[field.name] && !!errors[field.name] ? getError() : " "
          }
        />
      )}
    />
  );
};
