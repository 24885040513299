import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { CreateCcidFormDataOld, ProductionType, User } from "../../types/types";
import ProductionTypePage from "./ProductionTypePage";
import IsExistingProgrammePage from "./IsExistingProgrammePage";
import FindProgrammePage from "./FindProgrammePage";
import InputNamePage from "./InputNamePage";
import GetInTouchPage from "./GetInTouchPage";
import { CcidCreationForm } from "./CcidCreationForm";
import { sendContactMsg } from "../../api/portal";
import { useBrands } from "../../hooks/queries";
import ExistingProgrammeFoundPage from "./ExistingProgrammeFoundPage";
import { useCreateCCID } from "../../hooks";
import { AddProductionLead } from "./AddProductionLead";
import axios from "axios";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

export interface CreateCcidProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}
type Page =
  | "PRODUCTION_TYPE"
  | "IS_EXISTING_PROGRAMME"
  | "FIND_EXISTING"
  | "INPUT_PRODUCTION_NAME"
  | "EXISTING_PROGRAMME_FOUND"
  | "FORM"
  | "GET_IN_TOUCH"
  | "ADD_PRODUCTION_LEAD";

const initialFormData = {
  productionYear: new Date().getFullYear(),
  intendedSlotLength: undefined,
  name: "",
  brandName: "",
  productionLabels: [],
  commissioner: "",
  genre: "",
  whoIsThisFor: "",
  subgenres: [],
  specialName: "",
  count: "",
  sequence: "1",
};
const snackbarDuration = 3000;

const CreateCcid: React.FC<CreateCcidProps> = ({ open, setOpen }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [page, setPage] = useState<Page>("PRODUCTION_TYPE");
  const [productionType, setProductionType] = useState<ProductionType | null>(
    null,
  );
  const [existingProgramme, setExistingProgramme] = useState<boolean | null>(
    null,
  );
  const [searchInitiated, setSearchInitiated] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [productionName, setProductionName] = useState<string>("");
  const [formData, setFormData] =
    useState<CreateCcidFormDataOld>(initialFormData);
  const [productionLead, setProductionLead] = useState<User | undefined>();
  const { data: searchResults, isLoading: isLoadingSearchResults } = useBrands({
    searchTerm,
    includeOpportunity: true,
    filterByUserLabels: true,
  });

  // Contact us info
  const [nameOfUser, setNameOfUser] = useState<string>("");
  const [contactUsMessage, setContactUsMessage] = useState<string>("");
  const submitContactUsMessage = () => {
    sendContactMsg({
      name: nameOfUser,
      msg: `I can't seem to find the ${productionType === "oneOff" ? "one off" : productionType} I'm looking for.\n\n`.concat(
        contactUsMessage,
      ),
    })
      .then(() => setOpen(false))
      .catch();
  };

  const { mutate: createCCID, isLoading: createCCIDLoading } = useCreateCCID(
    (data) => {
      enqueueSnackbar(`${productionType} created`, {
        variant: "success",
        autoHideDuration: snackbarDuration,
      });
      setOpen(false);
      if (data) {
        if (productionType === "film" || productionType === "oneOff")
          navigate(`/special/${data.ccid}`);
        if (productionType === "series" || productionType === "pilot")
          navigate(
            `/programmes/${data.brandCcid}/series/${data.seriesCcidList[data.seriesCcidList.length - 1]}`,
          );
        if (productionType === "special") navigate(`/programmes/${data.ccid}`);
      }
    },
    (error) => {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        enqueueSnackbar(
          `You don't have permission to create a ${productionType} for producer(s) ${formData.productionLabels}`,
          {
            variant: "error",
            action: SnackbarDismiss,
            autoHideDuration: snackbarDuration,
          },
        );
      } else {
        enqueueSnackbar(
          `There is an error on creating your ${productionType}, please contact us.`,
          {
            variant: "error",
            action: SnackbarDismiss,
            autoHideDuration: snackbarDuration,
          },
        );
      }
    },
  );

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setProductionType(null);
      setExistingProgramme(null);
      setSearchInitiated(false);
      setSearchTerm("");
      setProductionName("");
      setFormData(initialFormData);
      setProductionLead(undefined);
      setPage("PRODUCTION_TYPE");
    }, 200); // make sure the modal is closed before clearing the ui
  };

  function isFormDataValid(incomingFormData: CreateCcidFormDataOld): boolean {
    if (
      !incomingFormData.productionLabels.length ||
      !incomingFormData.commissioner ||
      !incomingFormData.genre ||
      !incomingFormData.subgenres ||
      incomingFormData.subgenres.length < 1 ||
      !incomingFormData.intendedSlotLength ||
      !incomingFormData.productionYear
    )
      return false;

    // Optional fields
    if (productionType === "series" || productionType === "pilot") {
      if (
        incomingFormData.sequence === undefined ||
        Number(incomingFormData.sequence) <= 0 ||
        incomingFormData.count === undefined ||
        Number(incomingFormData.count) <= 0
      )
        return false;
    }

    if (productionType === "special") {
      if (!incomingFormData.specialName) return false;
    }
    if (
      incomingFormData.commissioner === "ITV" &&
      !incomingFormData.whoIsThisFor
    )
      return false;

    return true;
  }

  const handleFormSubmission = () => {
    const formattedFormData = {
      ...formData,
      whoIsThisFor: formData.whoIsThisFor || "OFF_ITV",
      productionYear: Number(formData.productionYear),
      intendedSlotLength: Number(formData.intendedSlotLength),
      count: Number(formData.count),
      sequence: Number(formData.sequence),
      productionLead: productionLead
        ? {
            name: `${productionLead.firstName} ${productionLead.lastName}`,
            email: productionLead.email,
          }
        : undefined,
    };

    if (
      productionType === "series" ||
      productionType === "special" ||
      productionType === "pilot"
    )
      formattedFormData.brandName = productionName;
    else formattedFormData.name = productionName;

    if (productionType)
      createCCID({ formData: formattedFormData, productionType });
  };

  const searchForExistingProgramme = () => {
    setSearchInitiated(true);
    setSearchTerm(productionName);
  };

  useEffect(() => {
    if (
      !isLoadingSearchResults &&
      searchResults !== undefined &&
      searchInitiated
    ) {
      if (searchResults.results.length === 0) setPage("FORM");
      else if (page === "INPUT_PRODUCTION_NAME")
        setPage("EXISTING_PROGRAMME_FOUND");
      setSearchInitiated(false);
    }
  }, [searchResults, isLoadingSearchResults, searchInitiated, page]);

  useEffect(() => {
    if (productionType === "pilot" && formData.count === "")
      setFormData({ ...formData, count: "1" });
  }, [productionType, formData]);

  const handleNextPage = () => {
    if (
      page === "PRODUCTION_TYPE" &&
      (productionType === "series" || productionType === "special")
    )
      setPage("IS_EXISTING_PROGRAMME");
    if (
      page === "PRODUCTION_TYPE" &&
      (productionType === "oneOff" || productionType === "film")
    )
      setPage("INPUT_PRODUCTION_NAME");
    if (page === "PRODUCTION_TYPE" && productionType === "pilot")
      setPage("INPUT_PRODUCTION_NAME");
    if (page === "IS_EXISTING_PROGRAMME" && existingProgramme)
      setPage("FIND_EXISTING");
    if (page === "IS_EXISTING_PROGRAMME" && !existingProgramme)
      setPage("INPUT_PRODUCTION_NAME");
    if (page === "INPUT_PRODUCTION_NAME") searchForExistingProgramme();
    if (page === "FIND_EXISTING") setPage("GET_IN_TOUCH");
    if (page === "EXISTING_PROGRAMME_FOUND") setPage("FORM");
    if (page === "FORM") setPage("ADD_PRODUCTION_LEAD");
  };

  const handlePreviousPage = () => {
    if (page === "IS_EXISTING_PROGRAMME") {
      setPage("PRODUCTION_TYPE");
      setExistingProgramme(null);
    }
    if (page === "FIND_EXISTING") setPage("IS_EXISTING_PROGRAMME");
    if (page === "INPUT_PRODUCTION_NAME") {
      if (existingProgramme === false) setPage("IS_EXISTING_PROGRAMME");
      else setPage("PRODUCTION_TYPE");
      setProductionName("");
    }
    if (page === "GET_IN_TOUCH") setPage("FIND_EXISTING");
    if (page === "EXISTING_PROGRAMME_FOUND") setPage("INPUT_PRODUCTION_NAME");

    if (page === "FORM" && searchResults !== undefined) {
      setFormData(initialFormData);
      setProductionLead(undefined);
      if (searchResults.results.length === 0) setPage("INPUT_PRODUCTION_NAME");
      if (searchResults.results.length > 0) setPage("EXISTING_PROGRAMME_FOUND");
    }
    if (page === "ADD_PRODUCTION_LEAD") setPage("FORM");
  };

  const dialogTitle = () => {
    switch (page) {
      case "PRODUCTION_TYPE":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            Let's create something new
          </Typography>
        );
      case "IS_EXISTING_PROGRAMME":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            Does this belong to an existing programme?
          </Typography>
        );
      case "INPUT_PRODUCTION_NAME":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            Create a new production
          </Typography>
        );
      case "FIND_EXISTING":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            Find an existing programme
          </Typography>
        );
      case "EXISTING_PROGRAMME_FOUND":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            We've found some productions with similar names
          </Typography>
        );
      case "GET_IN_TOUCH":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            Get in touch with us
          </Typography>
        );
      case "FORM":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            Create a new{" "}
            {productionType === "oneOff" ? "one off" : productionType}
          </Typography>
        );
      case "ADD_PRODUCTION_LEAD":
        return (
          <Typography display="inline" variant="h6" fontWeight="regular">
            One more thing
          </Typography>
        );
      default:
        return null;
    }
  };

  const displayPage = () => {
    switch (page) {
      case "PRODUCTION_TYPE":
        return (
          <ProductionTypePage
            productionType={productionType}
            setProductionType={setProductionType}
          />
        );
      case "IS_EXISTING_PROGRAMME":
        return (
          <IsExistingProgrammePage
            productionType={productionType}
            existingProgramme={existingProgramme}
            setExistingProgramme={setExistingProgramme}
          />
        );
      case "INPUT_PRODUCTION_NAME":
        return (
          <InputNamePage
            productionName={productionName}
            setProductionName={setProductionName}
          />
        );
      case "FIND_EXISTING":
        return <FindProgrammePage />;
      case "EXISTING_PROGRAMME_FOUND":
        return <ExistingProgrammeFoundPage searchResults={searchResults} />;
      case "GET_IN_TOUCH":
        return (
          <GetInTouchPage
            productionType={productionType}
            nameOfUser={nameOfUser}
            setNameOfUser={setNameOfUser}
            contactUsMessage={contactUsMessage}
            setContactUsMessage={setContactUsMessage}
          />
        );
      case "FORM":
        return (
          <CcidCreationForm
            productionName={productionName}
            setProductionName={setProductionName}
            productionType={productionType}
            formData={formData}
            setFormdata={setFormData}
          />
        );
      case "ADD_PRODUCTION_LEAD":
        return <AddProductionLead setProductionLead={setProductionLead} />;
      default:
        return null;
    }
  };

  const progressButton = () => {
    switch (page) {
      case "PRODUCTION_TYPE":
        return (
          <Button
            variant="contained"
            disabled={productionType === null}
            onClick={handleNextPage}
          >
            Next
          </Button>
        );
      case "INPUT_PRODUCTION_NAME":
        return (
          <LoadingButton
            variant="contained"
            loading={isLoadingSearchResults}
            disabled={productionName === ""}
            onClick={handleNextPage}
          >
            Next
          </LoadingButton>
        );
      case "IS_EXISTING_PROGRAMME":
        return (
          <Button
            variant="contained"
            disabled={existingProgramme === null}
            onClick={handleNextPage}
          >
            Next
          </Button>
        );
      case "FIND_EXISTING":
        return (
          <Button variant="contained" onClick={handleNextPage}>
            Contact Us
          </Button>
        );
      case "GET_IN_TOUCH":
        return (
          <Button variant="contained" onClick={submitContactUsMessage}>
            Submit
          </Button>
        );
      case "EXISTING_PROGRAMME_FOUND":
        return (
          <Button variant="contained" onClick={handleNextPage}>
            Create new{" "}
            {productionType === "oneOff" ? "one off" : productionType}
          </Button>
        );
      case "FORM":
        return (
          <LoadingButton
            loading={createCCIDLoading}
            variant="contained"
            disabled={!isFormDataValid(formData)}
            onClick={handleNextPage}
          >
            Create new production
          </LoadingButton>
        );
      case "ADD_PRODUCTION_LEAD":
        return (
          <LoadingButton
            loading={createCCIDLoading}
            variant="contained"
            disabled={
              !isFormDataValid(formData) || productionLead === undefined
            }
            onClick={handleFormSubmission}
          >
            Add production lead
          </LoadingButton>
        );
      default:
        return (
          <Button variant="contained" disabled>
            Next
          </Button>
        );
    }
  };

  const secondaryButton = () => {
    switch (page) {
      case "PRODUCTION_TYPE":
        return <Button onClick={handleClose}>Cancel</Button>;
      case "ADD_PRODUCTION_LEAD":
        return (
          <LoadingButton
            loading={createCCIDLoading}
            disabled={
              !isFormDataValid(formData) || productionLead !== undefined
            }
            onClick={() => {
              handleFormSubmission();
            }}
          >
            Skip
          </LoadingButton>
        );
      default:
        return <Button onClick={handlePreviousPage}>Back</Button>;
    }
  };

  return (
    <Dialog fullWidth open={open} aria-labelledby="create-ccid">
      <Box>
        {/* Dialog header */}
        <Stack
          bgcolor={theme.palette.primary.main}
          color="white"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          {dialogTitle()}
          <IconButton aria-label="close" onClick={handleClose} color="inherit">
            <Close />
          </IconButton>
        </Stack>

        {/* Dialog content */}
        <Box p={2}>
          {displayPage()}
          <Stack direction="row" justifyContent="flex-end" spacing={2} mt={2}>
            {secondaryButton()}
            {progressButton()}
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateCcid;
