import {
  Typography,
  Stack,
  OutlinedInput,
  Card,
  CardContent,
  Box,
  InputAdornment,
} from "@mui/material";
import { useFormikContext } from "formik";
import { FormFieldLabel } from "../../../componentsV2/FormFieldLabel";
import { type BasicInfoSection } from "../yupSchemas/basicInfoSchema";
import { DropdownMetadata } from "../../../types/types";
import { useMetadata, UseUpdateRiskArgs } from "../../../hooks";
import { useParams } from "react-router-dom";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import dayjs from "dayjs";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { EmailField } from "../formFields/EmailField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { NumberField } from "../formFields/NumberField";
import { DateField } from "../formFields/DateField";
import { DropdownField } from "../formFields/DropdownField";
import { YesNoField } from "../formFields/YesNoField";
import { RadioField } from "../formFields/RadioField";
import { NotificationFormSubmitValuesV3 } from "../NotificationFormV3";
import { BasicInfoSectionV3 } from "../yupSchemas/basicInfoSchemaV3";

interface BasicInfoPageProps {
  dropdownMetadata: DropdownMetadata;
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

const castNumberOptions = [
  {
    label: "1 - 9",
    value: "OneToNine",
  },
  {
    label: "10 - 19",
    value: "TenToNineteen",
  },
  {
    label: "20 - 29",
    value: "TwentyToTwentyNine",
  },
  {
    label: "30 or more",
    value: "ThirtyOrMore",
  },
];

const crewNumberOptions = [
  {
    label: "1 - 49",
    value: "OneToFortyNine",
  },
  {
    label: "50 - 99",
    value: "FiftyToNinetyNine",
  },
  {
    label: "100 - 149",
    value: "HundredToHundredFortyNine",
  },
  {
    label: "150 or more",
    value: "HundredFiftyOrMore",
  },
];

const productionTypeOptions = [
  { label: "Show for broadcast", value: "Broadcast" },
  { label: "Development / pilot / taster show / sizzle", value: "Sizzle" },
  { label: "Other", value: "Other" },
];

const contentTypeOptions = [
  { label: "Scripted", value: "Scripted" },
  { label: "Non-scripted", value: "Non-Scripted" },
];

const recordingTypeOptions = [
  { label: "Pre-recorded", value: "PreRecorded" },
  { label: "Live", value: "Live" },
];

export function BasicInfoPageV3({
  dropdownMetadata,
  autosaveForm,
}: BasicInfoPageProps) {
  const { seriesCcid, titleCcid } = useParams();
  const { data: metadata } = useMetadata(titleCcid || seriesCcid);
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  const dateChangeHandler = (fieldName: keyof BasicInfoSection) => {
    return function handleDateChange(value: dayjs.Dayjs | null) {
      if (!value) {
        formik.getFieldHelpers(`basicInfo.${fieldName}`).setValue("");
      } else if (value.isValid()) {
        formik
          .getFieldHelpers(`basicInfo.${fieldName}`)
          .setValue(value.format("YYYY-MM-DD"));
      }
    };
  };

  const yesNoChangeHandler = (fieldName: keyof BasicInfoSectionV3) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`basicInfo.${fieldName}`).setValue(isYes);
    };
  };

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.basicInfo || {};
  const errors = formik.errors.basicInfo || {};

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Basic Information</Typography>

              <Typography variant="body1">
                This information includes rudimentary and technical information
                regarding your production
              </Typography>

              <MultiLineTextField
                version="v3"
                fieldInfo={{ sectionId: "basicInfo", fieldName: "narrative" }}
                label="Series narrative"
                error={errors.narrative}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.narrative}
                placeholder="Please write a brief description of the production here"
                autosaveForm={autosaveForm}
              />

              <DropdownField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "productionStatus",
                }}
                label="Production status"
                error={errors.productionStatus}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.productionStatus}
                options={dropdownMetadata.productionStatus.map((status) => ({
                  value: status,
                  label: status,
                }))}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "productionType",
                }}
                label="Production type"
                error={errors.productionType}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.productionType}
                options={productionTypeOptions}
                autosaveForm={autosaveForm}
              />

              {values.productionType === "Other" ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "basicInfo",
                    fieldName: "otherProductionType",
                  }}
                  label="Other production type"
                  error={errors.otherProductionType}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.otherProductionType}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <DropdownField
                version="v3"
                fieldInfo={{ sectionId: "basicInfo", fieldName: "broadcaster" }}
                label="Broadcaster / Network name"
                error={errors.broadcaster}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.broadcaster}
                options={dropdownMetadata.broadcaster.map((broadcaster) => ({
                  value: broadcaster,
                  label: broadcaster,
                }))}
                autosaveForm={autosaveForm}
              />

              {values.broadcaster === "Other" ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "basicInfo",
                    fieldName: "otherBroadcaster",
                  }}
                  label="Other broadcaster"
                  error={errors.otherBroadcaster}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.otherBroadcaster}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <RadioField
                version="v3"
                fieldInfo={{ sectionId: "basicInfo", fieldName: "contentType" }}
                label="Is this show scripted or non-scripted?"
                error={errors.contentType}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.contentType}
                options={contentTypeOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "recordingType",
                }}
                label="Is this show pre-recorded or live?"
                error={errors.recordingType}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.recordingType}
                options={recordingTypeOptions}
                autosaveForm={autosaveForm}
              />

              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Box flex={1}>
                  <DropdownField
                    version="v3"
                    fieldInfo={{
                      sectionId: "basicInfo",
                      fieldName: "currency",
                    }}
                    label="Currency"
                    error={errors.currency}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.currency}
                    options={dropdownMetadata.currencies.map((currency) => ({
                      value: currency,
                      label: currency,
                    }))}
                    autosaveForm={autosaveForm}
                  />
                </Box>

                <Box flex={1}>
                  <NumberField
                    version="v3"
                    fieldInfo={{ sectionId: "basicInfo", fieldName: "budget" }}
                    label="Budget"
                    error={errors.budget}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.budget}
                    autosaveForm={autosaveForm}
                  />
                </Box>
              </Stack>

              <YesNoField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "specialPurposeVehicle",
                }}
                label="Are you setting up a Special Purpose Vehicle/Entity (SPV/SPE) for this production?"
                error={errors.specialPurposeVehicle}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("specialPurposeVehicle")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.specialPurposeVehicle}
                autosaveForm={autosaveForm}
              />

              {values.specialPurposeVehicle === true ? (
                <SingleLineTextField
                  version="v3"
                  fieldInfo={{
                    sectionId: "basicInfo",
                    fieldName: "specialPurposeVehicleName",
                  }}
                  label="Special Purpose Vehicle Name"
                  error={errors.specialPurposeVehicleName}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.specialPurposeVehicleName}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Box flex={1}>
                  <DropdownField
                    version="v3"
                    fieldInfo={{
                      sectionId: "basicInfo",
                      fieldName: "castEstimate",
                    }}
                    label="Cast number"
                    error={errors.castEstimate}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.castEstimate}
                    options={castNumberOptions}
                    autosaveForm={autosaveForm}
                  />
                </Box>

                <Box flex={1}>
                  <DropdownField
                    version="v3"
                    fieldInfo={{
                      sectionId: "basicInfo",
                      fieldName: "crewEstimate",
                    }}
                    label="Crew number"
                    error={errors.crewEstimate}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.crewEstimate}
                    options={crewNumberOptions}
                    autosaveForm={autosaveForm}
                  />
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Production Team Details</Typography>

              <Typography variant="body1">
                Who is the core team and label working on this production?
              </Typography>

              <FormFieldLabel
                inputId="productionLabel"
                label="Production Label"
                required={false}
              >
                <OutlinedInput
                  type="text"
                  id="productionLabel"
                  name="productionLabel"
                  disabled={true}
                  required={false}
                  multiline={false}
                  fullWidth
                  value={
                    metadata?.productionLabel ||
                    metadata?.productionLabels?.[0] ||
                    ""
                  }
                />
              </FormFieldLabel>

              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Box flex={1}>
                  <SingleLineTextField
                    version="v3"
                    fieldInfo={{
                      sectionId: "basicInfo",
                      fieldName: "productionContact",
                    }}
                    label="Production contact name"
                    error={errors.productionContact}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.productionContact}
                    placeholder="Forename Surname"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonOutline />
                      </InputAdornment>
                    }
                    autosaveForm={autosaveForm}
                  />
                </Box>

                <Box flex={1}>
                  <EmailField
                    version="v3"
                    fieldInfo={{
                      sectionId: "basicInfo",
                      fieldName: "productionContactEmail",
                    }}
                    label="Production contact email"
                    error={errors.productionContactEmail}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.productionContactEmail}
                    placeholder="example@itv.com"
                    startAdornment={
                      <InputAdornment position="start">
                        <MailOutline />
                      </InputAdornment>
                    }
                    autosaveForm={autosaveForm}
                  />
                </Box>
              </Stack>

              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Box flex={1}>
                  <SingleLineTextField
                    version="v3"
                    fieldInfo={{
                      sectionId: "basicInfo",
                      fieldName: "creativeLead",
                    }}
                    label="Production creative lead name"
                    error={errors.creativeLead}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.creativeLead}
                    placeholder="Forename Surname"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonOutline />
                      </InputAdornment>
                    }
                    autosaveForm={autosaveForm}
                  />
                </Box>

                <Box flex={1}>
                  <EmailField
                    version="v3"
                    fieldInfo={{
                      sectionId: "basicInfo",
                      fieldName: "creativeLeadEmail",
                    }}
                    label="Production creative lead email"
                    error={errors.creativeLeadEmail}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.creativeLeadEmail}
                    placeholder="example@itv.com"
                    startAdornment={
                      <InputAdornment position="start">
                        <MailOutline />
                      </InputAdornment>
                    }
                    autosaveForm={autosaveForm}
                  />
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Dates</Typography>

              <Typography variant="body1">
                When are the most important phases of production scheduled to
                be?
              </Typography>

              <DateField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "preProductionDate",
                }}
                label="Pre-production"
                error={errors.preProductionDate}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={dateChangeHandler("preProductionDate")}
                isRequired={true}
                value={dayjs(values.preProductionDate || "", "YYYY-MM-DD")}
                autosaveForm={autosaveForm}
              />

              <DateField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "principalStartDate",
                }}
                label="Principal start"
                error={errors.principalStartDate}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={dateChangeHandler("principalStartDate")}
                isRequired={true}
                value={dayjs(values.principalStartDate || "", "YYYY-MM-DD")}
                autosaveForm={autosaveForm}
              />

              <DateField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "principalFinishDate",
                }}
                label="Principal finish"
                error={errors.principalFinishDate}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={dateChangeHandler("principalFinishDate")}
                isRequired={true}
                value={dayjs(values.principalFinishDate || "", "YYYY-MM-DD")}
                autosaveForm={autosaveForm}
              />

              <DateField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "startPostDate",
                }}
                label="Start post"
                error={errors.startPostDate}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={dateChangeHandler("startPostDate")}
                isRequired={true}
                value={dayjs(values.startPostDate || "", "YYYY-MM-DD")}
                autosaveForm={autosaveForm}
              />

              <DateField
                version="v3"
                fieldInfo={{
                  sectionId: "basicInfo",
                  fieldName: "productionCloseDate",
                }}
                label="Production close"
                error={errors.productionCloseDate}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={dateChangeHandler("productionCloseDate")}
                isRequired={true}
                value={dayjs(values.productionCloseDate || "", "YYYY-MM-DD")}
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
