import { FC } from "react";
import {
  Typography,
  Stack,
  Box,
  Paper,
  Container,
  Skeleton,
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import { useTitleMetadata, useProductioniseTitle } from "../../hooks";
import { ProductionisePayload, CreateCcidFormData } from "../../types/types";
import { CcidCreationFormFormik } from "../CreateCcid/CcidCreationFormFormik";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

const currentYear = new Date().getFullYear();
const initialFormData = {
  productionLabels: [],
  commissioner: "",
  whoIsThisFor: { label: "", value: "" },
  productionYear: currentYear.toString(),
  intendedSlotLength: "",
  genre: { label: "", value: "" },
  subgenres: [],
};

const registrationSchema = Yup.object().shape({
  productionLabels: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      }),
    )
    .min(1, "This is a required field"),
  commissioner: Yup.string().required("This is a required field"),
  whoIsThisFor: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string().when("$commissioner", ([commissioner], schema) => {
      if (commissioner === "ITV") {
        return Yup.string().required("This is a required field");
      }
      return schema;
    }),
  }),
  productionYear: Yup.string().required("This is a required field"),
  intendedSlotLength: Yup.string()
    .required("This is a required field")
    .matches(/^[0-9]+$/, "Must be only numerical digits"),
});

export const UpdateCcid: FC = () => {
  const navigate = useNavigate();
  const { titleCcid } = useParams<string>();
  const { data: titleMetaData, isLoading: titleLoading } =
    useTitleMetadata(titleCcid);
  const { mutate: updateTitle } = useProductioniseTitle(
    () => {
      enqueueSnackbar(`Special updated`, { variant: "success" });
      navigate(`../`);
    },
    () => {
      enqueueSnackbar(
        `There was an error updating your programme, please try again.`,
        { variant: "error", action: SnackbarDismiss },
      );
      navigate(`../`);
    },
  );

  const onSubmit = (values: CreateCcidFormData) => {
    const formattedSeriesData: ProductionisePayload = {
      productionLabels: values.productionLabels
        ? Array.from(values.productionLabels, (p) => p.value)
        : [],
      commissioner: values.commissioner,
      whoIsThisFor:
        values.commissioner === "ITV" ? values.whoIsThisFor.value : "OFF_ITV",
      productionYear: Number(values.productionYear),
      intendedSlotLength: Number(values.intendedSlotLength),
    };
    if (titleCcid !== undefined)
      updateTitle({ ccid: titleCcid || "", formData: formattedSeriesData });
  };

  return (
    <Container>
      <Paper>
        <Box my={2} p={2}>
          <Box mb={1}>
            <Typography variant="h4" display="inline">
              Update Programme:
            </Typography>
            {titleLoading ? (
              <Skeleton sx={{ display: "inline-block" }}>
                <Typography variant="h4">Loading brand</Typography>
              </Skeleton>
            ) : (
              <Typography variant="h4" display="inline">
                {` ${titleMetaData?.titleName}`}
              </Typography>
            )}
          </Box>

          <Stack direction="row" spacing={4} my={4}>
            <Box my={2}>
              <Typography>
                We need some more information before we can update this
                programme.{" "}
              </Typography>
            </Box>
          </Stack>
          <Formik
            initialValues={initialFormData}
            validationSchema={registrationSchema}
            onSubmit={onSubmit}
          >
            {({ submitForm, isSubmitting }) =>
              isSubmitting ? (
                <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Form>
                  <Alert severity="warning" sx={{ padding: 1 }}>
                    <AlertTitle>
                      <Typography variant="h6"> Requires Update</Typography>
                    </AlertTitle>
                    This programme was created by a commissioner and exists in
                    the catalogue. Some information is missing from this series,
                    please provide some more information before we can update
                    this programme.
                  </Alert>

                  <>
                    <Box my={2}>
                      <Typography>
                        We only need a few more details to update this programme
                      </Typography>
                    </Box>

                    {titleMetaData ? (
                      <CcidCreationFormFormik
                        useUpdateSeries
                        prepopulatedData={{}}
                        excludeCount
                      />
                    ) : (
                      <></>
                    )}

                    <br />
                    <Stack direction="row" justifyContent="center" spacing={4}>
                      <Button onClick={() => navigate("/")}>Cancel</Button>
                      <Button
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={submitForm}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </>
                </Form>
              )
            }
          </Formik>
        </Box>
      </Paper>
    </Container>
  );
};
