import { SyntheticEvent, useCallback, FC, useState } from "react";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { useSearchLabelUsers } from "../../hooks";
import { User } from "../../types/types";
import { ListSelectedUsers } from "../../componentsV2/ListSelectedUsers/ListSelectedUsers";

export const AddProductionLead: FC<{
  setProductionLead: (productionLead: User | undefined) => void;
}> = ({ setProductionLead }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearch, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputChangeHandler = useCallback((event: any) => {
    if (event?.target?.value) {
      setSearchTerm(event.target.value);
    } else {
      setSearchTerm("");
    }
  }, []);

  const handleSelect = (
    _event: SyntheticEvent<Element, Event>,
    value: { label: string; user: User } | null,
  ) => {
    if (value?.user) {
      setSelectedUsers([value.user]);
      setProductionLead(value.user);
    }
    setSearchTerm("");
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h4">Add a Production Lead</Typography>
      <Typography>
        The production lead would be responsible for completing the new
        production notification form and assigning other collaborators to this
        series.
      </Typography>
      <Autocomplete
        id="search-users"
        inputValue={searchTerm}
        clearIcon={null}
        popupIcon={null}
        options={
          usersFromSearch
            ? usersFromSearch.map((user) => ({
                label: `${user.firstName} ${user.lastName} ${user.role ? `(${user.role})` : ""}`,
                user,
              }))
            : []
        }
        onInputChange={inputChangeHandler}
        onChange={handleSelect}
        loading={isLoading}
        renderInput={(params) => (
          <TextField {...params} label="Search for a user" />
        )}
      />
      <ListSelectedUsers
        selectedUsers={selectedUsers}
        deslectUser={(user) => {
          setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id));
          setProductionLead(undefined);
        }}
      />
    </Stack>
  );
};
