import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { oktaAuthClient } from "../utils/oktaAuthClient";

const getRequestConfig = (config?: AxiosRequestConfig) => {
  const basicConfig = {
    headers: {
      Authorization: `Bearer ${oktaAuthClient.getAccessToken()}`,
    },
  };

  if (config) {
    return {
      ...basicConfig,
      ...config,
    };
  }

  return basicConfig;
};

const handleRequest = {
  get: (
    endpoint: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> =>
    axios.get(endpoint, {
      ...getRequestConfig(config),
      paramsSerializer: { indexes: null },
    }),

  post: (
    endpoint: string,
    body: unknown,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> =>
    axios.post(endpoint, body, {
      ...getRequestConfig(config),
      paramsSerializer: { indexes: null },
    }),

  put: <T>(
    endpoint: string,
    body?: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> =>
    axios.put(endpoint, body, {
      ...getRequestConfig(config),
      paramsSerializer: { indexes: null },
    }),

  delete: <T>(
    endpoint: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> =>
    axios.delete(endpoint, {
      ...getRequestConfig(config),
      paramsSerializer: { indexes: null },
      data,
    }),

  patch: (
    endpoint: string,
    body: string | Record<string, string | number | undefined | string[]>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse> =>
    axios.patch(endpoint, body, {
      ...getRequestConfig(config),
      paramsSerializer: { indexes: null },
    }),
};

export default handleRequest;
