import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import {
  getMetaDataDropdown,
  getMetaDataDropdownNew,
} from "../../api/spotlight";
import { type DropdownMetadata } from "../../types/types";

export const useMetadataDropdown = (): UseQueryResult<
  DropdownMetadata,
  AxiosError
> => useQuery(["metaDataDropdown"], () => getMetaDataDropdown());

export const useMetadataDropdownNew = (): UseQueryResult<
  DropdownMetadata,
  AxiosError
> => useQuery(["metaDataDropdown"], () => getMetaDataDropdownNew());
