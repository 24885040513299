import React from "react";
import {
  CircularProgress,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useProductionDetails } from "../../hooks";
import { SERIES, TITLES } from "../../constants";
import ProductionContacts from "./ProductionContacts";

const ProductionContactsPage = (): JSX.Element => {
  const { seriesCcid, titleCcid } = useParams();
  const type = titleCcid ? TITLES : SERIES;
  const ccid = titleCcid || seriesCcid;
  const { data: productionDetails, isLoading } = useProductionDetails(
    ccid,
    type,
  );

  return (
    <Container sx={{ py: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5">Production Contacts</Typography>
        <Stack alignItems="center" my={2}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <ProductionContacts
              contactName={productionDetails?.productionContactName}
              contactEmail={productionDetails?.productionContactEmail}
              creativeLeadName={productionDetails?.creativeLeadName}
              creativeLeadEmail={productionDetails?.creativeLeadEmail}
            />
          )}
        </Stack>
      </Paper>
    </Container>
  );
};

export default ProductionContactsPage;
