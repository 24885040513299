import { useMutation, UseMutationResult } from "react-query";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";
import { legalDeliverablesFormSubmission } from "../../api/legalDeliverables";
import { AutosaveOfcomFormDataServer } from "../../types/types";
import { noop } from "../../utils/appHelper";

export const useOfcomFormSubmission = (
  onSuccess = noop,
): UseMutationResult<
  unknown,
  unknown,
  {
    ccid: string;
    formData: AutosaveOfcomFormDataServer;
    autosave: boolean;
    provisional: boolean;
  },
  unknown
> =>
  useMutation(
    ({ ccid, formData, autosave, provisional }) =>
      legalDeliverablesFormSubmission(
        ccid,
        formData,
        autosave,
        provisional ? `OFCOM_PROVISIONAL` : `OFCOM_FINAL`,
      ),
    {
      onSuccess: () => {
        enqueueSnackbar("Content Saved", { variant: "success" });
        onSuccess();
      },
      onError: () => {
        enqueueSnackbar("error saving content!", {
          variant: "error",
          action: SnackbarDismiss,
        });
      },
    },
  );
