import { useQuery, UseQueryResult } from "react-query";
import { searchUsers } from "../../api/accessPermissions";
import { User } from "../../types/types";

export const useSearchLabelUsers = (
  searchTerm: string,
  limit: number,
): UseQueryResult<User[]> =>
  useQuery(
    ["label search users", searchTerm, limit],
    () => searchUsers(searchTerm, limit),
    { enabled: Boolean(searchTerm.trim().length !== 0) },
  );
