import { MailOutline, PersonOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import {
  CHECKBOX,
  GROUP_CHECKBOXES,
  RADIO,
  TEXT,
  TEXTAREA,
} from "../../../components";

// eslint-disable-next-line
const emailRegex = new RegExp("[a-z0-9]+@[a-z0-9]+.[a-z]{2,3}");

export const healthAndSafetyFormConfig = [
  {
    sectionTitle: (
      <Typography variant="h5">
        Risk as a factor of Health and Safety
      </Typography>
    ),
    key: "Risk as a factor of Health and Safety",
    description:
      "Please indicate whether pre-production or filming will involve the following:",
    formFields: [
      {
        type: TEXT,
        label: "Who is Responsible for Health and Safety on this production?",
        id: "responsible",
        placeholder: "Forename Surname",
        helperText:
          "This person is responsible for reporting all Health and Safety incidents to the ITV Incident Reporting Portal or to your Health and Safety risk advisor within 72 hours.",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        icon: <PersonOutline />,
      },
      {
        type: TEXT,
        label: "Responsible Email",
        id: "responsibleEmail",
        placeholder: "example@itv.com",
        value: undefined,
        validationRegex: emailRegex,
        columnCount: 12,
        required: false,
        disabled: false,
        icon: <MailOutline />,
      },
      {
        type: CHECKBOX,
        label: "Not yet assigned",
        id: "responsibleNone",
        questionBubble: "",
      },
      {
        type: RADIO,
        label:
          "What is the highest level of Health and Safety Training this person has received?",
        id: "training",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "No formal Health and Safety Training",
            value: "NoTraining",
          },
          {
            label:
              "Health and Safety Training received via an external provider",
            value: "External",
          },
          {
            label: "ITV's Internal Managing Risk Health and Safety Training ",
            value: "Internal",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Please indicate external course and provider.",
        id: "trainingCourse",
        value: undefined,
        placeholder: "Type answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          training: "External",
        },
      },

      {
        type: GROUP_CHECKBOXES,
        id: "activities",
        label:
          "Will the production be conducting any of the following activities?",
        columnCount: 12,
        vertical: true,
        required: true,
        options: [
          {
            label: "Food handling",
            value: "FoodHandling",
          },
          {
            label: "Filming with animals",
            value: "FilmingWithAnimals",
          },
          {
            label: "Underground filming",
            value: "Underground",
          },
          {
            label: "Extreme Sports",
            value: "Extreme",
          },
          {
            label: "The use of jet skis or other high speed water vessels",
            value: "JetSkisOrOther",
          },
          {
            label: "Diving or other waterborne activities",
            value: "DivingOrOther",
          },
          {
            label: "Other potential high risk activities?",
            value: "OtherHighRisk",
          },
          {
            label: "None of the above",
            value: "NoneOfAbove",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Please indicate other potential high risk activities",
        id: "otherActivities",
        value: undefined,
        placeholder: "Type answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: RADIO,
        label:
          "Will the programme include the use of drones for filming, filming from aircraft, filming from boats and/or filming from vehicles?",
        id: "filmingTechniques",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "No use of non-standard filming techniques",
            value: "NoUse",
          },
          {
            label:
              "Under 10% of filming will use non-standard filming techniques",
            value: "Under10Percent",
          },
          {
            label:
              "10% - 25% of filming will use non-standard filming techniques",
            value: "Between10And25Percent",
          },
          {
            label:
              "Over 25% of filming will use non-standard filming techniques",
            value: "Over25Percent",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will the programme include extended filming during adverse weather conditions or darkness?",
        id: "weatherConditions",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "No filming in these conditions",
            value: "NoUse",
          },
          {
            label:
              "Under 5% of the production will be filmed in these conditions",
            value: "Under5Percent",
          },
          {
            label:
              "5 - 10% of the production will be filmed in these conditions",
            value: "Between5And10Percent",
          },
          {
            label:
              "Over 10% of the production will be filmed in these conditions",
            value: "Over10Percent",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will firearms or other weapons (including imitation firearms or weapons) be used on this production?",
        id: "firearms",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "No firearms or weapons will be used on this production",
            value: "NoFirearms",
          },
          {
            label:
              "Imitation firearms or imitation weapons will be used on this production",
            value: "Imitation",
          },
          {
            label:
              "Non-imitation weapons (but not firearms) will be used on this production",
            value: "NonImitation",
          },
          {
            label: "Live firearms will be used on this production",
            value: "LiveFirearms",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will special effects be developed and deployed as part of the production?",
        id: "sfx",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "No special effects will be used as part of this production",
            value: "NoSfx",
          },
          {
            label:
              "The production is likely to have a single scene where special effects are used",
            value: "LikelySingle",
          },
          {
            label:
              "The production is likely to have multiple scenes where SFX are used.",
            value: "LikelyMultiple",
          },
          {
            label:
              "A significant proportion of the production will include SFX",
            value: "Significant",
          },
        ],
      },
      {
        type: RADIO,
        label: "Will stunts be used as part of the production?",
        id: "stunt",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "No stunts will be used as part of this production",
            value: "NoStunt",
          },
          {
            label:
              "The production is likely to have a single scene where stunts are used",
            value: "LikelySingle",
          },
          {
            label:
              "The production is likely to have multiple scenes where stunts are used.",
            value: "LikelyMultiple",
          },
          {
            label:
              "A significant proportion of the production will include stunts",
            value: "Significant",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "To what extent will set construction be required on the production?",
        id: "setBuild",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "No set build required as part of this production",
            value: "NoSet",
          },
          {
            label: "Minor set build required as part of this production",
            value: "Minor",
          },
          {
            label: "Moderate set build required as part of this production",
            value: "Moderate",
          },
          {
            label: "Significant set build required as part of this production",
            value: "Significant",
          },
        ],
      },
      {
        type: RADIO,
        label: "Where will filming take place?",
        id: "filmingLocation",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "All filming will be conducted in a studio.",
            value: "Studio",
          },
          {
            label:
              "The majority of filming will be conducted in the studio however some shoots will be conducted on location.",
            value: "MajorityInStudio",
          },
          {
            label: "Around half of all filming will take place on location",
            value: "HalfInLocation",
          },
          {
            label: "The majority of filming will take place on location",
            value: "MajorityInLocation",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Does the filming location present any health or safety risks for the public, contributors, or crew?",
        id: "locationType",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "The production will not be filming in locations that could directly result in an injury to contributors, crew, or the public",
            value: "InLocation",
          },
          {
            label:
              "The production will be carrying out a single shoot in a location that could directly result in an injury to contributors, crew, or the public",
            value: "SingleShootInLocation",
          },
          {
            label:
              "The production will be carrying out multiple shoots in a location(s) that could directly result in an injury to contributors, crew, or the public.",
            value: "MultipleShootsInLocation",
          },
          {
            label: "Not yet known",
            value: "NotYetKnown",
          },
        ],
      },
      {
        type: RADIO,
        defaultToFalse: true,
        label: "Will the production have a studio audience?",
        id: "audience",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will the production be working with children, or/and vulnerable adults?",
        id: "vulnerable",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "The production will not be working with either children and vulnerable adults.",
            value: "NoVulnerableAndChildren",
          },
          {
            label:
              "The production will be working with children but not vulnerable adults",
            value: "WithChildren",
          },
          {
            label:
              "The production will be working with vulnerable adults but not children",
            value: "WithVulnerableAdults",
          },
          {
            label:
              "The production will be working with both children and vulnerable adults",
            value: "Both",
          },
        ],
      },
      {
        type: RADIO,
        defaultToFalse: true,
        label:
          "Will you be putting any Covid-specific controls in place for this production? ",
        id: "covid",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "If yes, please detail these controls.",
        id: "covidDetails",
        placeholder: "Type answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          covid: true,
        },
      },
    ],
  },
];
