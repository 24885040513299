import { useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from "@mui/material";
import { useQueries, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import {
  ImageState,
  patchDeleteDeliverableState,
} from "../../../../../api/images";

import {
  FilesWithMetaInitialState,
  FilesWithMetaAction,
  MetaProps,
} from "../Reducers/filesWithMetaDataReducer";
import { getAltThumbnail } from "../Thumbnail/Thumbnail";

export interface UploadErrors {
  fileId: string;
  errorMessage: string;
}

export interface ImgCcid {
  imgId: string;
  ccid: string;
  imgUploadId: string;
  embargoDateTime?: string;
}

interface PreviewModalProps {
  isModalOpen: boolean;
  setIsOpenModal: (bool: boolean) => void;
  filesWithMetaData: FilesWithMetaInitialState;
  ccid: string;
  level: string;
  uploadErrors?: UploadErrors[];
  setUploadErrors: React.Dispatch<React.SetStateAction<UploadErrors[]>>;
  dispatch: React.Dispatch<FilesWithMetaAction>;
  type: string;
}

const typeLabelMap: Record<string, string> = {
  images: "Images",
  scripts: "Scripts",
  fonts: "Fonts",
};

export const PreviewModal: React.FC<PreviewModalProps> = ({
  isModalOpen,
  setIsOpenModal,
  filesWithMetaData: { filesWithMetaData },
  ccid,
  level,
  setUploadErrors,
  uploadErrors,
  dispatch,
  type,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [isUploadCompleted, setIsUploadCompleted] = useState<boolean>(false);

  const isAllFileSelected =
    filesWithMetaData.length > 0 &&
    filesWithMetaData.every((f) => f.isSelected);

  const queryClient = useQueryClient();

  const filesWithNoUploadError = filesWithMetaData.filter(
    (file) => file.errorOnUpload === undefined,
  );

  useQueries(
    filesWithNoUploadError.map((file, i, arr) => ({
      queryKey: ["patchImages", file.fileUploadId],
      queryFn: () =>
        patchDeleteDeliverableState(
          level,
          ccid,
          file.fileUploadId as string,
          ImageState.ACTIVE,
          file.meta.embargoDateTime?.toISOString(),
        ),
      enabled: isUploadCompleted,
      onSuccess: () => {
        if (i === arr.length - 1) {
          queryClient.invalidateQueries("uploadedImages");
          dispatch({ type: "SET_TO_INITAL_STATE" });
        }
      },
      onError: () => {
        if (i === arr.length - 1) {
          queryClient.invalidateQueries("uploadedImages");
        }
      },
    })),
  );

  const handleDeleteImages = () => {
    dispatch({
      type: "REMOVE_FILES",
      payload: filesWithMetaData
        .filter((f) => f.isSelected)
        .map((f) => f.meta.id),
    });
  };

  const handleSelectAllChecked = () => {
    dispatch({
      type: "TOGGLE_SELECT_ALL_FILES",
      payload: isAllFileSelected ? "remove" : "select",
    });
  };

  const handleCheckboxChange = (imgId: string) => {
    dispatch({ type: "TOGGLE_SELECT_FILE", payload: imgId });
  };

  const handleSetEmbargoDate = () => {
    if (selectedDate !== null) {
      dispatch({ type: "SET_EMBARGO_DATE", payload: selectedDate.toDate() });
    }
  };

  const handleRemoveEmbargoDate = () => {
    dispatch({ type: "REMOVE_EMBARGO_DATE" });
  };

  const previewUrlCheck = (meta: MetaProps) =>
    meta.previewUrl ? (
      <img src={meta.previewUrl} alt={meta.name} loading="lazy" />
    ) : (
      <img src={getAltThumbnail(meta.name)} alt={meta.name} loading="lazy" />
    );

  const handleImagePreview = (meta: MetaProps) => {
    if (meta.type === "image/tiff") {
      if (meta.status !== "done") {
        return <CircularProgress sx={{ mx: 6 }} />;
      }
      return (
        <img src={getAltThumbnail(meta.name)} alt={meta.name} loading="lazy" />
      );
    }
    return previewUrlCheck(meta);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isModalOpen}
      onClose={() => {
        setUploadErrors([]);
        dispatch({ type: "SET_TO_INITAL_STATE" });
        setIsOpenModal(false);
      }}
    >
      <DialogTitle data-testid="previewModal">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography display="inline" variant="h5">
            Upload {typeLabelMap[type]}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setUploadErrors([]);
              dispatch({ type: "SET_TO_INITAL_STATE" });
              setIsOpenModal(false);
            }}
            color="primary"
          >
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>

      {(uploadErrors && uploadErrors?.length > 0) ||
      filesWithMetaData.length < 1 ? (
        <DialogContent dividers>
          <Typography>
            There was an error loading some of the{" "}
            {typeLabelMap[type].toLowerCase()}. Please refresh the page and try
            again.
          </Typography>
        </DialogContent>
      ) : (
        <>
          <DialogContent dividers>
            <ImageList sx={{ height: 500 }} gap={16} cols={3} rowHeight={250}>
              {filesWithMetaData.map(({ meta }) => (
                <ImageListItem key={meta.id} sx={{ height: "100% !important" }}>
                  <ImageListItemBar
                    sx={{ background: "none" }}
                    title={
                      meta.embargoDateTime && (
                        <Chip
                          label={`Embargo until: ${meta.embargoDateTime?.toLocaleDateString()}`}
                          color="error"
                          size="small"
                        />
                      )
                    }
                    position="top"
                    actionIcon={
                      <Checkbox
                        checked={filesWithMetaData.some(
                          (f) => f.meta.id === meta.id && f.isSelected,
                        )}
                        onChange={() => handleCheckboxChange(meta.id)}
                      />
                    }
                    actionPosition="left"
                  />
                  {handleImagePreview(meta)}
                  <ImageListItemBar
                    title={meta.name}
                    subtitle={
                      <Typography variant="caption">
                        {(meta.size / 1000).toFixed(0)} kB
                      </Typography>
                    }
                    position="below"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </DialogContent>
          <DialogContent>
            <Stack direction="row" justifyContent="space-between" py={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAllFileSelected}
                    id="embargo-images-select-all-checkbox"
                    onChange={handleSelectAllChecked}
                  />
                }
                label="Select All"
              />
              <Stack direction="row" spacing={2} alignItems="center">
                <DatePicker
                  slotProps={{ textField: { size: "small" } }}
                  disablePast
                  value={selectedDate}
                  referenceDate={dayjs()}
                  onChange={setSelectedDate}
                />
                <Button
                  disabled={
                    (!filesWithMetaData.some((f) => f.isSelected) &&
                      !isAllFileSelected) ||
                    selectedDate === null
                  }
                  variant="contained"
                  color="secondary"
                  onClick={handleSetEmbargoDate}
                >
                  Apply Embargo to Selected
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  disabled={
                    !filesWithMetaData.some(
                      (f) => f.meta.embargoDateTime && f.isSelected,
                    )
                  }
                  onClick={handleRemoveEmbargoDate}
                >
                  Remove embargo
                </Button>
                <Button
                  disabled={!filesWithMetaData.some((f) => f.isSelected)}
                  variant="outlined"
                  color="error"
                  onClick={handleDeleteImages}
                >
                  Delete Selected
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsUploadCompleted(true);
                    setUploadErrors([]);
                    if (filesWithNoUploadError.length < 1) {
                      dispatch({ type: "SET_TO_INITAL_STATE" });
                    }
                    enqueueSnackbar(`${typeLabelMap[type]} Uploaded`, {
                      variant: "success",
                    });
                  }}
                >
                  Complete
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
