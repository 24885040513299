import { Typography } from "@mui/material";
import { RADIO, TEXT, TEXTAREA } from "../../../components";

export const insuranceFormConfig = [
  // {
  //   sectionTitle: <StyledFormSectionTitle>Broadcast / Network details</Typography>,
  //   description: "",
  //   formFields: [
  //     {
  //       type: RADIO,
  //       label: "Which insurance cover will you be using?",
  //       id: "insuranceCover",
  //       value: undefined,
  //       placeholder: "please select",
  //       columnCount: 12,
  //       disabled: false,
  //       options: [
  //         {
  //           label: "ITV",
  //           value: "ITV",
  //         },
  //         {
  //           label: "Broadcaster / Network",
  //           value: "Broadcaster",
  //         },
  //         {
  //           label: "Other",
  //           value: "Other",
  //         },
  //       ],
  //     },
  //     {
  //       type: TEXT,
  //       label: "What other insurance cover will you be using?",
  //       id: "otherInsuranceCover",
  //       value: '',
  //       columnCount: 12,
  //       disabled: false,
  //       visibleDependencies: {
  //         'insuranceCover': 'Other',
  //       },
  //     },
  //   ]
  // },
  {
    sectionTitle: (
      <Typography variant="h5">
        Cast Insurance (Production Budget Insurance)
      </Typography>
    ),
    description: (
      <div>
        <p>
          Cast insurance is in place to provide cover to the budget for the
          additional costs incurred if cast or crew are unable to work due to
          sickness or injury (this does not need to be work-related) in extreme
          cases this could include abandonment of a Production due to reliance
          on key individuals.
        </p>
        <p>
          A higher level of medical confirmation of fitness than the standard
          process is required for more significant financial exposure.
        </p>
        <p>
          If you have cast or crew involved in your Production that could have a
          greater financial impact than 500,000 to your production if they are
          unable to work or cause you to abandon you will need to declare them
          below and they will require a medical. The insurance team will be in
          touch to discuss how this will work.
        </p>
      </div>
    ),
    formFields: [
      {
        type: RADIO,
        label:
          "Could the loss of key talent (1 or more individuals) have a greater financial impact than 500,000 ($, £ or €) above the production budget?",
        id: "talentLoss",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: TEXT,
        label: "Please list the names of those key individuals",
        id: "talentLossList",
        value: "",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          talentLoss: true,
        },
      },
      {
        type: RADIO,
        label:
          "Could the complete loss of key talent (1 or more individuals) cause the production to be shut down and possibly have the network abandon the project?",
        id: "shutdown",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: TEXT,
        label:
          "Please list the names of the key individuals and confirm their roles",
        id: "shutdownDetails",
        value: "",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          shutdown: true,
        },
      },
      {
        type: RADIO,
        label: "Will any cast members be over the age of 75 or under 6?",
        id: "age",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
  },
  {
    sectionTitle: (
      <Typography variant="h5">Wardrobe, Props And Cash Declaration</Typography>
    ),
    description: "",
    formFields: [
      {
        type: RADIO,
        label:
          "The production will be responsible for any jewellery/jewelry, furs, antiques with a total value of more than 100k (or equivalent)?",
        id: "jewellery",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not sure but it's unlikely",
            value: "Unlikely",
          },
          {
            label: "Not sure but it's likely",
            value: "Likely",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Any 1 person on the production will be carrying more than 2.5k (or equivalent) cash at any point in time?",
        id: "personalCash",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not sure but it's unlikely",
            value: "Unlikely",
          },
          {
            label: "Not sure but it's likely",
            value: "Likely",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "The production holds cash to a value greater than 75k (or equivalent) on location",
        id: "productionCash",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not sure but it's unlikely",
            value: "Unlikely",
          },
          {
            label: "Not sure but it's likely",
            value: "Likely",
          },
        ],
      },
    ],
  },
  {
    sectionTitle: <Typography variant="h5">Procurement</Typography>,
    description:
      "Will any spend be over £100k or the local currency equivalent in the following categories? Please detail below so that we can get in touch with you to give you additional support and risk management advice where it’s needed. ",
    formFields: [
      {
        type: RADIO,
        label: "Post Production",
        id: "post",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Outside Broadcast (OB)",
        id: "ob",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Audiovisual (AV) Hire",
        id: "av",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Facility Vehicles",
        id: "vehicles",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Sound Equipment",
        id: "sound",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Rigging or Camera Cranes",
        id: "rigging",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Camera Hire",
        id: "camera",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Portable Single Camera (PSC) Crews",
        id: "psc",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Sourced Material",
        id: "source",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Lighting",
        id: "lighting",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Studio Hire",
        id: "studio",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Set Construction",
        id: "set",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Security",
        id: "security",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Catering",
        id: "catering",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Other - please detail",
        id: "procOther",
        value: undefined,
        placeholder: "Type answer here",
        columnCount: 12,
        required: false,
        disabled: false,
      },
    ],
  },
];
