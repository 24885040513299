import { Typography } from "@mui/material";
import { RADIO, GROUP_CHECKBOXES, SELECT, TEXT } from "../../../components";

export const sustainabilityFormConfig = [
  {
    sectionTitle: (
      <Typography variant="h5">
        Locations (Set builds/Studios/Location etc)
      </Typography>
    ),
    description: "",
    key: "Locations (Set builds/Studios/Location etc)",
    formFields: [
      {
        type: SELECT,
        label: "Which carbon calculator will be used? ",
        id: "calculator",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "albert",
            value: "Albert",
          },
          {
            label: "Ecoprod",
            value: "Ecoprod",
          },
          {
            label: "Green Shooting/MFG",
            value: "GreenShootingMFG",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
      },
      {
        type: TEXT,
        label: "Other carbon calculator:",
        id: "calculatorDetails",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          calculator: "Other",
        },
      },
      {
        type: TEXT,
        label: "Please indicate all countries that you will be visiting:",
        id: "country",
        value: undefined,
        placeholder: "enter location here",
        questionBubble:
          "Countries with ITV studios operations will probably be able to offer you some advice or support, so please get in touch.",
        columnCount: 12,
        optionsMapTo: "countries",
        required: false,
        disabled: false,
      },
      {
        type: RADIO,
        label:
          "Will you be working in an area with a significant risk from extreme weather or natural hazards?",
        id: "hazard",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "TBC",
          },
        ],
      },
      {
        type: GROUP_CHECKBOXES,
        id: "naturalHazard",
        label: "What natural hazard will the production be exposed to?",
        columnCount: 12,
        vertical: true,
        required: true,
        options: [
          {
            label: "Floods",
            value: "Floods",
          },
          {
            label: "Excessive rain/snow",
            value: "RainSnow",
          },
          {
            label: "Excessive heat",
            value: "Heat",
          },
          {
            label: "Severe Thunderstorms",
            value: "Thunderstorms",
          },
          {
            label: "Hurricanes or Typhoons",
            value: "HurricanesTyphoons",
          },
          {
            label: "Earthquakes",
            value: "Earthquakes",
          },
          {
            label: "Volcanic Eruptions",
            value: "Volcanic",
          },
          {
            label: "Wildfires",
            value: "Wildfires",
          },
          {
            label: "Tsunami's",
            value: "Tsunami",
          },
          {
            label: "Landslides",
            value: "Landslides",
          },
          {
            label: "Drought / Water shortage",
            value: "Drought",
          },
        ],
        visibleDependencies: {
          hazard: "Yes",
        },
      },
      {
        type: RADIO,
        label: "Is set construction required for your production?",
        id: "setbuild",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: RADIO,
        label: "Will you be filming a studio?",
        id: "filmloc",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "All filming for this production will be done from a Studio",
            value: "Studio",
          },
          {
            label:
              "The majority of filming for this production will be done from a Studio with some on location shoots",
            value: "MajorityInStudio",
          },
          {
            label:
              "Around 50% of the filming for this production will be from a studio with the other 50% being done on location",
            value: "HalfInLocation",
          },
          {
            label:
              "The majority of filming for this production will be done on location",
            value: "MajorityInLocation",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "If you have a lighting department on this production, will they be using low energy lighting?",
        id: "lighting",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "All lighting for this production will be low energy lighting",
            value: "AllLowEnergy",
          },
          {
            label:
              "The majority of lighting for this production will be low energy lighting",
            value: "MajorityLowEnergy",
          },
          {
            label:
              "Around 50% of lighting for this production will be low energy lighting",
            value: "HalfLowEnergy",
          },
          {
            label:
              "The majority of light for this department will not be low energy lighting",
            value: "MajorityHighEnergy",
          },
          {
            label: "Not yet known",
            value: "NotYetKnown",
          },
        ],
      },
      {
        type: RADIO,
        label: "Are you using generator on your production?",
        id: "gen",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "TBC",
          },
        ],
      },
      {
        type: RADIO,
        label: "Will anyone be taking flights as part of this production?",
        id: "flying",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "This production will require over 51 flight bookings",
            value: "LargeAmount",
          },
          {
            label:
              "This production will require between 11 and 50 flight bookings",
            value: "MediumAmount",
          },
          {
            label:
              "This production will require between 1 and 10 flight bookings",
            value: "SmallAmount",
          },
          {
            label: "This production does not require any flight booking",
            value: "None",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will your production be covering a significant number of vehicle miles?",
        id: "mileage",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label:
              "This production will require over 1000 combined vehicle miles",
            value: "LargeDistance",
          },
          {
            label:
              "This production will require 500 - 999 combined vehicle miles",
            value: "MediumDistance",
          },
          {
            label:
              "This production will require 250 - 499 combined vehicle miles",
            value: "ShortDistance",
          },
          {
            label:
              "This production will require under 250 combined vehicles miles",
            value: "VeryShortDistance",
          },
        ],
      },
    ],
  },
];
