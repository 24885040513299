import { FC, useMemo } from "react";
import dayjs from "dayjs";
import { Chip, Typography, Stack } from "@mui/material";
import { RiskLevel } from "../../../types/types";

export interface Summary {
  rating?: RiskLevel;
  submittedBy?: string;
  lastSubmitted?: string;
}

export interface FormStatusProps {
  summary: Summary;
}

export const FromChip: FC<FormStatusProps> = ({
  summary: { rating, lastSubmitted },
}) => {
  if (!lastSubmitted) {
    return <Chip label="Draft - Unsubmitted" />;
  }

  if (rating && rating === RiskLevel.High) {
    return <Chip label="Submitted - High Risk" color="error" />;
  }

  return <Chip label="Submitted" color="success" />;
};

export const FromStatus: FC<FormStatusProps> = ({ summary }) => {
  const message = useMemo(() => {
    const { submittedBy, lastSubmitted } = summary;

    if (lastSubmitted && submittedBy) {
      return `Last submitted : ${dayjs(lastSubmitted).format("DD/MM/YYYY HH:mm a")} by ${submittedBy}`;
    }
    return "";
  }, [summary]);

  return (
    <Stack alignItems="flex-end" spacing={1}>
      <FromChip summary={summary} />
      {message && (
        <Typography fontSize={13} color="secondary">
          {message}
        </Typography>
      )}
    </Stack>
  );
};
