import * as Yup from "yup";

export const securitySchemaV3 = Yup.object().shape({
  additionalSecurity: Yup.string().required("This is a required field"),
  suppliers: Yup.array()
    .of(Yup.string().required())
    .when("$security.additionalSecurity", ([additionalSecurity], schema) => {
      if (additionalSecurity === "Required") {
        return schema
          .required("This is a required field")
          .min(1, "This is a required field");
      }
      return schema;
    }),
  securityProvider: Yup.string(),
  atRiskCountries: Yup.array()
    .of(Yup.string().required())
    .required("This is a required field")
    .min(1, "This is a required field"),
  internationalTravel: Yup.boolean().required("This is a required field"),
  provocation: Yup.string().required("This is a required field"),
  provocationDetail: Yup.string(),
  risk: Yup.string().required("This is a required field"),
  riskDetail: Yup.string(),
});

export type SecuritySectionV3 = Yup.InferType<typeof securitySchemaV3>;
