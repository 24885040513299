/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint no-param-reassign: ["error", { "props": false }] */
import React, {
  useEffect,
  useState,
  useReducer,
  ChangeEvent,
  useMemo,
  useCallback,
} from "react";
import Dropzone, { IFileWithMeta } from "react-dropzone-uploader";
import { HelpOutline } from "@mui/icons-material";
import {
  CircularProgress,
  Tooltip,
  Button,
  Stack,
  Container,
  Card,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { fromEvent } from "file-selector";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { uploadConfig } from "./upload.config";
import { NoAccess } from "../../../../components/NoAccess";
import useUnload from "../../../../hooks/useUnload";

import { startImageUpload, ListImageSchema } from "../../../../api/images";
import CustomInput from "./CustomInput/CustomInput";
import UploadedImages from "./UploadedImages/UploadedImages";
import {
  FilesWithMetaInitialState,
  FilesWithMetaProps,
  filesWithMetaDataReducer,
} from "./Reducers/filesWithMetaDataReducer";
import { PreviewModal, UploadErrors } from "./PreviewModal/PreviewModal";
import {
  useListDeliverables,
  useImagesEntitlements,
  useSynopsis,
  useBrandMetadata,
  useTitles,
  useSeries,
} from "../../../../hooks/queries";
import theme from "../../../../utils/theme";

export type Level = "version" | "titles" | "series" | "brands";

type ErrorStatusValue =
  | "rejected_file_type"
  | "error_upload"
  | "exception_upload"
  | "error_upload_params";

const uploadErrorsList: ErrorStatusValue[] = [
  "rejected_file_type",
  "error_upload",
  "exception_upload",
  "error_upload_params",
];

const supportedTypes: Record<string, string> = {
  psb: "image/vnd.adobe.photoshop",
  psd: "image/vnd.adobe.photoshop",
};

export const getFileExtension = (fileName: string): string =>
  fileName.split(".").pop() || "";

export const getUploadType = (tabName: string | undefined): string => {
  let returnValue = "images";
  if (tabName === "scripts") returnValue = "scripts";
  else if (tabName === "fontOneOff" || tabName === "fontSeries")
    returnValue = "fonts";
  return returnValue;
};

const initialState: FilesWithMetaInitialState = {
  filesWithMetaData: [],
};

interface FileUploadProps {
  fileId: string;
  uploadId: string;
}

interface IFileUploadWithMeta extends IFileWithMeta {
  retry: number;
}

const dropZoneAcceptedBrandLevel = `image/jpeg,image/png,image/tiff,image/webp,image/x-eps,application/illustrator",application/postscript,image/vnd.adobe.photoshop,image/svg+xml,application/vnd.3gpp.pic-bw-small,.psb,.psd,.ttf,.otf,.fnt`;

const Images: React.FC = () => {
  const url = useLocation();
  const pathArray = url.pathname.split("/");
  const currentTab = pathArray[pathArray.length - 1];

  const { programmeCcid, seriesCcid, titleCcid } = useParams<string>();
  const activeCcid = titleCcid || seriesCcid || programmeCcid || "";
  const brandsOrSeriesUrl = programmeCcid === activeCcid ? "brands" : "series";
  const level = titleCcid === activeCcid ? "titles" : brandsOrSeriesUrl;

  const { data: brandsMetaData } = useBrandMetadata(programmeCcid);
  const { data: brandTitlesData } = useTitles(programmeCcid, "brands");
  const { data: seriesData } = useSeries(programmeCcid, false);
  const { data: titlesData } = useTitles(seriesCcid, "series");

  const getSeriesDetails = useCallback(
    (id: string) =>
      seriesData?.seriesDetails?.find((serie) => serie.ccid === id),
    [seriesData?.seriesDetails],
  );
  const getTitleDetails = useCallback(
    (id: string) =>
      titlesData?.titleDetails?.find((title) => title.ccid === id) ||
      brandTitlesData?.titleDetails?.find((title) => title.ccid === id),
    [brandTitlesData?.titleDetails, titlesData?.titleDetails],
  );

  const seriesDetails = useMemo(
    () => getSeriesDetails(seriesCcid || ""),
    [getSeriesDetails, seriesCcid],
  );
  const titleDetails = useMemo(
    () => getTitleDetails(titleCcid || ""),
    [getTitleDetails, titleCcid],
  );

  const { data: synopsisData } = useSynopsis(activeCcid, level);

  const synopsisDescription =
    synopsisData?.synopsisMedium ||
    synopsisData?.synopsisShort ||
    synopsisData?.synopsisLong ||
    "";

  const brandName = brandsMetaData?.brandName;

  const config = useMemo(
    () => uploadConfig[currentTab as string] || {},
    [currentTab],
  );
  const deliverablesType = getUploadType(currentTab);
  const uploadFileTypes: any = {
    images: ".jpeg,.jpg,.png,.tiff,.ai,.psd,or .eps",
    fonts: " .ttf,.otf,.fnt",
    scripts: ".doc, .docx",
  };
  let idType = "imageId";
  if (deliverablesType === "scripts") idType = "scriptId";
  if (deliverablesType === "fonts") idType = "fontId";
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsOpenModal] = useState<boolean>(false);
  const [uploadErrors, setUploadErrors] = useState<UploadErrors[]>([]);
  const [uploadedDeliverables, setUploadedDeliverables] =
    useState<ListImageSchema[]>();

  const [filesWithMetaData, dispatch] = useReducer(
    filesWithMetaDataReducer,
    initialState,
  );
  const [uploadFilesId, setUploadFilesId] = useState<FileUploadProps[]>([]);
  const [showWrongFormatErrorMessage, setShowWrongFormatErrorMessage] =
    useState<boolean>(false);

  const [chosenFilesQuantity, setChosenFilesQauntity] = useState<number>(0);

  const optionalRetryUpload = (file: IFileUploadWithMeta) => {
    if (file.retry === undefined) {
      file.retry = 0;
    }

    if (
      ["exception_upload", "error_upload", "error_upload_params"].includes(
        file.meta.status,
      )
    ) {
      if (file.retry < 5) {
        file.restart();
        file.retry += 1;
        return true;
      }
    }

    return false;
  };

  const getUploadErrorMessage = (
    error: ErrorStatusValue,
    backendErrorMessage?: string,
  ) =>
    `${
      {
        rejected_file_type: "Upload failed: File not supported",
        error_upload: "Upload failed",
        error_upload_params: "Upload failed",
        exception_upload: "Upload failed",
      }[error]
    } ${backendErrorMessage || ""}`;

  const { data: entitlementsData, isLoading } = useImagesEntitlements(
    activeCcid,
    level,
    deliverablesType,
  );

  const { data: listDeliverableData, isLoading: isDeliverableDataLoading } =
    useListDeliverables(activeCcid, level, config.imageTag, deliverablesType);

  useEffect(() => {
    if (config.level !== level) {
      navigate(`${location.pathname}`);
    }
  }, [config, level, location.pathname, navigate]);

  useEffect(() => {
    const listDeliverableDataTyped = listDeliverableData as any;
    const deliverables =
      listDeliverableDataTyped?.images ||
      listDeliverableDataTyped?.scripts ||
      listDeliverableDataTyped?.fonts ||
      [];
    setUploadedDeliverables(deliverables);
  }, [listDeliverableData]);

  useUnload((e: BeforeUnloadEvent) => {
    if (!isModalOpen) {
      return;
    }
    e.preventDefault();
    e.returnValue = "";
  });

  const handleCheckboxChange = (imgId?: string, isAllSelected?: boolean) => {
    setUploadedDeliverables((prevState) =>
      prevState?.map((img: any) => {
        if (imgId) {
          if (img[idType] === imgId) {
            return {
              ...img,
              isChecked: !img.isChecked,
            };
          }
          return {
            ...img,
          };
        }
        return {
          ...img,
          isChecked: !isAllSelected,
        };
      }),
    );
  };

  const DropzoneStyle = {
    dropzone: {
      width: "100%",
      height: "252px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "white",
      border: `1px dashed ${theme.palette.frenchGray}`,
      borderRadius: "16px",
    },
    input: { display: "none" },
    dropzoneReject: {
      border: `1px dashed ${theme.palette.charade}`,
      background: theme.palette.midGray,
    },
    inputLabel: (
      files: unknown,
      extra: { reject: boolean },
    ): { color: string } | Record<string, never> =>
      extra.reject ? { color: theme.palette.charade } : {},
  };

  const getFilesFromEvent: (
    event: React.DragEvent<HTMLElement> | ChangeEvent<HTMLInputElement> | Event,
  ) => File[] | Promise<File[]> = (event) =>
    new Promise((resolve) => {
      fromEvent(event as Event).then((chosenFiles) => {
        setChosenFilesQauntity(chosenFiles.length);

        resolve(chosenFiles as File[]);
      });
    });

  const handleChangeStatus = (file: IFileUploadWithMeta) => {
    if (optionalRetryUpload(file)) {
      return;
    }

    let fileToupload = { ...file } as FilesWithMetaProps;
    let hasErrorOnupload;
    const uploadedFileData = uploadFilesId.find(
      (f) => f.fileId === file.meta.id,
    );

    if (uploadErrorsList.includes(file.meta.status as ErrorStatusValue)) {
      const hasNewError =
        uploadErrors.filter((f) => f.fileId === file.meta.id).length < 1;

      setUploadErrors((prevState) => {
        if (prevState.length < 1 || hasNewError) {
          return [
            ...prevState,
            {
              fileId: file.meta.id,
              errorMessage: `${file.meta.name}: ${getUploadErrorMessage(
                file.meta.status as ErrorStatusValue,
              )}`,
            },
          ];
        }
        return prevState;
      });

      hasErrorOnupload = true;
    }

    if (uploadedFileData) {
      fileToupload = {
        ...fileToupload,
        fileUploadId: uploadedFileData.uploadId,
        errorOnUpload: hasErrorOnupload,
      };
    }
    dispatch({ type: "GET_FILES", payload: fileToupload });
  };

  useEffect(() => {
    if (filesWithMetaData.filesWithMetaData.length < 1) {
      const timer = setTimeout(() => {
        setIsOpenModal(false);
      }, 350);

      return () => clearTimeout(timer);
    }
    return () => null;
  }, [filesWithMetaData]);

  useEffect(() => {
    if (
      filesWithMetaData.filesWithMetaData.length > 0 &&
      chosenFilesQuantity === filesWithMetaData.filesWithMetaData.length
    ) {
      if (
        filesWithMetaData.filesWithMetaData.every(
          (f) => f.meta.status === "rejected_file_type",
        )
      ) {
        dispatch({ type: "SET_TO_INITAL_STATE" });
        setUploadErrors([]);
        setShowWrongFormatErrorMessage(true);
        setChosenFilesQauntity(0);
      } else {
        setShowWrongFormatErrorMessage(false);
        setIsOpenModal(true);
      }
    }
  }, [filesWithMetaData.filesWithMetaData, chosenFilesQuantity]);
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" marginTop={10}>
        {" "}
        <CircularProgress data-testid="spinner" />
      </Box>
    );
  }

  return (
    <Container sx={{ py: 2 }}>
      {synopsisDescription && (
        <Card sx={{ marginBottom: "1rem", marginTop: "0.5rem" }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Synopsis
            </Typography>

            <Typography marginTop="1rem">{synopsisDescription}</Typography>
          </CardContent>
        </Card>
      )}
      {entitlementsData?.canUpload && (
        <>
          <Typography variant="h4">
            {config?.headerTitle || "Upload Images"}
          </Typography>
          <Stack direction="row" alignItems="baseline" marginBottom={1}>
            Drag &amp; drop a file(s) into the box below to upload files.{" "}
            <Tooltip
              title={`Upload any files in the following formats: ${uploadFileTypes[deliverablesType]}`}
              placement="top"
            >
              <Button
                size="small"
                sx={{
                  padding: 0,
                  position: "relative",
                  top: "5px",
                }}
              >
                <HelpOutline
                  aria-label="Help icon"
                  // eslint-disable-next-line jsx-a11y/aria-role
                  role="graphics-symbol"
                  color="primary"
                />
              </Button>
            </Tooltip>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Box minWidth={350} minHeight={210}>
              <Dropzone
                accept={config.acceptFormat || dropZoneAcceptedBrandLevel}
                getUploadParams={async (file: IFileUploadWithMeta) => {
                  try {
                    const contentTypeValue =
                      supportedTypes[getFileExtension(file.meta.name)] ||
                      file.meta.type;
                    const response: any = await startImageUpload(
                      level,
                      activeCcid,
                      {
                        contentType: contentTypeValue,
                        objectName: file.meta.name,
                        imageTag: config.imageTag,
                      },
                      deliverablesType,
                    );
                    setUploadFilesId((prevState) => {
                      const fileIndex = prevState.findIndex(
                        (f) => f.fileId === file.meta.id,
                      );
                      if (fileIndex > -1) {
                        return [
                          ...prevState.slice(0, fileIndex),
                          {
                            fileId: file.meta.id,
                            uploadId: response[idType],
                          },
                          ...prevState.slice(fileIndex + 1),
                        ];
                      }
                      return [
                        ...prevState,
                        {
                          fileId: file.meta.id,
                          uploadId: response[idType],
                        },
                      ];
                    });

                    return {
                      method: "PUT",
                      url: response.presignedUrl,
                      body: file.file,
                      headers: { "Content-Type": contentTypeValue },
                    };
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  } catch (err: any) {
                    if (!optionalRetryUpload(file)) {
                      setUploadErrors((prevState) => [
                        ...prevState,
                        {
                          fileId: file?.meta?.id,
                          errorMessage: `${
                            file?.meta?.name
                          }: ${getUploadErrorMessage(
                            "error_upload_params",
                            err?.response?.data,
                          )}`,
                        },
                      ]);
                    }
                  }
                  return {
                    url: "",
                  };
                }}
                onChangeStatus={handleChangeStatus}
                PreviewComponent={() => <></>}
                InputComponent={CustomInput}
                getFilesFromEvent={getFilesFromEvent}
                styles={DropzoneStyle}
              />
            </Box>
            {config.titleCopy1 && (
              <Box>
                <Typography variant="h5" marginY={2}>
                  {config.titleCopy1}
                </Typography>
                <div>{config.descriptionCopy1}</div>

                <Typography variant="h5" marginY={2}>
                  {config.titleCopy2}
                </Typography>
                <div>{config.descriptionCopy2}</div>
              </Box>
            )}
          </Stack>

          {isModalOpen && (
            <PreviewModal
              isModalOpen={isModalOpen}
              setIsOpenModal={setIsOpenModal}
              filesWithMetaData={filesWithMetaData}
              ccid={activeCcid}
              level={level}
              uploadErrors={uploadErrors}
              setUploadErrors={setUploadErrors}
              dispatch={dispatch}
              type={deliverablesType}
            />
          )}
        </>
      )}

      {entitlementsData?.canView ? (
        <>
          <Typography
            style={{ display: showWrongFormatErrorMessage ? "block" : "none" }}
          >
            {config.formatErrorMessage}
          </Typography>
          <div style={{ marginBottom: "16px" }}>
            <Typography variant="h4" marginTop={2}>
              {config.disableView ? "" : "View"}
              {entitlementsData?.canDownload
                ? `${!config.disableView ? " & download" : "Download"}`
                : ""}{" "}
              {config.viewTitle ?? "images"}
            </Typography>
            <p
              style={{
                margin: " 0 0 10px",
                lineHeight: "24px",
              }}
            >
              {config.viewAndDownloadCopy
                ? config.viewAndDownloadCopy
                : "Browse the images for this episode below. You can remove, download, and edit embargo information for each image below."}
            </p>
          </div>
          {isDeliverableDataLoading && <CircularProgress />}

          {!isDeliverableDataLoading &&
            uploadedDeliverables &&
            uploadedDeliverables.length > 0 && (
              <div style={{ marginTop: 10 }}>
                <UploadedImages
                  ccid={activeCcid}
                  level={level}
                  deliverables={uploadedDeliverables}
                  handleCheckboxChange={handleCheckboxChange}
                  entitlements={entitlementsData}
                  seriesDetails={seriesDetails}
                  titleDetails={titleDetails}
                  brandName={brandName}
                  disableView={Boolean(config.disableView)}
                  type={deliverablesType}
                />
              </div>
            )}
        </>
      ) : (
        <NoAccess />
      )}
    </Container>
  );
};

export default Images;
