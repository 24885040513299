/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "react-query";
import { submitRiskFormData, submitRiskFormDataNew } from "../../api/spotlight";
import {
  NotificationFormValues,
  NotificationFormValuesV3,
} from "../../containers/NotificationForm/getInitialNotificationFormValues";
import { noop } from "../../utils/appHelper";

interface UseSubmitRiskArgs {
  riskCcid: string;
  formData: NotificationFormValues;
  productionType: "series" | "titles";
}

export const useSubmitRisk = (onSuccess = noop, onError = noop) =>
  useMutation(
    (payload: UseSubmitRiskArgs) =>
      submitRiskFormData(
        payload.riskCcid,
        payload.formData,
        payload.productionType,
      ),
    { onSuccess, onError },
  );

interface UseSubmitRiskArgsNew {
  riskCcid: string;
  formData: NotificationFormValues | NotificationFormValuesV3;
  version: "v2" | "v3";
}

export const useSubmitRiskNew = ({ onSuccess = noop, onError = noop }) =>
  useMutation(
    (payload: UseSubmitRiskArgsNew) =>
      submitRiskFormDataNew({
        riskId: payload.riskCcid,
        formData: payload.formData,
        version: payload.version,
      }),
    { onSuccess, onError },
  );
