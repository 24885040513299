import {
  Typography,
  Stack,
  Card,
  CardContent,
  InputAdornment,
} from "@mui/material";
import { useFormikContext } from "formik";
import { type HealthAndSafetySection } from "../yupSchemas/healthAndSafetySchema";
import { type NotificationFormSubmitValues } from "../NotificationFormNew";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { YesNoField } from "../formFields/YesNoField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import { EmailField } from "../formFields/EmailField";
import { CheckboxField } from "../formFields/CheckboxField";
import { UseUpdateRiskArgs } from "../../../hooks";

const trainingOptions = [
  {
    label: "No formal Health and Safety Training",
    value: "NoTraining",
  },
  {
    label: "Health and Safety Training received via an external provider",
    value: "External",
  },
  {
    label: "ITV's Internal Managing Risk Health and Safety Training ",
    value: "Internal",
  },
];

const activitiesOptions = [
  {
    label: "Food handling",
    value: "FoodHandling",
  },
  {
    label: "Filming with animals",
    value: "FilmingWithAnimals",
  },
  {
    label: "Underground filming",
    value: "Underground",
  },
  {
    label: "Extreme Sports",
    value: "Extreme",
  },
  {
    label: "The use of jet skis or other high speed water vessels",
    value: "JetSkisOrOther",
  },
  {
    label: "Diving or other waterborne activities",
    value: "DivingOrOther",
  },
  {
    label: "Other potential high risk activities",
    value: "OtherHighRisk",
  },
  {
    label: "None of the above",
    value: "NoneOfAbove",
  },
];

const filmingTechniquesOptions = [
  {
    label: "No use of non-standard filming techniques",
    value: "NoUse",
  },
  {
    label: "Under 10% of filming will use non-standard filming techniques",
    value: "Under10Percent",
  },
  {
    label: "10% - 25% of filming will use non-standard filming techniques",
    value: "Between10And25Percent",
  },
  {
    label: "Over 25% of filming will use non-standard filming techniques",
    value: "Over25Percent",
  },
];

const weatherOptions = [
  {
    label: "No filming in these conditions",
    value: "NoUse",
  },
  {
    label: "Under 5% of the production will be filmed in these conditions",
    value: "Under5Percent",
  },
  {
    label: "5 - 10% of the production will be filmed in these conditions",
    value: "Between5And10Percent",
  },
  {
    label: "Over 10% of the production will be filmed in these conditions",
    value: "Over10Percent",
  },
];

const firearmsOptions = [
  {
    label: "No firearms or weapons will be used on this production",
    value: "NoFirearms",
  },
  {
    label:
      "Imitation firearms or imitation weapons will be used on this production",
    value: "Imitation",
  },
  {
    label:
      "Non-imitation weapons (but not firearms) will be used on this production",
    value: "NonImitation",
  },
  {
    label: "Live firearms will be used on this production",
    value: "LiveFirearms",
  },
];

const sfxOptions = [
  {
    label: "No special effects will be used as part of this production",
    value: "NoSfx",
  },
  {
    label:
      "The production is likely to have a single scene where special effects are used",
    value: "LikelySingle",
  },
  {
    label:
      "The production is likely to have multiple scenes where SFX are used",
    value: "LikelyMultiple",
  },
  {
    label: "A significant proportion of the production will include SFX",
    value: "Significant",
  },
];

const stuntOptions = [
  {
    label: "No stunts will be used as part of this production",
    value: "NoStunt",
  },
  {
    label:
      "The production is likely to have a single scene where stunts are used",
    value: "LikelySingle",
  },
  {
    label:
      "The production is likely to have multiple scenes where stunts are used",
    value: "LikelyMultiple",
  },
  {
    label: "A significant proportion of the production will include stunts",
    value: "Significant",
  },
];

const setBuildOptions = [
  {
    label: "No set build required as part of this production",
    value: "NoSet",
  },
  {
    label: "Minor set build required as part of this production",
    value: "Minor",
  },
  {
    label: "Moderate set build required as part of this production",
    value: "Moderate",
  },
  {
    label: "Significant set build required as part of this production",
    value: "Significant",
  },
];

const filmingLocationOptions = [
  {
    label: "All filming will be conducted in a studio",
    value: "Studio",
  },
  {
    label:
      "The majority of filming will be conducted in the studio however some shoots will be conducted on location",
    value: "MajorityInStudio",
  },
  {
    label: "Around half of all filming will take place on location",
    value: "HalfInLocation",
  },
  {
    label: "The majority of filming will take place on location",
    value: "MajorityInLocation",
  },
];

const locationTypeOptions = [
  {
    label:
      "The production will not be filming in locations that could directly result in an injury to contributors, crew, or the public",
    value: "InLocation",
  },
  {
    label:
      "The production will be carrying out a single shoot in a location that could directly result in an injury to contributors, crew, or the public",
    value: "SingleShootInLocation",
  },
  {
    label:
      "The production will be carrying out multiple shoots in a location(s) that could directly result in an injury to contributors, crew, or the public",
    value: "MultipleShootsInLocation",
  },
  {
    label: "Not yet known",
    value: "NotYetKnown",
  },
];

const vulnerableOptions = [
  {
    label:
      "The production will not be working with either children and vulnerable adults",
    value: "NoVulnerableAndChildren",
  },
  {
    label:
      "The production will be working with children but not vulnerable adults",
    value: "WithChildren",
  },
  {
    label:
      "The production will be working with vulnerable adults but not children",
    value: "WithVulnerableAdults",
  },
  {
    label:
      "The production will be working with both children and vulnerable adults",
    value: "Both",
  },
];

interface HealthAndSafetyPageProps {
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

export function HealthAndSafetyPage({
  autosaveForm,
}: HealthAndSafetyPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValues>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.healthAndSafety || {};
  const errors = formik.errors.healthAndSafety || {};

  // the logic for this is inverted - "Yes" means `false` and "No" means `true`.
  // this is more confusing for us but less confusing for the user
  const assignedResponsibilityHandler = (isYes: boolean) => {
    return function handleResponsibilityChange() {
      formik
        .getFieldHelpers("healthAndSafety.responsibleNone")
        .setValue(!isYes);
    };
  };

  const checkboxesChangeHandler = (fieldName: keyof HealthAndSafetySection) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`healthAndSafety.${fieldName}`).setValue(values);
    };
  };

  const yesNoChangeHandler = (fieldName: keyof HealthAndSafetySection) => {
    return function handleYesNoChange(isYes: boolean) {
      formik.getFieldHelpers(`healthAndSafety.${fieldName}`).setValue(isYes);
    };
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">
                Risk as a factor of Health and Safety
              </Typography>

              <Typography variant="body1">
                Please indicate whether pre-production or filming will involve
                the following:
              </Typography>

              <YesNoField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "responsibleNone",
                }}
                label="Has somebody been assigned responsibility for Health and Safety on this production?"
                error={errors.responsibleNone}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={assignedResponsibilityHandler}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={
                  typeof values.responsibleNone === "boolean"
                    ? !values.responsibleNone
                    : undefined
                }
                autosaveForm={autosaveForm}
              />

              {values.responsibleNone === false ? (
                <>
                  <SingleLineTextField
                    version="v2"
                    fieldInfo={{
                      sectionId: "healthAndSafety",
                      fieldName: "responsible",
                    }}
                    label="Who is responsible for Health and Safety on this production?"
                    error={errors.responsible}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.responsible}
                    placeholder="Forename Surname"
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonOutline />
                      </InputAdornment>
                    }
                    helperText="This person is responsible for reporting all Health and Safety incidents to the ITV Incident Reporting Portal or to your Health and Safety risk advisor within 72 hours."
                    autosaveForm={autosaveForm}
                  />

                  <EmailField
                    version="v2"
                    fieldInfo={{
                      sectionId: "healthAndSafety",
                      fieldName: "responsibleEmail",
                    }}
                    label="What is the email of the person responsible for Health and Safety on this production?"
                    error={errors.responsibleEmail}
                    shouldDisplayErrors={shouldDisplayErrors}
                    handleChange={formik.handleChange}
                    handleBlur={formik.handleBlur}
                    isRequired={true}
                    value={values.responsibleEmail}
                    placeholder="example@itv.com"
                    startAdornment={
                      <InputAdornment position="start">
                        <MailOutline />
                      </InputAdornment>
                    }
                    autosaveForm={autosaveForm}
                  />
                </>
              ) : null}

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "training",
                }}
                label="What is the highest level of Health and Safety Training this person has received?"
                error={errors.training}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.training}
                options={trainingOptions}
                autosaveForm={autosaveForm}
              />

              {values.training === "External" ? (
                <MultiLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "healthAndSafety",
                    fieldName: "trainingCourse",
                  }}
                  label="Please indicate external course and provider"
                  error={errors.trainingCourse}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.trainingCourse}
                  placeholder="Type answer here"
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <CheckboxField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "activities",
                }}
                label="Will the production be conducting any of the following activities?"
                error={
                  Array.isArray(errors.activities)
                    ? errors.activities[0]
                    : errors.activities
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("activities")}
                isRequired={true}
                value={values.activities}
                options={activitiesOptions}
                autosaveForm={autosaveForm}
              />

              <MultiLineTextField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "otherActivities",
                }}
                label="Please indicate other potential high risk activities"
                error={errors.otherActivities}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.otherActivities}
                placeholder="Type answer here"
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "filmingTechniques",
                }}
                label="Will the programme include the use of drones for filming, filming from aircraft, filming from boats and/or filming from vehicles?"
                error={errors.filmingTechniques}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.filmingTechniques}
                options={filmingTechniquesOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "weatherConditions",
                }}
                label="Will the programme include extended filming during adverse weather conditions or darkness?"
                error={errors.weatherConditions}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.weatherConditions}
                options={weatherOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "firearms",
                }}
                label="Will firearms or other weapons (including imitation firearms or weapons) be used on this production?"
                error={errors.firearms}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.firearms}
                options={firearmsOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "healthAndSafety", fieldName: "sfx" }}
                label="Will special effects be developed and deployed as part of the production?"
                error={errors.sfx}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.sfx}
                options={sfxOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "healthAndSafety", fieldName: "stunt" }}
                label="Will stunts be used as part of the production?"
                error={errors.stunt}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.stunt}
                options={stuntOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "setBuild",
                }}
                label="To what extent will set construction be required on the production?"
                error={errors.setBuild}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.setBuild}
                options={setBuildOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "filmingLocation",
                }}
                label="Where will filming take place?"
                error={errors.filmingLocation}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.filmingLocation}
                options={filmingLocationOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "locationType",
                }}
                label="Does the filming location present any health or safety risks for the public, contributors, or crew?"
                error={errors.locationType}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.locationType}
                options={locationTypeOptions}
                autosaveForm={autosaveForm}
              />

              <YesNoField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "audience",
                }}
                label="Will the production have a studio audience?"
                error={errors.audience}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("audience")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.audience}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "healthAndSafety",
                  fieldName: "vulnerable",
                }}
                label="Will the production be working with children, or/and vulnerable adults?"
                error={errors.vulnerable}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.vulnerable}
                options={vulnerableOptions}
                autosaveForm={autosaveForm}
              />

              <YesNoField
                version="v2"
                fieldInfo={{ sectionId: "healthAndSafety", fieldName: "covid" }}
                label="Will you be putting any Covid-specific controls in place for this production?"
                error={errors.covid}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={yesNoChangeHandler("covid")}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.covid}
                autosaveForm={autosaveForm}
              />

              {values.covid === true ? (
                <MultiLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "healthAndSafety",
                    fieldName: "covidDetails",
                  }}
                  label="If yes, please detail these controls"
                  error={errors.covidDetails}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.covidDetails}
                  placeholder="Type answer here"
                  autosaveForm={autosaveForm}
                />
              ) : null}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
