import { FC, useMemo } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMetadata, useProductionCardDetails } from "../../hooks";
import ProductionCardRisk from "./ProductionCardRisk";
import ProductionCardStatusSelect from "./ProductionCardStatusSelect";
import ProductionCardProdLead from "./ProductionCardProdLead";

const ProductionCard: FC<{ ccid?: string }> = ({ ccid }) => {
  const theme = useTheme();
  const { data: productionMetadata, isLoading: isLoadingProductionMetadata } =
    useMetadata(ccid);
  const { data: productionCardData, isLoading: isLoadingProductionCardData } =
    useProductionCardDetails(ccid);
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isDesktop = !isSm && !isXs;
  const getProductionUrl = (): string => {
    switch (productionMetadata?.productionType) {
      case "SPECIAL":
        return `special/${ccid}`;
      case "FILM":
        return `special/${ccid}`;
      case "BRANDED_SPECIAL":
        return `programmes/${productionMetadata?.parentCcid}/series/0/titles/${ccid}`;
      default:
        return `programmes/${productionMetadata?.parentCcid}/series/${ccid}`;
    }
  };

  const getName = (): string | undefined => {
    switch (productionMetadata?.productionType) {
      case "EPISODE":
        return productionMetadata?.titleName || productionMetadata?.parentName;
      case "FILM":
      case "BRANDED_SPECIAL":
        return productionMetadata?.titleName || productionMetadata?.parentName;
      case "SPECIAL":
        return productionMetadata?.parentName
          ? `${productionMetadata?.parentName}: ${productionMetadata?.titleName}`
          : productionMetadata?.titleName;
      default:
        return (
          productionMetadata?.parentName ||
          productionMetadata?.seriesName ||
          productionMetadata?.titleName
        );
    }
  };

  const getProductionType = (includeSeriesNumber: boolean): string => {
    switch (productionMetadata?.productionType) {
      case "EPISODE":
        return "Episode";
      case "FILM":
        return "Film";
      case "BRANDED_SPECIAL":
        return "Special";
      case "SPECIAL":
        return "One Off";
      case "SERIES":
        if (productionMetadata?.seriesNumber && includeSeriesNumber)
          return `Series ${productionMetadata?.seriesNumber}`;
        return "Series";
      default:
        return "";
    }
  };

  const productionYears = useMemo(() => {
    if (productionMetadata?.productionYear)
      return productionMetadata?.productionYear;

    if (productionMetadata) {
      if (
        productionMetadata.productionYearSpan?.earliest &&
        productionMetadata.productionYearSpan?.latest &&
        productionMetadata.productionYearSpan?.earliest !==
          productionMetadata.productionYearSpan?.latest
      ) {
        return `${productionMetadata.productionYearSpan?.earliest} - ${productionMetadata.productionYearSpan?.latest}`;
      }
      return (
        productionMetadata.productionYearSpan?.latest ||
        productionMetadata.productionYearSpan?.earliest ||
        "N/A"
      );
    }

    return "-";
  }, [productionMetadata]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 3,
          border: 0,
          borderTop: "4px solid #1976d2",
          backgroundColor: "#F5FAFD",
        }}
      >
        <CardContent
          sx={{
            padding: "16px 24px 16px 24px",
            // MUI sets the last child to have extra padding for some reason, so we have to overwrite it here
            "&:last-child": { paddingBottom: "16px" },
          }}
        >
          <Typography variant="overline" color="secondary" gutterBottom>
            {getProductionType(false)}
          </Typography>

          <Grid
            wrap="nowrap"
            container
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent="space-between"
          >
            {isLoadingProductionMetadata ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Skeleton variant="text" width={250} />
                <Skeleton variant="text" width={75} />
              </Stack>
            ) : (
              <>
                <Stack direction="row" spacing={1.5} px={1} alignItems="center">
                  <Typography variant="h5">{getName()}</Typography>
                  {getProductionType(true) ? (
                    <Chip
                      sx={{
                        background: "transparent",
                        padding: "12px 4px",
                      }}
                      variant="outlined"
                      color="primary"
                      label={
                        <Typography
                          variant="body1"
                          color={theme.palette.text.secondary}
                          letterSpacing="0.15px"
                        >
                          {productionMetadata?.seriesNumber
                            ? `Series ${productionMetadata?.seriesNumber}`
                            : getProductionType(true)}
                        </Typography>
                      }
                      size="small"
                    />
                  ) : null}
                </Stack>
                {isDesktop ? (
                  <Grid item xs={3}>
                    {isLoadingProductionCardData ? (
                      <Skeleton variant="text" width="100%" />
                    ) : (
                      <ProductionCardStatusSelect
                        productionStatus={productionCardData?.productionStatus}
                        canEdit={!!productionCardData?.productionRiskRating}
                        ccid={ccid || ""}
                        type={
                          productionMetadata?.productionType === "SERIES"
                            ? "series"
                            : "titles"
                        }
                      />
                    )}
                  </Grid>
                ) : null}
              </>
            )}
          </Grid>
          <Grid item xs={3}>
            {isLoadingProductionMetadata ? (
              <Stack direction="row" spacing={2}>
                <Skeleton variant="text" sx={{ width: "45%" }} />
                <Divider
                  sx={{ bgcolor: "black" }}
                  orientation="vertical"
                  flexItem
                />
                <Skeleton variant="text" sx={{ width: "45%" }} />
              </Stack>
            ) : (
              <Stack
                direction="row"
                spacing={1}
                divider={
                  <Typography
                    variant="body1"
                    color={theme.palette.text.secondary}
                  >
                    |
                  </Typography>
                }
              >
                {productionMetadata?.titleId &&
                  (productionMetadata?.productionType === "FILM" ||
                    productionMetadata?.productionType === "BRANDED_SPECIAL" ||
                    productionMetadata?.productionType === "SPECIAL") && (
                    <Typography
                      variant="body1"
                      color={theme.palette.text.secondary}
                    >
                      {productionMetadata?.titleId}
                    </Typography>
                  )}

                {productionMetadata?.seriesId && (
                  <Typography
                    variant="body1"
                    color={theme.palette.text.secondary}
                  >
                    {productionMetadata.seriesId}
                  </Typography>
                )}

                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                >
                  {productionYears}
                </Typography>
              </Stack>
            )}
          </Grid>
          <Divider sx={{ bgcolor: "black", my: 0.5 }} flexItem />
          <Grid
            wrap="nowrap"
            container
            direction="row"
            alignItems="stretch"
            spacing={1}
            justifyContent="space-between"
            paddingTop="16px"
          >
            <Grid item xs={3}>
              {isLoadingProductionCardData ? (
                <Skeleton variant="text" width={100} />
              ) : (
                <ProductionCardProdLead
                  type={
                    productionMetadata?.productionType === "SERIES"
                      ? "series"
                      : "titles"
                  }
                  productionLead={productionCardData?.productionLead}
                  ccid={ccid || ""}
                  canEdit={!!productionCardData?.productionRiskRating}
                />
              )}
            </Grid>
            {isDesktop ? (
              <Grid item xs>
                {isLoadingProductionCardData ? (
                  <Skeleton variant="text" width="100%" />
                ) : (
                  <>
                    <Typography
                      variant="caption"
                      color={theme.palette.text.secondary}
                      letterSpacing="0.4px"
                    >
                      Risk & Insurance Score
                    </Typography>
                    <ProductionCardRisk
                      productionRiskRating={
                        productionCardData?.productionRiskRating
                      }
                    />
                  </>
                )}
              </Grid>
            ) : null}
            <Grid item>
              <CardActions>
                <Button
                  component={RouterLink}
                  variant="contained"
                  size="large"
                  sx={{ fontWeight: "normal" }}
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  to={getProductionUrl()}
                >
                  View Production
                </Button>
              </CardActions>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default ProductionCard;
