import getEnvironment from "./getEnviroment";

export enum SERVICES {
  browse_assets = "BROWSE_ASSETS",
  iconik = "ICONIK",
  deliverables = "DELIVERABLES",
  portal = "PORTAL",
  security = "SECURITY",
  synopsis = "SYNOPSIS",
  spotlight = "SPOTLIGHT",
  legalDeliverables = "LEGAL_DELIVERABLES",
  storyboard = "STORYBOARD",
  notifications = "NOTIFICATIONS",
  access_permissions = "ACCESS_PERMISSIONS",
  google_drive = "GOOGLE_DRIVE",
  slack = "slack",
}

interface Enviroment {
  production: string;
  test: string;
  staging: string;
  localhost: string;
}

const TEST_BASE_URL = "http://127.0.0.1:12345/api/public";
const PROD_BASE_URL = "https://studios-portal-cloudfront.prd.ca.itv.com";

const urls: Record<SERVICES, Enviroment> = {
  [SERVICES.browse_assets]: {
    production: `${PROD_BASE_URL}/browseassets`,
    test: `${TEST_BASE_URL}/browseassets`,
    staging:
      "https://cit-studios-browse-assets.stg.ca.itv.com/api/public/browseassets",
    localhost:
      "https://cit-studios-browse-assets.stg.ca.itv.com/api/public/browseassets",
  },
  [SERVICES.iconik]: {
    production: `${PROD_BASE_URL}/iconik`,
    test: `${TEST_BASE_URL}/iconik`,
    staging: "https://cit-studios-iconik.stg.ca.itv.com/api/public/iconik",
    localhost: "https://cit-studios-iconik.stg.ca.itv.com/api/public/iconik",
  },
  [SERVICES.deliverables]: {
    production: `${PROD_BASE_URL}/deliverables`,
    test: `${TEST_BASE_URL}/deliverables`,
    staging:
      "https://cit-studios-images.stg.ca.itv.com/api/public/deliverables",
    localhost:
      "https://cit-studios-images.stg.ca.itv.com/api/public/deliverables",
  },
  [SERVICES.google_drive]: {
    production: `${PROD_BASE_URL}/googledrive`,
    test: `${TEST_BASE_URL}/googledrive`,
    staging:
      "https://studios-google-drive.stg.ca.itv.com/api/public/googledrive",
    localhost:
      "https://studios-google-drive.stg.ca.itv.com/api/public/googledrive",
  },
  [SERVICES.portal]: {
    production: `${PROD_BASE_URL}/portal`,
    test: `${TEST_BASE_URL}/portal`,
    staging: "https://cit-studios-portal.stg.ca.itv.com/api/public/portal",
    localhost: "https://cit-studios-portal.stg.ca.itv.com/api/public/portal",
  },
  [SERVICES.security]: {
    production: `${PROD_BASE_URL}/portal`,
    test: `${TEST_BASE_URL}/portal`,
    staging: "https://cit-studios-portal.stg.ca.itv.com/api/public/portal",
    localhost: "https://cit-studios-portal.stg.ca.itv.com/api/public/portal",
  },
  [SERVICES.synopsis]: {
    production: `${PROD_BASE_URL}/synopsis`,
    test: `${TEST_BASE_URL}/synopsis`,
    staging: "https://cit-studios-synopsis.stg.ca.itv.com/api/public/synopsis",
    localhost:
      "https://cit-studios-synopsis.stg.ca.itv.com/api/public/synopsis",
  },
  [SERVICES.spotlight]: {
    production: `${PROD_BASE_URL}/spotlight`,
    test: `${TEST_BASE_URL}/spotlight`,
    staging: "https://studios-spotlight.stg.ca.itv.com/api/public/spotlight",
    localhost: "https://studios-spotlight.stg.ca.itv.com/api/public/spotlight",
  },
  [SERVICES.storyboard]: {
    production: `${PROD_BASE_URL}/storyboard`,
    test: `${TEST_BASE_URL}/storyboard`,
    staging: "https://studios-storyboard.stg.ca.itv.com/api/public/storyboard",
    localhost:
      "https://studios-storyboard.stg.ca.itv.com/api/public/storyboard",
  },
  [SERVICES.access_permissions]: {
    production: `${PROD_BASE_URL}/accesspermissions`,
    test: `${TEST_BASE_URL}/accesspermissions`,
    staging:
      "https://studios-access-permissions.stg.ca.itv.com/api/public/accesspermissions",
    localhost:
      "https://studios-access-permissions.stg.ca.itv.com/api/public/accesspermissions",
  },
  [SERVICES.notifications]: {
    production: `${PROD_BASE_URL}/notifications`,
    test: `${TEST_BASE_URL}/notifications`,
    staging:
      "https://studios-portal-notifications.stg.ca.itv.com/api/public/notifications",
    localhost:
      "https://studios-portal-notifications.stg.ca.itv.com/api/public/notifications",
  },
  [SERVICES.slack]: {
    production: `https://hooks.slack.com/services/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`,
    test: `https://hooks.slack.com/services/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`,
    staging:
      "https://hooks.slack.com/services/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",
    localhost:
      "https://hooks.slack.com/services/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",
  },
  [SERVICES.legalDeliverables]: {
    production: `${PROD_BASE_URL}/legal-deliverables`,
    test: `${TEST_BASE_URL}/legal-deliverables`,
    staging: "https://studios-legal-deliverables.stg.ca.itv.com/api/public/legal-deliverables",
    localhost: "https://studios-legal-deliverables.stg.ca.itv.com/api/public/legal-deliverables",
  },
};

export const getAPI = (service: SERVICES): string =>
  urls[service][getEnvironment()];
