import { PersonOutline, MailOutline } from "@mui/icons-material";
import { TEXT, RADIO, SELECT } from "../../../../../components";
import {} from "../../../../../global.styles";

export const basicFormConfig = [
  {
    sectionTitle: "Basic Information",
    key: "Basic Information",
    description:
      "This information includes rudimentary and technical information regarding your production",
    formFields: [
      {
        type: SELECT,
        label: "Production status",
        id: "productionStatus",
        value: undefined,
        placeholder: "Please select an option’",
        columnCount: 6,
        required: true,
        disabled: false,
        options: [],
      },
      {
        type: RADIO,
        label: "Production Type",
        id: "productionType",
        value: "showForBroadcast",
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Show for broadcast",
            value: "Broadcast",
          },
          {
            label: "Development/pilot/taster show/sizzle",
            value: "Sizzle",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
      },
      {
        type: TEXT,
        label: "Other Production Type",
        id: "otherProductionType",
        value: "",
        columnCount: 12,
        required: true,
        disabled: false,
        excludedFromCompletion: true,
        visibleDependencies: {
          productionType: "Other",
        },
      },
      {
        type: SELECT,
        label: "Broadcaster / Network Name",
        id: "broadcaster",
        value: undefined,
        placeholder: "Please select an option",
        columnCount: 6,
        required: true,
        disabled: false,
        options: [],
      },
      {
        type: TEXT,
        label: "Other Broadcaster",
        id: "otherBroadcaster",
        columnCount: 6,
        required: true,
        disabled: false,
        excludedFromCompletion: true,
        visibleDependencies: {
          broadcaster: "Other",
        },
      },
      {
        type: RADIO,
        label: "Is this show scripted or non-scripted?",
        id: "contentType",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Scripted",
            value: "Scripted",
          },
          {
            label: "Non-scripted",
            value: "Non-Scripted",
          },
        ],
      },
      {
        type: RADIO,
        label: "Is this show prerecorded or live?",
        id: "recordingType",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Pre-recorded",
            value: "PreRecorded",
          },
          {
            label: "Live",
            value: "Live",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will any production be taking place outside of your base location?",
        id: "outsideLocation",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
  },
  {
    sectionTitle: "Production Team Details",
    key: "Production Team Details",
    description: "Who is the core team and label working on this production?",
    formFields: [
      {
        type: TEXT,
        label: "Production Label",
        id: "productionLabel",
        value: "potato",
        columnCount: 6,
        required: false,
        disabled: true,
      },
      {
        type: TEXT,
        label: "Production contact name",
        id: "productionContactName",
        placeholder: "Forename Surname",
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <PersonOutline />,
      },
      {
        type: TEXT,
        label: "Production contact email",
        id: "productionContactEmail",
        placeholder: "example@itv.com",
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <MailOutline />,
      },
      {
        type: TEXT,
        label: "Production creative lead name",
        id: "creativeLeadName",
        placeholder: "Forename Surname",
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <PersonOutline />,
      },
      {
        type: TEXT,
        label: "Production creative lead email",
        id: "creativeLeadEmail",
        placeholder: "example@itv.com",
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <MailOutline />,
      },
      // getEmptyField(4),
    ],
  },
];
