import { Typography } from "@mui/material";
import { RADIO, AUTOCOMPLETE_WITH_SINGLE_SELECTION } from "../../../components";

export const complianceFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Compliance</Typography>,
    description: "",
    key: "Compliance",
    formFields: [
      {
        type: AUTOCOMPLETE_WITH_SINGLE_SELECTION,
        label: "Which countries will your production be operating in?",
        id: "countries",
        isMultiple: true,
        value: undefined,
        placeholder: "enter location here",
        questionBubble:
          "Countries with ITV studios operations will probably be able to offer you some advice or support, so please get in touch.",
        columnCount: 12,
        optionsMapTo: "countries",
        required: false,
        disabled: false,
      },
      {
        type: RADIO,
        label:
          "Is there a modern slavery risk associated with operating in this country?    Modern Slavery Risk is considered to be any country with a score of over 30 for Vulnerability to Modern Slavery on the Global Slavery Index, which can be found here: https://www.globalslaveryindex.org/2018/data/maps/#prevalence",
        id: "slavery",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Is there a corruption risk associated with operating in this country? Corruption Risk = any country with a CPI score of under 50 on Transparency International's Corruption Perceptions Index https://www.transparency.org/en/cpi/2021/",
        id: "corruption",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Is there a sanctions risk associated with operating in this country?  Sanctions risk = Any country that appears on the financial sanctions imposed in the UK by country. https://www.gov.uk/government/collections/financial-sanctions-regime-specific-consolidated-lists-and-releases",
        id: "sanctions",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Has local tax advice or advice from group tax been sought to date? ",
        id: "tax",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Have you established whether VAT incurred int he overseas territories can be recovered?",
        id: "vat",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ],
  },
];
