import {
  BuildOutlined,
  ContentPasteOutlined,
  CreateNewFolder,
  CreateOutlined,
  GroupAddOutlined,
  LocalMoviesOutlined,
  VideoCameraBackOutlined,
  Add,
} from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { FrontendTaskList } from "../types/types";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { FontIcon } from "../icons/Font";
import { ImageIcon } from "../icons/Images";
import { LogoIcon } from "../icons/Logo";
import { TimePlayIcon } from "../icons/TimePlayIcon";
import { ScriptIcon } from "../icons/Scripts";
import { GoogleLogo } from "../icons/GoogleLogo";

export const preProductionTasks: FrontendTaskList = {
  "RISK-NOTIFICATION-FORM": {
    description: "New Production Notification (SPOT) Form",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./notification-form",
    taskStatusUrl: null,
  },
  "OFCOM-PROVISIONAL-FORM": {
    description: "Ofcom Form (Provisional)",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./ofcom-form",
    taskStatusUrl: null,
  },
  "PRODUCTION-PRINCIPLES-PROVISIONAL-FORM": {
    description: "Production Principles (Provisional)",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./production-principles",
    taskStatusUrl: null,
  },
  "START-UP-FORM": {
    description: "Start Up Form",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./start-up-form",
    taskStatusUrl: null,
  },
  "SHARED-DRIVE": {
    description: "Google Drive",
    icon: <GoogleLogo />,
    taskRoute: "./google-drive",
    taskStatusUrl: null,
  },
  "MY-DRIVE": {
    description: "My Drive",
    icon: <CreateNewFolder sx={{ fontSize: 40 }} />,
    taskRoute: "./my-drive",
    taskStatusUrl: null,
  },
  "UPDATE-COLLABORATORS": {
    description: "Access Management",
    icon: <GroupAddOutlined sx={{ fontSize: 30 }} />,
    taskRoute: "./access-management",
    taskStatusUrl: null,
  },
  "MANAGE-EPISODES": {
    description: "Manage Episodes",
    icon: <Add sx={{ fontSize: 40 }} />,
    taskRoute: "./manage-episodes",
    taskStatusUrl: null,
  },
  "ASSIGN-PRODUCTION-LEAD": {
    description: "Re/Assign Production Lead",
    icon: <PersonIcon sx={{ fontSize: 40 }} />,
    taskRoute: "./production-lead",
    taskStatusUrl: null,
  },
  "ASSIGN-TASKS": {
    description: "Assign Tasks",
    icon: <AssignmentIndOutlinedIcon sx={{ fontSize: 40 }} />,
    taskRoute: "./assign-tasks",
    taskStatusUrl: null,
  },
};

export const inProductionTasks: FrontendTaskList = {
  "ACCESS-ICONIK": {
    description: "Iconik",
    icon: <VideoCameraBackOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "",
    taskStatusUrl: null,
  },
  "UPLOAD-KEY-ART-IMAGES": {
    description: "Upload Images",
    icon: <ImageIcon />,
    taskRoute: "./iconicKeyArtSeries",
    taskStatusUrl: null,
  },
  "UPLOAD-KEY-ART-CONTRIBUTORS-SET-UP-IMAGES": {
    description: "Presenters / Contributors Images",
    icon: <ImageIcon />,
    taskRoute: "./iconicKeyArtSeriesPresenterContributor",
    taskStatusUrl: null,
  },
  "UPLOAD-KEY-ART-CONTRIBUTORS-ON-SET-GALLERY-IMAGES": {
    description: "Gallery Images",
    icon: <ImageIcon />,
    taskRoute: "./iconicKeyArtSeriesGallery",
    taskStatusUrl: null,
  },
  "UPLOAD-FONTS": {
    description: "Upload Logo Font",
    icon: <FontIcon />,
    taskRoute: "./fontSeries",
    taskStatusUrl: null,
  },
  "UPLOAD-BEHIND-THE-SCENES-IMAGES": {
    description: "Behind Scenes Images",
    icon: <ImageIcon />,
    taskRoute: "./episodicBehindScenes",
    taskStatusUrl: null,
  },
};

export const postProductionTasks: FrontendTaskList = {
  "CLOSE-DOWN-FORM": {
    description: "Close-down Form",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./close-down-form",
    taskStatusUrl: null,
  },
  "OFCOM-FINAL-FORM": {
    description: "Ofcom Form (Final)",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./ofcom-final",
    taskStatusUrl: null,
  },
  "MARKETING-PROMO-CLIP-TIMECODE": {
    description: "Marketing Promo Clip Timecode",
    icon: <TimePlayIcon />,
    taskRoute: "./marketingPromoClip",
    taskStatusUrl: null,
  },
  "PRODUCTION-PRINCIPLES-FINAL-FORM": {
    description: "Production Principles (Final)",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./production-principles-final",
    taskStatusUrl: null,
  },
  "UPDATE-SYNOPSIS": {
    description: "Write Synopsis",
    icon: <CreateOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./synopsis",
    taskStatusUrl: null,
  },
  "UPDATE-NAME": {
    description: "Edit Name",
    icon: <BuildOutlined color="primary" sx={{ fontSize: 40 }} />,
    taskRoute: "./edit-title",
    taskStatusUrl: null,
  },
  "UPLOAD-LOGO": {
    description: "Upload logo",
    icon: <LogoIcon />,
    taskRoute: "./logoSeries",
    taskStatusUrl: null,
  },
  "PREVIEW-TIMECODE": {
    description: "Set Preview Timecode",
    icon: <TimePlayIcon />,
    taskRoute: "./txmaster",
    taskStatusUrl: null,
  },
  "PRE-TITLE-SEQUENCE-TIMECODE": {
    description: "Set Pre-title Sequence",
    icon: <TimePlayIcon />,
    taskRoute: "./preTitleSequence",
    taskStatusUrl: null,
  },
  "UPDATE-BILLINGS": {
    description: "Update Billings",
    icon: <ContentPasteOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./billings",
    taskStatusUrl: null,
  },
  "EPISODE-CLIP-TIMECODE": {
    description: "Episode Clip Timecode",
    icon: <TimePlayIcon />,
    taskRoute: "./episodeClipTimecode",
    taskStatusUrl: null,
  },
  "UPLOAD-IMAGES": {
    description: "Upload Images",
    icon: <ImageIcon />,
    taskRoute: "./images",
    taskStatusUrl: null,
  },
  "UPLOAD-UNIT-STILLS-IMAGES": {
    description: "Upload Images",
    icon: <ImageIcon />,
    taskRoute: "./episodic",
    taskStatusUrl: null,
  },
  "POST-PRODUCTION-PAPERWORK": {
    description: "View Video Assets",
    icon: <LocalMoviesOutlined sx={{ fontSize: 40 }} />,
    taskRoute: "./txmaster",
    taskStatusUrl: null,
  },
  "UPLOAD-SCRIPTS": {
    description: "Upload Transcripts",
    icon: <ScriptIcon />,
    taskRoute: "./scripts",
    taskStatusUrl: null,
  },
};
