/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import {
  Badge,
  Popover,
  IconButton,
  Tooltip,
  List,
  ListSubheader,
  useTheme,
  Typography,
  Box,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { useNotifications } from "../../hooks/queries/useNotifications";
import { usePutNotifications } from "../../hooks/mutations/usePutNotifications";
import NotificationsItemContainer from "./NotificationItemContainer";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

const NotificationsContainer: FC<any> = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { data: notificationData } = useNotifications();

  const { mutate } = usePutNotifications(
    () => queryClient.invalidateQueries("getNotifications"),
    () =>
      enqueueSnackbar("There was error please contact us", {
        variant: "error",
        action: SnackbarDismiss,
      }),
  );

  const findNumberOfUnread = (input: any) =>
    input.reduce(
      (numberOfUnreadNotifications: number, instance: any) =>
        !instance.read
          ? numberOfUnreadNotifications + 1
          : numberOfUnreadNotifications,
      0,
    );

  const convertIncomingDateString = (str: string) => {
    const event = new Date(str);
    const options: any = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return event.toLocaleDateString(undefined, options);
  };

  // don't want to display more than 15 notifications at a time
  const notificationList = notificationData?.notifications.slice(0, 15) || [];
  const notificationNum = findNumberOfUnread(notificationList);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (notificationList) {
      notificationList.forEach((instance) => {
        if (!instance.read)
          mutate({ notificationId: instance.id, payload: { read: true } });
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Badge
        badgeContent={notificationNum}
        color="secondary"
        overlap="circular"
      >
        <Tooltip title="Notifications">
          <IconButton
            aria-label="notifications"
            color="primary"
            data-testid="profile"
            sx={{
              backgroundColor: `${open ? theme.palette.primary.dark : theme.palette.primary}`,
              "&:hover": { backgroundColor: `${theme.palette.primary.dark}` },
            }}
            onClick={(e: any) => handleClick(e)}
          >
            <NotificationsIcon sx={{ color: "white" }} />
          </IconButton>
        </Tooltip>
      </Badge>
      <Popover
        id={id}
        onClick={() => {
          setAnchorEl(null);
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ zIndex: 10004 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: "center",
        }}
      >
        {notificationList.length === 0 ? (
          <Typography sx={{ p: 2 }}>No notifications to show</Typography>
        ) : (
          <List component="div" role="group" sx={{ p: 0, maxHeight: 350 }}>
            {notificationList.map((instance, index) => {
              const keyProp = index + 1;
              let includeDivider = true;
              if (
                index > 0 &&
                convertIncomingDateString(instance.created) ===
                  convertIncomingDateString(notificationList[index - 1].created)
              ) {
                includeDivider = false;
              }
              return (
                <Box
                  key={`${instance.notificationType}_${instance.created}_${keyProp}`}
                >
                  {includeDivider ? (
                    <ListSubheader>
                      {convertIncomingDateString(instance.created)}
                    </ListSubheader>
                  ) : null}
                  <NotificationsItemContainer {...instance} />
                </Box>
              );
            })}
          </List>
        )}
      </Popover>
    </>
  );
};

export default NotificationsContainer;
