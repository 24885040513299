import { FormHelperText, MenuItem, Select } from "@mui/material";
import { FormikHandlers } from "formik";
import { FormFieldLabel } from "../../../componentsV2/FormFieldLabel";
import { UseUpdateRiskArgs } from "../../../hooks";
import { NotificationFormFieldProps } from "../../../types/types";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  isRequired: boolean;
  value: string | undefined;
  options: Array<{ label: string; value: string }>;
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

type DropdownFieldProps = NotificationFormFieldProps<Props>;

export function DropdownField({
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  handleBlur,
  isRequired,
  value,
  options,
  autosaveForm,
}: DropdownFieldProps) {
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;

  return (
    <FormFieldLabel inputId={inputId} label={label} required={isRequired}>
      <Select
        id={inputId}
        name={inputId}
        required={isRequired}
        fullWidth
        value={value || ""}
        onChange={(event) => {
          handleChange(event);
          autosaveForm({
            [fieldInfo.sectionId]: {
              [fieldInfo.fieldName]: event.target.value,
            },
          });
        }}
        onBlur={handleBlur}
        aria-describedby={`${inputId}-error`}
        error={shouldDisplayErrors && Boolean(error)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {shouldDisplayErrors && error ? (
        <FormHelperText id={`${inputId}-error`} error={true}>
          {error}
        </FormHelperText>
      ) : null}
    </FormFieldLabel>
  );
}
