import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FormHeader } from "../../components";
import {
  useMetadataDropdownNew,
  useRiskNew,
  useRiskScoreNew,
  useRiskSummaryNew,
  useSubmitRiskNew,
  useUpdateRiskNew,
  UseUpdateRiskArgsNew,
} from "../../hooks";
import { Form, Formik, useFormikContext } from "formik";
import { getInitialNotificationFormValuesV3 } from "./getInitialNotificationFormValues";
import { enqueueSnackbar } from "notistack";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";
import { RiskContact } from "./RiskContact";
import {
  ProductionNotificationFormV3,
  RiskData,
  RiskScoreId,
} from "../../types/types";
import { getLatestDate } from "./notificationForm.helper";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useGetUserProfile } from "../../hooks/queries/useGetUserProfile";
import { Redirect } from "../../componentsV2/Redirect";
import { basicInfoSchemaV3 } from "./yupSchemas/basicInfoSchemaV3";
import { BasicInfoPageV3 } from "./NotificationFormPages/BasicInfoPageV3";
import { healthAndSafetySchemaV3 } from "./yupSchemas/healthAndSafetySchemaV3";
import { insuranceSchemaV3 } from "./yupSchemas/insuranceSchemaV3";
import { securitySchemaV3 } from "./yupSchemas/securitySchemaV3";
import { HealthAndSafetyPageV3 } from "./NotificationFormPages/HealthAndSafetyPageV3";
import { InsurancePageV3 } from "./NotificationFormPages/InsurancePageV3";
import { SecurityPageV3 } from "./NotificationFormPages/SecurityPageV3";

interface FormStep {
  id: RiskScoreId | "basicInfo";
  title: string;
}

const notificationFormSchema = Yup.object({
  basicInfo: basicInfoSchemaV3,
  healthAndSafety: healthAndSafetySchemaV3,
  insurance: insuranceSchemaV3,
  security: securitySchemaV3,
});

export type NotificationFormSubmitValuesV3 = Yup.InferType<
  typeof notificationFormSchema
>;

const STEPS: Array<FormStep> = [
  { id: "basicInfo", title: "Production" },
  { id: "healthAndSafety", title: "Health & Safety" },
  { id: "insurance", title: "Insurance" },
  { id: "security", title: "Security" },
];

export function NotificationFormV3() {
  const { seriesCcid, titleCcid } = useParams<string>();
  const [isDeclarationOpen, setIsDeclarationOpen] = useState(false);

  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const currentStep = STEPS[currentStepIndex];
  const stepNumber = currentStepIndex + 1;

  const ccid = titleCcid || seriesCcid;

  const { data: dropdownMetadata, isLoading: dropdownMetadataLoading } =
    useMetadataDropdownNew();
  const { data: summary } = useRiskSummaryNew({ ccid, version: "v3" });
  const { data: formData, isLoading: formDataLoading } = useRiskNew({
    ccid,
    version: "v3",
  });
  const { data: riskScore, isLoading: riskScoreLoading } = useRiskScoreNew({
    ccid,
    version: "v3",
  });

  // TODO: remove when releasing the new form to everyone
  const { data: userProfile, isLoading: userProfileLoading } =
    useGetUserProfile();

  const queryClient = useQueryClient();

  const { mutate: updateForm } = useUpdateRiskNew({
    onSuccess: () => {
      queryClient.setQueryData(
        ["risk", ccid],
        (formData: ProductionNotificationFormV3 | undefined) => {
          if (!formData) {
            return undefined as unknown as ProductionNotificationFormV3;
          }
          /* 
          TODO: this is a bit of a hack. 
          when the BE is refactored to add `lastUpdated` to the top-level of the form data, we should do the same here:
            return {
             ...formData,
             lastUpdated: new Date().toISOString(),
           }; 
           `getLatestDate()` will probably also need to be amended,
           or a new function created to get the autosave date for this form
           */
          return {
            ...formData,
            basicInfo: {
              ...formData.basicInfo,
              lastUpdated: new Date().toISOString(),
            },
          } as ProductionNotificationFormV3;
        },
      );
    },
  });

  const autosaveForm = (formData: UseUpdateRiskArgsNew["formData"]) => {
    return updateForm({ riskCcid: ccid as string, version: "v3", formData });
  };

  const { mutate: submitForm, isLoading: isSubmitting } = useSubmitRiskNew({
    onSuccess: () => {
      enqueueSnackbar("Form successfully submitted!", {
        variant: "success",
        action: SnackbarDismiss,
      });
    },
    onError: () => {
      enqueueSnackbar(
        "There was an error submitting the form. Please try again and contact us if this persists",
        {
          variant: "error",
          action: SnackbarDismiss,
        },
      );
    },
  });

  const isLoading =
    dropdownMetadataLoading ||
    formDataLoading ||
    riskScoreLoading ||
    userProfileLoading;

  if (isLoading) {
    return (
      <>
        <FormHeader
          formTitle="New Production Notification Form"
          showFormStatus
          summary={summary}
        />
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  // TODO: remove when releasing the new form to everyone
  if (!userProfile || userProfile.userType !== "ADMIN") {
    return <Redirect to="/" />;
  }

  if (!dropdownMetadata || !formData) {
    return (
      <>
        <FormHeader
          formTitle="New Production Notification Form"
          showFormStatus
          summary={summary}
        />
        <Stack alignItems="center" m={4}>
          <Alert severity="error">
            There was an error retrieving the data for this form. Please contact
            us if this persists
          </Alert>
        </Stack>
      </>
    );
  }

  const renderFormStepContent = () => {
    switch (currentStep.id) {
      case "basicInfo":
        return (
          <BasicInfoPageV3
            dropdownMetadata={dropdownMetadata}
            autosaveForm={autosaveForm}
          />
        );
      case "healthAndSafety":
        return <HealthAndSafetyPageV3 autosaveForm={autosaveForm} />;
        case "insurance":
          return <InsurancePageV3 autosaveForm={autosaveForm} />;
          case "security":
            return <SecurityPageV3 dropdownMetadata={dropdownMetadata} autosaveForm={autosaveForm} />;
      default:
        return null;
    }
  };

  const riskData: RiskData | null =
    currentStep.id === "basicInfo" || !riskScore
      ? null
      : riskScore[currentStep.id] || null;

  const autosaveDate = getLatestDate(formData);

  return (
    <>
      <FormHeader
        formTitle="New Production Notification Form"
        showFormStatus
        summary={summary}
      />

      <Container disableGutters>
        <Formik
          initialValues={getInitialNotificationFormValuesV3(formData)}
          validationSchema={notificationFormSchema}
          validateOnChange={false}
          validateOnMount={true}
          onSubmit={() => {
            setIsDeclarationOpen(true);
          }}
        >
          {({ values }) => (
            <>
              <Form noValidate>
                <Stack alignItems="left" spacing={5} direction="row">
                  <Box sx={{ maxWidth: "100%", p: 1, position: "sticky" }}>
                    <FormSteps
                      currentStepIndex={currentStepIndex}
                      handleStepChange={setCurrentStepIndex}
                      autosaveDate={autosaveDate}
                      isSubmitting={isSubmitting}
                    />
                  </Box>
                  <Box width="100%">
                    <Stack spacing={2}>
                      <Typography variant="h5">
                        {`Section ${stepNumber} of ${STEPS.length}: ${currentStep.title}`}
                      </Typography>

                      {riskData && riskData.assessor ? (
                        <RiskContact
                          riskAssessor={riskData.assessor}
                          riskLevel={riskData.rating}
                          riskNotes={riskData.notes}
                        />
                      ) : null}

                      {currentStepIndex === 0 ? (
                        <>
                          <Typography variant="body1">
                            This form brings all elements of risk together in
                            one form, helping us review, manage, escalate and
                            report on risk across key areas of risk.
                          </Typography>
                          <Typography variant="body1">
                            You should get the form completed as soon as
                            possible - ideally before the start-up meeting - to
                            help us ensure you have the right support to manage
                            potential risks. All the information you provide in
                            the form will help us maintain a log of all current
                            and ongoing production.
                          </Typography>
                          <Typography variant="body1">
                            We are constantly updating and improving this form,
                            so if you have any questions or feedback - please
                            let us know by emailing risk@itv.com.
                          </Typography>
                        </>
                      ) : null}

                      <Typography fontStyle="italic" marginTop={2}>
                        <Typography component="span" color="error">
                          *
                        </Typography>{" "}
                        Indicates a required field
                      </Typography>
                      {renderFormStepContent()}
                    </Stack>
                  </Box>
                </Stack>
              </Form>
              <Dialog open={isDeclarationOpen} maxWidth="xs">
                <DialogTitle>SPOT Declaration of Truth</DialogTitle>
                <DialogContent>
                  <Typography gutterBottom>
                    Please verify the validity of the following statement:
                  </Typography>

                  <Typography
                    textAlign="center"
                    fontWeight="bold"
                    fontStyle="italic"
                  >
                    I declare that the information provided in these forms is
                    true and accurate at this point in time, to the best of my
                    knowledge. I will endeavour to amend theses forms, when
                    additional information or new requirements arise at any time
                    within the production.
                  </Typography>
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={() => setIsDeclarationOpen(false)}
                    variant="outlined"
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={() => {
                      submitForm({
                        riskCcid: ccid as string,
                        formData: values,
                        version: "v3",
                      });
                      setIsDeclarationOpen(false);
                    }}
                    variant="contained"
                  >
                    Verify &amp; Submit Form
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Formik>
      </Container>
    </>
  );
}

function FormSteps({
  currentStepIndex,
  handleStepChange,
  autosaveDate,
  isSubmitting,
}: {
  currentStepIndex: number;
  handleStepChange: (stepIndex: number) => void;
  autosaveDate: string | undefined;
  isSubmitting: boolean;
}) {
  const formik = useFormikContext<NotificationFormSubmitValuesV3>();

  function hasError(step: FormStep): boolean {
    // TODO: remove this if statement when all the sections are added
    if (step.id !== "basicInfo" && step.id !== "healthAndSafety") {
      return false;
    }

    return Boolean(formik.errors[step.id]);
  }

  return (
    <Box position="sticky" top={0} padding={1}>
      <Stack spacing={2}>
        <Stepper
          orientation="vertical"
          nonLinear
          activeStep={currentStepIndex}
          sx={{ minWidth: "270px" }}
        >
          {STEPS.map((step, index) => (
            <Step key={step.id} completed={!hasError(step)}>
              <StepButton onClick={() => handleStepChange(index)}>
                <Typography
                  variant="body1"
                  color={
                    formik.submitCount > 0 && hasError(step)
                      ? "error"
                      : "primary"
                  }
                >
                  {step.title}
                </Typography>
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Submit Form
        </LoadingButton>

        <Stack spacing={1} direction="row">
          <IconButton
            aria-label="Previous step"
            disabled={currentStepIndex === 0}
            color="primary"
            onClick={() => {
              const newStepIndex = Math.max(0, currentStepIndex - 1);
              handleStepChange(newStepIndex);
            }}
          >
            <ArrowBack />
          </IconButton>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={() => {
              const newStepIndex = Math.min(
                STEPS.length - 1,
                currentStepIndex + 1,
              );
              handleStepChange(newStepIndex);
            }}
            disabled={currentStepIndex === STEPS.length - 1}
            sx={{ fontWeight: "light" }}
            endIcon={<ArrowForward />}
          >
            Next Section
          </Button>
        </Stack>

        <Box>
          {autosaveDate && (
            <Typography sx={{ fontSize: "14px", fontWeight: "light" }}>
              Autosaved: {dayjs(autosaveDate).format("DD/MM/YYYY HH:mm a")}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
