import { Link, Typography } from "@mui/material";
import { GROUP_CHECKBOXES, RADIO, TEXTAREA, TEXT } from "../../../components";

export const cyberSecurityFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Cyber Risk</Typography>,
    description:
      "This information will help us make sure we are managing the cyber risks involved in our production activities.",
    key: "Cyber Risk",
    formFields: [
      {
        type: TEXT,
        label:
          "Who is responsible for reporting cyber security incidents or data breaches on this production?",
        helperText:
          "There is a 72 hour window to report breaches to cybersecurity@itv.com. The contact should be the person on the production responsible for reporting this. ",
        id: "cyberResponsible",
        value: undefined,
        placeholder: "Name",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: TEXT,
        id: "cyberResponsibleEmail",
        value: undefined,
        placeholder: "Email",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: RADIO,
        label:
          "Is this a new format or development idea which requires new applications or online services?",
        id: "newTech",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not Yet Known",
            value: "TBC",
          },
        ],
      },
      {
        type: TEXTAREA,
        label:
          "Please list any new processes and/or technology, mobile application and/or online services that will be required as part of this production",
        id: "newTechDetail",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          newTech: "Yes",
        },
      },
      {
        type: GROUP_CHECKBOXES,
        id: "appList",
        label:
          "Please identify any existing and new key technology, mobile application or online internet services which will be used on this production and its purpose. ",
        columnCount: 12,
        vertical: true,
        required: true,
        options: [
          {
            label: "Whatsapp",
            value: "Whatsapp",
          },
          {
            label: "Signal",
            value: "Signal",
          },
          {
            label: "Dropbox",
            value: "Dropbox",
          },
          {
            label: "Google Drive",
            value: "GoogleDrive",
          },
          {
            label: "One Drive",
            value: "OneDrive",
          },
          {
            label: "Box",
            value: "Box",
          },
          {
            label: "WeTransfer",
            value: "WeTransfer",
          },
          {
            label: "ETribez",
            value: "ETribez",
          },
          {
            label: "Zoom",
            value: "Zoom",
          },
          {
            label: "Microsoft Teams",
            value: "MicrosoftTeams",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
      },
      {
        type: TEXT,
        label: "Please list any technology not included in the dropdown above:",
        id: "appListDetail",
        value: undefined,
        placeholder: "Please List",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: GROUP_CHECKBOXES,
        label:
          "Will any existing or new key suppliers or third parties be used to access or store data/content?",
        id: "dataStorage",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        vertical: true,
        options: [
          {
            label: "Adobe Sign",
            value: "AdobeSign",
          },
          {
            label: "Iconik",
            value: "Iconik",
          },
          {
            label: "PIX",
            value: "PIX",
          },
          {
            label: "Prodicle",
            value: "Prodicle",
          },
          {
            label: "Scenechronize",
            value: "Scenechronize",
          },
          {
            label: "Set Keeper",
            value: "SetKeeper",
          },
          {
            label: "Team Engine",
            value: "TeamEngine",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
      },
      {
        type: TEXT,
        label:
          "Please list any existing or new suppliers or third parties who be used to access or store data/content:",
        id: "dataStorageSupplier",
        value: undefined,
        placeholder: "Please List",
        columnCount: 12,
        required: false,
        disabled: false,
      },
      {
        type: RADIO,
        label:
          "Will data or content be edited, transferred or accessed remotely from different locations? For example, remote accessing and editing from home, hotel, airport or other insecure location.",
        id: "dataLink",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not Yet Known",
            value: "TBC",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Please state all parties and countries involved",
        id: "dataLinkDetail",
        value: undefined,
        placeholder: "Please List",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          dataLink: "Yes",
        },
      },
      {
        type: RADIO,
        label:
          "Will data or content be edited, stored or accessed from a different country to which it is stored? ",
        id: "dataAccess",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not Yet Known",
            value: "TBC",
          },
        ],
      },
      {
        type: TEXTAREA,
        label:
          "Where is the data/content being stored, by whom and in which country?",
        id: "dataStorageCountry",
        value: undefined,
        placeholder: "Please List",
        columnCount: 12,
        required: true,
        disabled: false,
        visibleDependencies: {
          dataAccess: "Yes",
        },
      },
      {
        type: RADIO,
        id: "breach",
        label:
          "Is there the potential for financial impact or reputational damage due to a significant breach of any of the following categories? Loss of sensitive data, Loss of intellectual property, Theft, Loss of contributor, employee or freelancer personally identifiable information.",
        columnCount: 12,
        vertical: true,
        required: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "What would be the impact of a breach?",
        id: "breachImpact",
        value: undefined,
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "No impact",
            value: "NoImpact",
          },
          {
            label: "Low impact",
            value: "LowImpact",
          },
          {
            label: "Moderate impact",
            value: "ModerateImpact",
          },
          {
            label: "Significant impact",
            value: "SignificantImpact",
          },
        ],
      },
      {
        type: RADIO,
        id: "ai",
        label:
          "Will you be using generative AI products during any of production stages?",
        columnCount: 12,
        vertical: true,
        required: true,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: TEXT,
        id: "aiProducts",
        label: "What AI products will you be using?",
        columnCount: 12,
        required: false,
        visibleDependencies: {
          ai: ["Yes"],
        },
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
        ],
      },
      {
        type: RADIO,
        id: "aiGuidance",
        label: "Have you read the following guidance on AI?",
        notes: (
          <>
            <Link
              sx={{ my: 0.5 }}
              href="https://my.itv.com/Home/Index/2be716f9-42e7-4723-b840-802e8497ed6c"
              target="_blank"
            >
              How to use AI safely and responsibly at ITV
            </Link>
            <Link
              sx={{ my: 0.5 }}
              href="https://docs.google.com/document/d/1gBF1FwGJxts5oRFRogqr9dAlU1mxmRBwo-X4vkVe6xE/edit"
              target="_blank"
            >
              Exploring Generative AI (GenAI) Opportunities Safely & Responsibly
            </Link>
          </>
        ),
        columnCount: 12,
        required: true,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
        ],
      },
    ],
  },
];
