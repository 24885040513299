import { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Stack,
  CircularProgress,
  Container,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { FormHeader } from "../../components";
import { OfcomFormSteps } from "./OfcomFormSteps";
import { OfcomPage1 } from "./OfcomPage1";
import { OfcomPage2 } from "./OfcomPage2";
import { OfcomFormDataFormik, OfcomFormDataServer } from "../../types/types";
import { transformFormDataToServer } from "./transformFormDataToServer";
import { OfcomFormTitleStep1 } from "./OfcomFormTitleStep1";
import { OfcomFormTitleStep2 } from "./OfcomFormTitleStep2";
import { useGetOfcomData, useOfcomFormSubmission } from "../../hooks";

interface Steps {
  [key: number]: string;
}

interface OfcomPageOneProp {
  provisional: boolean;
}

const stepTitles: Steps = {
  1: "European Compliance & Made in the UK qualification",
  2: "Regional Production Quota",
};

const registrationSchema = Yup.object().shape({
  producerCountry: Yup.string().required("This is a required field"),
  productionLocation: Yup.string().required("This is a required field"),
  foreignProduction: Yup.string().required("This is a required field"),
  substantiveBaseCriteria: Yup.boolean().required("This is a required field"),
  productionSpendCriteria: Yup.boolean().required("This is a required field"),
  talentCriteria: Yup.boolean().required("This is a required field"),
  substantiveBaseAddress: Yup.string().when(
    "$substantiveBaseCriteria",
    ([substantiveBaseCriteria], schema) => {
      if (substantiveBaseCriteria) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  substantiveBaseYear: Yup.number().when(
    "$substantiveBaseCriteria",
    ([substantiveBaseCriteria], schema) => {
      if (substantiveBaseCriteria) {
        return schema
          .typeError("Year must be a number")
          .integer("Year must be an integer")
          .moreThan(1000, "Year must be a 4-digit number")
          .lessThan(9999, "Year must be a 4-digit number")
          .required("This is a required field");
      }
      return schema;
    },
  ),
  substantiveBaseRegion: Yup.string().when(
    "$substantiveBaseCriteria",
    ([substantiveBaseCriteria], schema) => {
      if (substantiveBaseCriteria) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  substantiveBaseOpenPrior: Yup.string().when(
    "$substantiveBaseCriteria",
    ([substantiveBaseCriteria], schema) => {
      if (substantiveBaseCriteria) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  substantiveBaseCommission: Yup.string().when(
    "$substantiveBaseCriteria",
    ([substantiveBaseCriteria], schema) => {
      if (substantiveBaseCriteria) {
        return schema.required("This is a required field");
      }
      return schema;
    },
  ),
  productionSpendTotalBudget: Yup.string().when(
    "$productionSpendCriteria",
    ([productionSpendCriteria], schema) => {
      if (productionSpendCriteria) {
        return schema
          .required("This is a required field")
          .matches(
            /^[0-9]+(\.[0-9]{1,2})?$/,
            "Must be a positive number with up to 2 decimal places",
          );
      }
      return schema;
    },
  ),
  productionSpendTotalQualifyingBudget: Yup.string().when(
    "$productionSpendCriteria",
    ([productionSpendCriteria], schema) => {
      if (productionSpendCriteria) {
        return schema
          .required("This is a required field")
          .matches(
            /^[0-9]+(\.[0-9]{1,2})?$/,
            "Must be a positive number with up to 2 decimal places",
          );
      }
      return schema;
    },
  ),
  productionSpendPercentage: Yup.string().when(
    "$productionSpendCriteria",
    ([productionSpendCriteria], schema) => {
      if (productionSpendCriteria) {
        return schema
          .required("This is a required field")
          .matches(
            /^[0-9]+(\.[0-9]{1,2})?$/,
            "Must be a percentage from 0 to 100",
          );
      }
      return schema;
    },
  ),
  productionSpendRegion: Yup.array().when(
    "$productionSpendCriteria",
    ([productionSpendCriteria], schema) => {
      if (productionSpendCriteria) {
        return schema.min(1, "This is a required field");
      }
      return schema;
    },
  ),
  talentTotalBudget: Yup.string().when(
    "$talentCriteria",
    ([talentCriteria], schema) => {
      if (talentCriteria) {
        return schema
          .required("This is a required field")
          .matches(
            /^[0-9]+(\.[0-9]{1,2})?$/,
            "Must be a positive number with up to 2 decimal places",
          );
      }
      return schema;
    },
  ),
  talentBudgetPercentage: Yup.string().when(
    "$talentCriteria",
    ([talentCriteria], schema) => {
      if (talentCriteria) {
        return schema
          .required("This is a required field")
          .matches(/^(100|[1-9]?[0-9])$/, "Must be a percentage from 0 to 100");
      }
      return schema;
    },
  ),
  talentRegion: Yup.array().when(
    "$talentCriteria",
    ([talentCriteria], schema) => {
      if (talentCriteria) {
        return schema.min(1, "This is a required field");
      }
      return schema;
    },
  ),
});

export const OfcomForm: FC<OfcomPageOneProp> = ({ provisional }) => {
  const { seriesCcid, programmeCcid, titleCcid } = useParams<string>();
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [fetchedOfcomFormStatus, setFetchedOfcomFormStatus] =
    useState<boolean>(false);
  const ccid = titleCcid || seriesCcid;
  const isSeries = Boolean(seriesCcid);

  const navigate = useNavigate();
  const goToPreviousPage = () =>
    navigate(
      isSeries
        ? `/programmes/${programmeCcid}/series/${seriesCcid}`
        : `/special/${titleCcid}`,
    );

  const { data: fetchedOfcomformData, isLoading: loadingOfcomData } =
    useGetOfcomData(ccid as string, provisional);
  const { mutate: submitOfcomForm } = useOfcomFormSubmission(goToPreviousPage);
  const isFormProvisional = provisional;

  const transformOfcomData = (incomingOfcomdata: OfcomFormDataServer) => {
    const booleanToYesNo = (val: boolean | null) => {
      if (val === true) return "Yes";
      if (val === false) return "No";
      return "";
    };

    const now = new Date();

    const transformedData: OfcomFormDataFormik = {
      foreignProduction: booleanToYesNo(incomingOfcomdata?.foreignProduction),
      modifiedTime:
        incomingOfcomdata?.modifiedTime || now.toISOString().slice(0, 19),
      producerCountry: incomingOfcomdata.producerCountry || "",
      productionLocation: incomingOfcomdata.productionLocation || "",
      productionSpendCriteria:
        incomingOfcomdata?.productionSpendCriteria || false,
      productionSpendPercentage:
        incomingOfcomdata.productionSpendPercentage || "",
      productionSpendRegion: incomingOfcomdata.productionSpendRegion || [],
      productionSpendTotalBudget:
        incomingOfcomdata.productionSpendTotalBudget || "",
      productionSpendTotalQualifyingBudget:
        incomingOfcomdata.productionSpendTotalQualifyingBudget || "",
      submitted: incomingOfcomdata.submitted || false,
      submittedBy: incomingOfcomdata.submittedBy || "",
      substantiveBaseAddress: incomingOfcomdata.substantiveBaseAddress || "",
      substantiveBaseCommission: booleanToYesNo(
        incomingOfcomdata.substantiveBaseCommission,
      ),
      substantiveBaseCriteria:
        incomingOfcomdata.substantiveBaseCriteria || false,
      substantiveBaseOpenPrior: booleanToYesNo(
        incomingOfcomdata.substantiveBaseOpenPrior,
      ),
      substantiveBaseRegion: incomingOfcomdata.substantiveBaseRegion || "",
      substantiveBaseYear: incomingOfcomdata.substantiveBaseYear || "",
      talentBudgetPercentage: incomingOfcomdata.talentBudgetPercentage || "",
      talentCriteria: incomingOfcomdata.talentCriteria || false,
      talentRegion: incomingOfcomdata.talentRegion || [],
      talentTotalBudget: incomingOfcomdata.talentTotalBudget || "",
    };

    return transformedData;
  };

  useEffect(() => {
    if (fetchedOfcomformData && fetchedOfcomformData.data.submitted) {
      setFetchedOfcomFormStatus(fetchedOfcomformData.data.submitted);
    }
  }, [fetchedOfcomformData]);

  const onSubmit = (values: OfcomFormDataFormik) => {
    const useCcid: string = ccid as string;
    submitOfcomForm({
      ccid: useCcid,
      formData: transformFormDataToServer(values),
      autosave: false,
      provisional,
    });
  };

  if (loadingOfcomData)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <FormHeader
        formTitle="Ofcom Form"
        showFormStatus
        showFormSubmission
        isFormProvisional={provisional}
        isFormSubmitted={fetchedOfcomFormStatus}
      />
      {loadingOfcomData ? (
        <Stack alignItems="center" m={4}>
          <CircularProgress />
        </Stack>
      ) : (
        <Container fixed disableGutters>
          <Formik
            initialValues={transformOfcomData(
              fetchedOfcomformData ? fetchedOfcomformData.data : {},
            )}
            validationSchema={registrationSchema}
            onSubmit={onSubmit}
          >
            {({ submitForm, isSubmitting, validateForm, setTouched }) =>
              isSubmitting ? (
                <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Form>
                  <Stack alignItems="left" spacing={5} direction="row">
                    <Box sx={{ maxWidth: "100%", p: 1, position: "sticky" }}>
                      <OfcomFormSteps
                        onStepChange={setStepNumber}
                        onSubmitForm={submitForm}
                        onFormValidate={validateForm}
                        setTouched={setTouched}
                        submittedBy={
                          fetchedOfcomformData
                            ? fetchedOfcomformData.data.submittedBy
                            : undefined
                        }
                        modifiedTime={
                          fetchedOfcomformData
                            ? fetchedOfcomformData.data.modifiedTime
                            : undefined
                        }
                      />
                    </Box>
                    <Box>
                      <Stack>
                        <Typography variant="h5">
                          {`Section ${stepNumber} of 2 : ${stepTitles[stepNumber]}`}
                        </Typography>
                        {stepNumber === 1 ? (
                          <OfcomFormTitleStep1 />
                        ) : (
                          <OfcomFormTitleStep2 />
                        )}
                      </Stack>
                      {stepNumber === 1 ? (
                        <OfcomPage1
                          ccid={ccid as string}
                          provisional={isFormProvisional}
                        />
                      ) : (
                        <OfcomPage2
                          ccid={ccid as string}
                          provisional={isFormProvisional}
                        />
                      )}
                    </Box>
                  </Stack>
                </Form>
              )
            }
          </Formik>
        </Container>
      )}
    </>
  );
};
