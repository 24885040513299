import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { FormikHandlers } from "formik";
import { FormFieldLabel } from "../../../componentsV2/FormFieldLabel";
import { UseUpdateRiskArgs } from "../../../hooks";
import { NotificationFormFieldProps } from "../../../types/types";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: (value: Array<string>) => void;
  handleBlur: FormikHandlers["handleBlur"];
  isRequired: boolean;
  value: Array<string> | undefined;
  options: Array<string>;
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

type MultiSelectFieldProps = NotificationFormFieldProps<Props>;

export function MultiSelectField({
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  handleBlur,
  isRequired,
  value,
  options,
  autosaveForm,
}: MultiSelectFieldProps) {
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;

  return (
    <FormFieldLabel inputId={inputId} label={label} required={isRequired}>
      <Autocomplete
        id={inputId}
        multiple={true}
        fullWidth
        value={value || []}
        onChange={(_, selectedValues) => {
          handleChange(selectedValues);
          autosaveForm({
            [fieldInfo.sectionId]: {
              [fieldInfo.fieldName]: selectedValues,
            },
          });
        }}
        onBlur={(event) => {
          handleBlur(event);
        }}
        aria-describedby={`${inputId}-error`}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            name={inputId}
            required={isRequired}
            error={shouldDisplayErrors && Boolean(error)}
            aria-describedby={`${inputId}-error`}
          />
        )}
      />

      {shouldDisplayErrors && error ? (
        <FormHelperText id={`${inputId}-error`} error={true}>
          {error}
        </FormHelperText>
      ) : null}
    </FormFieldLabel>
  );
}
