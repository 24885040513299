import { useQuery, UseQueryResult } from "react-query";
import { TaskStatusPayload } from "../../types/types";
import handleRequest from "../../lib/handleRequest";

export const useTaskStatus = (
  taskStatusUrl: string | null,
): UseQueryResult<TaskStatusPayload> =>
  useQuery(
    ["task status", taskStatusUrl],
    () => handleRequest.get(taskStatusUrl as string).then(({ data }) => data),
    { enabled: Boolean(taskStatusUrl) },
  );
