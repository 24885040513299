import { Typography } from "@mui/material";
import {
  AUTOCOMPLETE_WITH_SINGLE_SELECTION,
  GROUP_CHECKBOXES,
  RADIO,
  TEXTAREA,
} from "../../../components";

export const securityFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Security</Typography>,
    key: "Security",
    description: "",
    formFields: [
      {
        type: RADIO,
        label: "Do you require security provision as part of your production?",
        id: "additionalSecurity",
        questionBubble: "",
        required: true,
        columnCount: 12,
        options: [
          {
            label:
              "Yes, security provision will be required as part of this production",
            value: "Required",
          },
          {
            label:
              "No security provision is required as part of this production",
            value: "NotRequired",
          },
          {
            label:
              "It is not yet known whether security provision will be required as part of this production",
            value: "NotYetKnown",
          },
        ],
      },
      {
        type: GROUP_CHECKBOXES,
        label: "Please select the Security suppliers you will be using:",
        id: "suppliers",
        vertical: true,
        columnCount: 6,
        required: true,
        disabled: false,
        visibleDependencies: {
          additionalSecurity: "Required",
        },
        options: [
          {
            label: "Athena Security Solutions",
            value: "AthenaSecuritySolutions",
          },
          {
            label: "Capricorn Security",
            value: "CapricornSecurity",
          },
          {
            label: "GuardForce Security",
            value: "GuardForceSecurity",
          },
          {
            label: "Location Secure",
            value: "LocationSecure",
          },
          {
            label: "Minimal Risk",
            value: "MinimalRisk",
          },
          {
            label: "Peregrine",
            value: "Peregrine",
          },
          {
            label: "Rock Security Solutions",
            value: "RockSecuritySolutions",
          },
          {
            label: "Safe & Sound Group",
            value: "SafeAndSoundGroup",
          },
          {
            label: "SRM Security",
            value: "SRMSecurity",
          },
          {
            label: "Titan Risk Management",
            value: "TitanRiskManagement",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Please list the other provider/s that you will be using:",
        id: "securityProvider",
        value: undefined,
        placeholder: "Please list the provider/s that you will be using",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          suppliers: ["Other"],
        },
      },
      {
        type: RADIO,
        label:
          "Is there a direct security risk to employees, contributors or contractors based on the locations that they are filming?",
        id: "risk",
        questionBubble: "",
        required: true,
        columnCount: 12,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "TBC",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Please detail the direct security risk:",
        id: "riskDetail",
        value: "",
        placeholder: "Please detail the direct security risk",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          risk: "Yes",
        },
      },
      {
        type: AUTOCOMPLETE_WITH_SINGLE_SELECTION,
        label:
          "Will the production or any person associated with the production be required to travel to any of the following countries?",
        id: "atRiskCountries",
        isMultiple: true,
        value: [],
        placeholder: "enter location here",
        columnCount: 12,
        optionsMapTo: "securityCountries",
        required: true,
        disabled: false,
      },
      // {
      //   type: SELECT,
      //   label: "Will the production or any person associated with the production be required to travel to any of the following countries?",
      //   id: "atRiskCountries",
      //   optionsMapTo: "countries",
      //   value: undefined,
      //   placeholder: "Please select an option",
      //   columnCount: 12,
      //   required: true,
      //   disabled: false,
      //   options: [],
      // },
      {
        type: RADIO,
        label:
          "Will you be arranging international travel for any cast or crew?",
        id: "internationalTravel",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will you be undertaking any covert or secret filming during any part of the production?",
        id: "covert",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "TBC",
          },
        ],
      },
      {
        type: TEXTAREA,
        label:
          "Please describe the covert or secret filming plans and the protocols that will be used.",
        id: "covertSynopsis",
        value: undefined,
        placeholder: "Type answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          covert: "Yes",
        },
      },
      {
        type: RADIO,
        label:
          "Will the content of the production cover any potentially sensitive subject areas which could provoke a significant public response, such as a protest at a production site or harassment (both online or in-person) targeted at ITV personnel?",
        id: "provocation",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "TBC",
          },
        ],
      },
      {
        type: TEXTAREA,
        label: "Please detail",
        id: "provocationDetail",
        value: undefined,
        placeholder: "Type answer here",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          provocation: "Yes",
        },
      },
    ],
  },
];
