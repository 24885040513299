/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ContributorProps,
  ContributorUpdateProps,
} from "../../../../types/types";

export const cleanContributors = (
  contributors: any,
  replaceId = false,
  elsewhereContributors?: any,
): ContributorUpdateProps[] => {
  let fullContributors = contributors;

  if (elsewhereContributors && Array.isArray(elsewhereContributors))
    fullContributors = fullContributors?.concat(elsewhereContributors);

  return (
    fullContributors?.map((contributor: ContributorProps) => {
      const role =
        typeof contributor?.role === "string"
          ? contributor?.role
          : contributor?.role?.value;

      return {
        ...contributor,
        contributorId: replaceId ? undefined : contributor.contributorId,
        role,
        character: role !== "ACTOR" ? null : contributor.character,
        surname:
          contributor.surname?.trim() === "" ? null : contributor.surname,
      };
    }) || []
  );
};

export const roleToDisplayName = (incomingRole: string) => {
  if (!incomingRole) return "";
  const stringValue = incomingRole
    .toLocaleLowerCase()
    .replace(/_+/g, " ")
    .replace("&", " & ");
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
};
