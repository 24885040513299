import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  useBrandMetadata,
  useSeries,
  useSeriesMetadata,
  useTitleMetadata,
  useTitles,
} from "../../hooks/queries";
import Navigation from "../../containers/Synopsis/Edit/Navigation";
import { BrandTitleResponsePayload, Tabs } from "../../types/types";
import StudiosBreadcrumbs from "../StudiosBreadcrumbs/StudiosBreadcrumbs";

const NavMenu: React.FC<{
  programmeCcid: string | undefined;
  seriesCcid: string | undefined;
  titleCcid: string | undefined;
}> = ({ programmeCcid, seriesCcid, titleCcid }) => {
  const navigate = useNavigate();
  const { data: brandMetaData, isLoading: brandMetaDataLoading } =
    useBrandMetadata(programmeCcid, true);
  const { data: seriesMetaData, isLoading: seriesMetaDataLoading } =
    useSeriesMetadata(seriesCcid);
  const { data: titleMetaData, isLoading: titleMetaDataLoading } =
    useTitleMetadata(titleCcid);

  useEffect(() => {
    if (titleCcid && titleMetaData && titleMetaData.status === "OPPORTUNITY") {
      navigate(`/special/${titleCcid}/update-ccid`);
    }
  }, [titleCcid, titleMetaData, navigate]);

  const { data: brandTitlesData } = useTitles(programmeCcid, "brands", true);
  const { data: seriesData } = useSeries(programmeCcid, true);
  const { data: titlesData } = useTitles(seriesCcid, "series", true);
  const isOneOff = Boolean(titleCcid && !programmeCcid && !seriesCcid);

  const [searchParams] = useSearchParams();
  const currentTab: Tabs = (searchParams.get("tab") as Tabs) || "tasks";

  function getProductionLabel(): string {
    const baseSet = new Set();
    if (seriesMetaData) {
      seriesMetaData?.productionLabels.forEach((item) => baseSet.add(item));
    } else {
      titleMetaData?.productionLabels.forEach((item) => baseSet.add(item));
      brandMetaData?.productionLabels.forEach((item) => baseSet.add(item));
    }

    return Array.from(baseSet).join(", ");
  }

  function displayId() {
    if (titleCcid)
      return (
        <Box>
          <Typography variant="subtitle1" display="inline">
            {titleCcid && programmeCcid ? "Episode ID: " : "Programme ID: "}
          </Typography>
          {titleMetaDataLoading ? (
            <Skeleton sx={{ display: "inline-block" }}>
              <Typography variant="subtitle1" display="inline">
                Placeholder ID
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="subtitle1" display="inline">
              {titleMetaData?.episodeId}
            </Typography>
          )}
        </Box>
      );
    if (seriesCcid)
      return (
        <Box>
          <Typography variant="subtitle1" display="inline">
            Series ID:{" "}
          </Typography>
          {seriesMetaDataLoading ? (
            <Skeleton sx={{ display: "inline-block" }}>
              <Typography variant="subtitle1" display="inline">
                Placeholder ID
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="subtitle1" display="inline">
              {seriesCcid === "0" ? "N/A" : seriesMetaData?.seriesId}
            </Typography>
          )}
        </Box>
      );
    if (programmeCcid)
      return (
        <Box>
          <Typography variant="subtitle1" display="inline">
            Programme ID:{" "}
          </Typography>
          {brandMetaDataLoading ? (
            <Skeleton sx={{ display: "inline-block" }}>
              <Typography variant="subtitle1" display="inline">
                Placeholder ID
              </Typography>
            </Skeleton>
          ) : (
            <Typography variant="subtitle1" display="inline">
              {brandMetaData?.programmeId}
            </Typography>
          )}
        </Box>
      );
    return null;
  }

  function displayProductionYears() {
    const earliestYearBrand = brandMetaData?.productionYears?.earliest;
    const latestYearBrand = brandMetaData?.productionYears?.latest;
    const earliestYearSeries = seriesMetaData?.productionYearSpan?.earliest;
    const latestYearSeries = seriesMetaData?.productionYearSpan?.latest;

    if (titleCcid)
      return titleMetaDataLoading ? (
        <Skeleton sx={{ display: "inline-block" }}>
          <Typography variant="subtitle1" display="inline">
            Year
          </Typography>
        </Skeleton>
      ) : (
        <Typography variant="subtitle1" display="inline">
          {titleMetaData?.productionYear ||
            earliestYearSeries ||
            latestYearSeries ||
            "N/A"}
        </Typography>
      );
    if (seriesCcid)
      return seriesMetaDataLoading ? (
        <Skeleton sx={{ display: "inline-block" }}>
          <Typography variant="subtitle1" display="inline">
            Placeholder ID
          </Typography>
        </Skeleton>
      ) : (
        <Typography variant="subtitle1" display="inline">
          {earliestYearSeries &&
          latestYearSeries &&
          earliestYearSeries !== latestYearSeries
            ? `${earliestYearSeries} - ${latestYearSeries}`
            : earliestYearSeries || latestYearSeries || "N/A"}
        </Typography>
      );
    if (programmeCcid)
      return brandMetaDataLoading ? (
        <Skeleton sx={{ display: "inline-block" }}>
          <Typography variant="subtitle1" display="inline">
            Placeholder ID
          </Typography>
        </Skeleton>
      ) : (
        <Typography variant="subtitle1" display="inline">
          {earliestYearBrand &&
          latestYearBrand &&
          earliestYearBrand !== latestYearBrand
            ? `${earliestYearBrand} - ${latestYearBrand}`
            : earliestYearBrand || latestYearBrand || "N/A"}
        </Typography>
      );
    return null;
  }

  return (
    <Paper sx={{ py: 2 }}>
      <Container>
        <StudiosBreadcrumbs />
        {isOneOff ? (
          <Typography variant="h4" sx={{ py: 1 }}>
            {titleMetaDataLoading ? (
              <Skeleton width={250} />
            ) : (
              titleMetaData?.titleName
            )}
          </Typography>
        ) : (
          <Typography variant="h4" sx={{ py: 1 }}>
            {brandMetaDataLoading ? (
              <Skeleton width={250} />
            ) : (
              brandMetaData?.brandName
            )}
          </Typography>
        )}
        <Stack direction="row" spacing={6}>
          {isOneOff ? null : (
            <Box>
              <Typography variant="subtitle1" display="inline">
                Production Label:{" "}
              </Typography>
              {brandMetaDataLoading ||
              seriesMetaDataLoading ||
              titleMetaDataLoading ? (
                <Skeleton sx={{ display: "inline-block" }}>
                  <Typography variant="subtitle1" display="inline">
                    Placeholder Label
                  </Typography>
                </Skeleton>
              ) : (
                <Typography variant="subtitle1" display="inline">
                  {getProductionLabel()}
                </Typography>
              )}
            </Box>
          )}
          <Box>
            <Typography variant="subtitle1" display="inline">
              Production year(s):{" "}
            </Typography>
            {displayProductionYears()}
          </Box>
          <Box>{displayId()}</Box>
        </Stack>
        <Box sx={{ mt: 2 }}>
          {isOneOff ? null : (
            <Navigation
              brandTitlesData={brandTitlesData as BrandTitleResponsePayload}
              titlesData={titlesData}
              data={seriesData}
              currentTab={currentTab}
            />
          )}
        </Box>
      </Container>
    </Paper>
  );
};

export default NavMenu;
