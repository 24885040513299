import { Typography, Stack, Card, CardContent } from "@mui/material";
import { useFormikContext } from "formik";
import { type NotificationFormSubmitValues } from "../NotificationFormNew";
import { RadioField } from "../formFields/RadioField";
import { UseUpdateRiskArgs } from "../../../hooks";

const radioOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "Not yet known",
  },
];

interface DataPrivacyPageProps {
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

export function DataPrivacyPage({ autosaveForm }: DataPrivacyPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValues>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.dataPrivacy || {};
  const errors = formik.errors.dataPrivacy || {};

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Data Privacy</Typography>

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "dataPrivacy", fieldName: "novelData" }}
                label="Will the production methods and techniques involve new or innovative technology, or any other novel approach?"
                error={errors.novelData}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.novelData}
                options={radioOptions}
                tooltipContent="For example: Virtual production, AI software"
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dataPrivacy",
                  fieldName: "covertActivities",
                }}
                label="Will you carry out covert or secret filming?"
                error={errors.covertActivities}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.covertActivities}
                options={radioOptions}
                tooltipContent="For example: Filming someone without them knowing. Covert filming. Secret background research into somebody without them knowing about it. Collecting information about someone’s health or criminal record. Tracking their location or behaviour without their knowledge."
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dataPrivacy",
                  fieldName: "subjectsAware",
                }}
                label="Do the subjects know you are making a programme about them?"
                error={errors.subjectsAware}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.subjectsAware}
                options={radioOptions}
                tooltipContent="For example: Did you try to contact them and could not get permission? Are you planning on surprising them (e.g. prize or prank) or otherwise deceiving or misleading them about the production and their involvement in what the programme is about?"
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dataPrivacy",
                  fieldName: "riskOfHarm",
                }}
                label="Is there a potential risk to life or other harm?"
                error={errors.riskOfHarm}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.riskOfHarm}
                options={radioOptions}
                tooltipContent="For example: An endurance/survival show. Participants being exposed to difficult, risky, stressful or other compromising conditions. Living people being portrayed in a scripted drama in a way which puts them at risk in real life."
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dataPrivacy",
                  fieldName: "journalisticAims",
                }}
                label="Is the production journalistic in nature? Do you have journalistic aims?"
                error={errors.journalisticAims}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.journalisticAims}
                options={radioOptions}
                tooltipContent="For example: Does the programme involve current affairs content, expose material or catch someone out in a lie or deception? Do you need to do research without alerting the person?"
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dataPrivacy",
                  fieldName: "personalRelationships",
                }}
                label="Does the programme feature footage or information about a real person’s sex life, sexual orientation or love life?"
                error={errors.personalRelationships}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.personalRelationships}
                options={radioOptions}
                tooltipContent="For example: Dating shows or scripted dramas which portray real-life relationships."
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dataPrivacy",
                  fieldName: "sensitiveMaterial",
                }}
                label="Does the programme feature other sensitive material or content?"
                error={errors.sensitiveMaterial}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.sensitiveMaterial}
                options={radioOptions}
                tooltipContent="For example: Is the programme based on real-life events which were notorious,scandalous, tragic, painful, difficult, controversial or horrific or which focus on very private or sensitive information."
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "dataPrivacy",
                  fieldName: "childrenFeatured",
                }}
                label="Does the programme feature children or vulnerable individuals?"
                error={errors.childrenFeatured}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.childrenFeatured}
                options={radioOptions}
                tooltipContent="For example - non-scripted programmes about real-life or physical or mental health, filming someone when ill or being medically treated, distressed or unable to understand they are being filmed/recorded."
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
