/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getRiskScore, getRiskScoreNew } from "../../api/spotlight";
import { RiskScore } from "../../types/types";

export const useRiskScore = (
  riskId: string,
  type = "series",
): UseQueryResult<RiskScore, AxiosError> =>
  useQuery(["risk score", riskId], () => getRiskScore(riskId as string, type), {
    enabled: Boolean(riskId),
  });

export const useRiskScoreNew = ({
  ccid,
  version,
}: {
  ccid: string | undefined;
  version: "v2" | "v3";
}): UseQueryResult<RiskScore, AxiosError> =>
  useQuery(
    ["risk score", ccid],
    () => getRiskScoreNew({ ccid: ccid as string, version }),
    {
      enabled: Boolean(ccid),
    },
  );
