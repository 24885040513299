/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useCallback, useState, useRef, useEffect } from "react";
import { Autocomplete, TextField, Stack, Button } from "@mui/material";
import { User } from "../../../../types/types";
import { ListUsers } from "../ListUsers";
import { useSearchLabelUsers } from "../../../../hooks";

const delayTime = 300;

interface AddNewCollaboratorProps {
  onAdd(users: User[]): void;
  onCancel(): void;
}

export const AddNewCollaborator: FC<AddNewCollaboratorProps> = ({
  onAdd,
  onCancel,
}) => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearchResult, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );

  useEffect(() => {
    if (usersFromSearchResult) {
      setUsers(usersFromSearchResult);
    }
  }, [usersFromSearchResult]);

  const timeout = useRef<any>(null);

  const inputChangeHandler = useCallback((e: any) => {
    const { value } = e.target;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      if (!value || value.length < 2) return;
      setSearchTerm(value);
    }, delayTime);
  }, []);

  const selectHandler = useCallback(
    (event: any, value: { id: string } | null) => {
      const newUser = users.find((user) => user.id === value?.id);

      if (newUser) {
        setSelectedUsers([...selectedUsers, newUser]);
      }
    },
    [selectedUsers, users],
  );

  return (
    <Stack sx={{ minWidth: "444px", marginTop: "1rem", p: 2 }} spacing={2}>
      <Autocomplete
        id="collaborators"
        options={users.map((user) => ({
          label: `${user.firstName.trim()} ${user.lastName.trim()} ${user.role ? `(${user.role})` : ""}`,
          id: user.id,
        }))}
        onInputChange={inputChangeHandler}
        onChange={selectHandler}
        loading={isLoading}
        renderInput={(params) => (
          <TextField {...params} label="Search for a user" />
        )}
      />
      <ListUsers
        selectedUsers={selectedUsers}
        onRemove={(user: User) => {
          setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id));
        }}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          color="primary"
          disabled={selectedUsers?.length <= 0}
          onClick={() => {
            onAdd(selectedUsers);
          }}
        >
          Add
        </Button>
        <Button color="primary" onClick={onCancel}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
