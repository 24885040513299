import { Close } from "@mui/icons-material";
import {
  Card,
  CardContent,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@mui/material";
import { FC } from "react";
import { User } from "../../../../types/types";

interface ListUsersProps {
  selectedUsers: User[];
  onRemove(user: User): void;
}

export const ListUsers: FC<ListUsersProps> = ({ selectedUsers, onRemove }) => (
  <List>
    {selectedUsers.map((user) => (
      <Card key={user.id} sx={{ marginBottom: "1rem" }}>
        <CardContent
          sx={{
            padding: 0,
            paddingBottom: "0 !important",
          }}
        >
          <ListItem
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => {
                  onRemove(user);
                }}
              >
                <Close />
              </IconButton>
            }
          >
            <ListItemText
              primary={`${user.firstName} ${user.lastName}`}
              secondary={user.email}
            />
          </ListItem>
        </CardContent>
      </Card>
    ))}
  </List>
);
