import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormikHandlers } from "formik";
import theme from "../../../utils/theme";
import { NotificationFormFieldProps } from "../../../types/types";
import { UseUpdateRiskArgs } from "../../../hooks";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: (isYes: boolean) => void;
  handleBlur: FormikHandlers["handleBlur"];
  isRequired: boolean;
  value: boolean | undefined;
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

type YesNoFieldProps = NotificationFormFieldProps<Props>;

export function YesNoField({
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  handleBlur,
  isRequired,
  value,
  autosaveForm,
}: YesNoFieldProps) {
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;
  return (
    <FormControl required={isRequired}>
      <FormLabel
        sx={{
          marginBottom: "16px",
          "& .MuiFormLabel-asterisk": {
            color: theme.palette.error.main,
          },
        }}
        id={`${inputId}-label`}
      >
        {label}
      </FormLabel>

      <RadioGroup
        id={inputId}
        name={inputId}
        value={value ?? ""}
        aria-labelledby={`${inputId}-label`}
        aria-describedby={`${inputId}-error`}
        sx={{ width: "80%", paddingLeft: "24px" }}
      >
        <FormControlLabel
          label="Yes"
          value={true}
          sx={{ marginBottom: "8px" }}
          control={
            <Radio
              onChange={() => {
                handleChange(true);

                autosaveForm({
                  [fieldInfo.sectionId]: {
                    [fieldInfo.fieldName]: true,
                  },
                });
              }}
              onBlur={handleBlur}
            />
          }
        />

        <FormControlLabel
          label="No"
          value={false}
          sx={{ marginBottom: "8px" }}
          control={
            <Radio
              onChange={() => {
                handleChange(false);

                autosaveForm({
                  [fieldInfo.sectionId]: {
                    [fieldInfo.fieldName]: false,
                  },
                });
              }}
              onBlur={handleBlur}
            />
          }
        />
      </RadioGroup>

      {shouldDisplayErrors && error ? (
        <FormHelperText id={`${inputId}-error`} error={true} sx={{ margin: 0 }}>
          {error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
