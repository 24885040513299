/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FC, useState } from "react";
import {
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Dialog,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useMutation, useQueryClient } from "react-query";
import { formatBytes } from "react-dropzone-uploader";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  downloadImage,
  getBlob,
  ImageEntitlementsResponse,
  ImageState,
  ListImageSchema,
  patchDeleteDeliverableState,
} from "../../../../../api/images";
import Thumbnail from "../Thumbnail/Thumbnail";
import { SeriesDetails, TitleDetailsProps } from "../../../../../types/types";
import { ModalWrapper } from "../../../../../components";
import { ModalService } from "../../../../../services";
import theme from "../../../../../utils/theme";

interface UploadedImagesProps {
  deliverables: ListImageSchema[];

  handleCheckboxChange: (imgId?: string, isAllSelected?: boolean) => void;
  ccid: string;
  level: string;
  entitlements: ImageEntitlementsResponse | undefined;
  seriesDetails?: SeriesDetails;
  titleDetails?: TitleDetailsProps;
  brandName?: string;
  disableView?: boolean;
  type: string;
}

const UploadedImages: FC<UploadedImagesProps> = ({
  deliverables,
  handleCheckboxChange,
  ccid,
  level,
  entitlements,
  seriesDetails,
  titleDetails,
  brandName,
  disableView = false,
  type = "images",
}) => {
  let idType = "imageId";
  if (type === "scripts") idType = "scriptId";
  if (type === "fonts") idType = "fontId";
  const [isHovered, setIsHovered] = useState<string>("");
  const [isSelected, setIsSelected] = useState<string>("");

  const [isUploadedModalOpen, setIsUploadedModalOpen] =
    useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState<boolean>(false);
  const [snackBarErrorMessage, setSnackBarErrorMessage] = useState<
    string | undefined
  >(undefined);
  const queryClient = useQueryClient();
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const deleteImage = useMutation(
    (checkedImages: ListImageSchema[]) =>
      Promise.all(
        checkedImages.map((image: any) =>
          patchDeleteDeliverableState(
            level,
            ccid,
            image[idType],
            ImageState.DELETED,
            undefined,
            type,
          ),
        ),
      ).catch(() => null),

    {
      onSuccess: () => {
        queryClient.invalidateQueries("uploadedImages");
      },
    },
  );

  const onImageLoaded = (imgId: string) => {
    if (loadedImages.includes(imgId)) {
      return;
    }
    setLoadedImages((prevState) => [...prevState, imgId]);
  };

  const handleDeleteConfirmation = () =>
    deleteImage.mutate(deliverables.filter((img) => img.isChecked));

  const isAllFileSelected =
    deliverables.filter((img) => img.isChecked).length === deliverables.length;

  const handleDownloadImages = async () => {
    const zip = new JSZip();
    const folder = zip.folder(type);
    setShowLoadingSpinner(true);

    const zippedFiles = await Promise.allSettled(
      deliverables
        .filter((img) => img.isChecked)
        .map(async (img: any) => {
          let blobData: Blob;
          try {
            const dowloadedData = await downloadImage(
              img[idType],
              level,
              ccid,
              type,
            );
            blobData = await getBlob(dowloadedData);
          } catch (e) {
            throw new Error();
          }
          return folder?.file(img.uploadFilename, blobData);
        }),
    ).finally(() => setShowLoadingSpinner(false));

    let numberOfErrors = 0;
    zippedFiles.forEach((file, index) => {
      if (file.status === "rejected") {
        numberOfErrors += 1;
        if (!snackBarErrorMessage) {
          setSnackBarErrorMessage("Error downloading one or more file(s).");
        }
      }
      if (index === zippedFiles.length - 1) {
        if (numberOfErrors < zippedFiles.length) {
          folder?.generateAsync({ type: "blob" }).then((blobV2) => {
            saveAs(blobV2, `${type}.zip`);
          });
        } else {
          setSnackBarErrorMessage("Error during download.");
        }
      }
    });
  };

  const canDoWriteThings =
    entitlements?.canUpload ||
    entitlements?.canDelete ||
    entitlements?.canView ||
    entitlements?.canDownload;

  const hasEntitlements = { canDoWriteThings };

  return (
    <div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginRight: "10px",
          }}
        >
          {canDoWriteThings && (
            <FormControlLabel
              control={
                <Checkbox
                  id="embargo-images-select-all-checkbox"
                  checked={isAllFileSelected}
                  onChange={() =>
                    handleCheckboxChange(undefined, isAllFileSelected)
                  }
                />
              }
              label="Select All"
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "373px",
            justifyContent: "space-between",
          }}
        >
          <LoadingButton
            loading={showLoadingSpinner}
            loadingIndicator={
              showLoadingSpinner ? <div>Downloading...</div> : ""
            }
            variant="contained"
            color="primary"
            onClick={() => handleDownloadImages()}
            disabled={
              !entitlements?.canDownload ||
              deliverables.filter((img) => img.isChecked).length < 1
            }
          >
            Download Selected
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              ModalService.getInstance()
                .setTitle(`Delete selected ${type}?`)
                .setComponent(
                  <div>Are you sure you want to delete these {type}?</div>,
                )
                .setCentered(true)
                .setConfirmCallback(handleDeleteConfirmation)
                .setClickOutsideToClose(true)
                .setShowFooter(true)
                .setConfirmationLabel("Delete")
                .showModal();
            }}
            disabled={
              !entitlements?.canDelete ||
              deliverables.filter((img) => img.isChecked).length < 1
            }
          >
            <DeleteIcon />
            Delete Selected
          </LoadingButton>
        </div>
      </div>

      <Stack flexDirection="row" flexWrap="wrap" columnGap={2}>
        {deliverables.map((img: any) => {
          let date;
          if (img.embargoDateTime) {
            date = new Date(img.embargoDateTime).toLocaleDateString();
          }
          return (
            <Stack
              direction="row"
              spacing={2}
              sx={{
                display: "flex",
                position: "relative",
                flexDirection: "column",
                width: "276px",
                height: "inherit",
                marginBottom: "24",
                marginRight: "12",
                ":hover": {
                  cursor: hasEntitlements ? "pointer" : "initial",
                },
              }}
              key={img[idType]}
              onMouseEnter={() => {
                setIsHovered(img[idType]);
              }}
              onMouseLeave={() => {
                setIsHovered("");
              }}
              onClick={() => {
                if (!disableView) {
                  setIsUploadedModalOpen(true);
                }

                setIsSelected(img[idType]);
              }}
              onContextMenu={(event) =>
                !canDoWriteThings && event.preventDefault()
              }
            >
              <Thumbnail
                fileUploadId={img[idType]}
                filename={img.uploadFilename}
                size={{
                  width: "285px",
                  height: "180px",
                }}
                loadingImg={() => onImageLoaded(img[idType])}
                contentType={img.contentType}
                isUploaded
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "8px",
                  fontSize: "13px",
                }}
              >
                <p
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    margin: "0 5px 0 0",
                    fontWeight: "bold",
                  }}
                  className="bytes"
                >
                  {img.uploadFilename}
                </p>
                <p
                  style={{
                    color: `595B63`,
                    margin: "0",
                  }}
                >
                  {formatBytes(img.size)}
                </p>
              </div>
              {date && (
                <div
                  style={{
                    backgroundColor: `#FFCC26`,
                    fontSize: `0.833em`,
                    height: "12%",
                    marginTop: "12px",
                    right: "4%",
                    borderRadius: "4px",
                    padding: "2px 5px 0",
                    position: "absolute",
                  }}
                >
                  <p style={{ margin: 0 }}>{date}</p>
                </div>
              )}
              {canDoWriteThings && loadedImages.includes(img[idType]) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.hummingBird,
                    position: loadedImages ? "absolute" : "relative",
                    height: loadedImages ? "180px" : "150px",
                    width: loadedImages ? "19%" : "48px",
                    marginTop: loadedImages ? "0" : "-154px",
                    right: loadedImages ? "81%" : "0",
                    float: "left",
                    borderRadius: "6px",
                    visibility:
                      isHovered || img.isChecked || isAllFileSelected
                        ? "visible"
                        : "hidden",
                    opacity:
                      isHovered || img.isChecked || isAllFileSelected
                        ? "0.7"
                        : "0",
                    transition:
                      "opacity 0.25s ease-in-out, visibility 0.25s ease-out",
                  }}
                >
                  <Checkbox
                    id="embargo-image-checkbox"
                    checked={Boolean(img.isChecked)}
                    onClick={(event: any) => {
                      event.stopPropagation();
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      event.stopPropagation();
                      handleCheckboxChange(img[idType]);
                    }}
                  />
                </Box>
              )}
            </Stack>
          );
        })}
        {isUploadedModalOpen && canDoWriteThings && (
          <Dialog
            open={isUploadedModalOpen}
            onClose={() => setIsUploadedModalOpen(false)}
            maxWidth="lg"
            sx={{}}
          >
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              height={80}
              paddingTop={2}
              paddingX={4}
            >
              <Typography
                display="flex"
                flexDirection="row"
                marginTop={2}
                marginLeft={1}
              >
                {brandName}
                {seriesDetails && (
                  <>
                    <KeyboardArrowRightIcon sx={{ position: "relative" }} />
                    Series {seriesDetails.seriesNumber}
                  </>
                )}
                {titleDetails && (
                  <>
                    <KeyboardArrowRightIcon sx={{ position: "relative" }} />
                    Episode {titleDetails.episodeNumber} - {titleDetails.name}
                  </>
                )}
              </Typography>

              <CloseIcon
                sx={{ marginTop: 2 }}
                onClick={() => setIsUploadedModalOpen(false)}
              />
            </Stack>
            {deliverables.map((img: any) => {
              let date;
              if (img.embargoDateTime) {
                date = new Date(img.embargoDateTime).toLocaleDateString();
              }
              return (
                img[idType] === isSelected && (
                  <>
                    <Box>
                      <Thumbnail
                        key={img[idType]}
                        fileUploadId={img[idType]}
                        filename={img.uploadFilename}
                        size={{
                          width: "auto",
                          height: "500px",
                        }}
                        loadingImg={() => onImageLoaded(img[idType])}
                        contentType={img.contentType}
                        style={{ maxWidth: "inherit" }}
                        isUploaded
                      />
                    </Box>
                    <Stack padding={4} maxHeight={132} width={900}>
                      {date && (
                        <>
                          <Stack flexDirection="row">
                            <Typography variant="h5">Embargo date:</Typography>
                            &nbsp; &nbsp;
                            <Box bgcolor="#FFCC26" borderRadius={2} padding={1}>
                              {date}
                            </Box>
                          </Stack>
                          <Typography>
                            This image is under embargo until the date shown,
                            please be aware that this image should not be
                            released to the public until after this date.
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </>
                )
              );
            })}
          </Dialog>
        )}
        <ModalWrapper />
      </Stack>
      {snackBarErrorMessage && (
        <Snackbar
          open={!!snackBarErrorMessage}
          autoHideDuration={6000}
          onClose={() => setSnackBarErrorMessage(undefined)}
        >
          <Alert
            onClose={() => setSnackBarErrorMessage(undefined)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackBarErrorMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default UploadedImages;
