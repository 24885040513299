import {
  Typography,
  Stack,
  Card,
  CardContent,
  InputAdornment,
  Link,
} from "@mui/material";
import { useFormikContext } from "formik";
import { type CyberSecuritySection } from "../yupSchemas/cyberSecuritySchema";
import { type NotificationFormSubmitValues } from "../NotificationFormNew";
import { RadioField } from "../formFields/RadioField";
import { SingleLineTextField } from "../formFields/SingleLineTextField";
import { MailOutline, PersonOutline } from "@mui/icons-material";
import { EmailField } from "../formFields/EmailField";
import { MultiLineTextField } from "../formFields/MultiLineTextField";
import { CheckboxField } from "../formFields/CheckboxField";
import { UseUpdateRiskArgs } from "../../../hooks";

const newTechOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not Yet Known",
    value: "TBC",
  },
];

const appListOptions = [
  {
    label: "Whatsapp",
    value: "Whatsapp",
  },
  {
    label: "Signal",
    value: "Signal",
  },
  {
    label: "Dropbox",
    value: "Dropbox",
  },
  {
    label: "Google Drive",
    value: "GoogleDrive",
  },
  {
    label: "One Drive",
    value: "OneDrive",
  },
  {
    label: "Box",
    value: "Box",
  },
  {
    label: "WeTransfer",
    value: "WeTransfer",
  },
  {
    label: "ETribez",
    value: "ETribez",
  },
  {
    label: "Zoom",
    value: "Zoom",
  },
  {
    label: "Microsoft Teams",
    value: "MicrosoftTeams",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const dataStorageOptions = [
  {
    label: "Adobe Sign",
    value: "AdobeSign",
  },
  {
    label: "Iconik",
    value: "Iconik",
  },
  {
    label: "PIX",
    value: "PIX",
  },
  {
    label: "Prodicle",
    value: "Prodicle",
  },
  {
    label: "Scenechronize",
    value: "Scenechronize",
  },
  {
    label: "Set Keeper",
    value: "SetKeeper",
  },
  {
    label: "Team Engine",
    value: "TeamEngine",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const dataLinkOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not Yet Known",
    value: "TBC",
  },
];

const dataAccessOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not Yet Known",
    value: "TBC",
  },
];

const breachOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "Not yet known",
  },
];

const breachImpactOptions = [
  {
    label: "No impact",
    value: "NoImpact",
  },
  {
    label: "Low impact",
    value: "LowImpact",
  },
  {
    label: "Moderate impact",
    value: "ModerateImpact",
  },
  {
    label: "Significant impact",
    value: "SignificantImpact",
  },
];

const aiOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label: "Not yet known",
    value: "Not yet known",
  },
];

const aiGuidanceOptions = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

interface CyberSecurityPageProps {
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

export function CyberSecurityPage({ autosaveForm }: CyberSecurityPageProps) {
  const formik = useFormikContext<NotificationFormSubmitValues>();

  const shouldDisplayErrors = formik.submitCount > 0;

  const values = formik.values.cyberSecurity || {};
  const errors = formik.errors.cyberSecurity || {};

  const checkboxesChangeHandler = (fieldName: keyof CyberSecuritySection) => {
    return function handleCheckboxesChange(values: Array<string>) {
      formik.getFieldHelpers(`cyberSecurity.${fieldName}`).setValue(values);
    };
  };

  return (
    <>
      <Stack direction="column" spacing={3} py={1}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h5">Cyber Risk</Typography>

              <Typography variant="body1">
                This information will help us make sure we are managing the
                cyber risks involved in our production activities.
              </Typography>

              <SingleLineTextField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "cyberResponsible",
                }}
                label="Who is responsible for reporting cyber security incidents or data breaches on this production?"
                error={errors.cyberResponsible}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.cyberResponsible}
                placeholder="Name"
                startAdornment={
                  <InputAdornment position="start">
                    <PersonOutline />
                  </InputAdornment>
                }
                helperText="There is a 72 hour window to report breaches to cybersecurity@itv.com. The contact should be the person on the production responsible for reporting this."
                autosaveForm={autosaveForm}
              />

              <EmailField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "cyberResponsibleEmail",
                }}
                label="What is the email of the person responsible for cyber security on this production?"
                error={errors.cyberResponsibleEmail}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.cyberResponsibleEmail}
                placeholder="example@itv.com"
                startAdornment={
                  <InputAdornment position="start">
                    <MailOutline />
                  </InputAdornment>
                }
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "cyberSecurity", fieldName: "newTech" }}
                label="Is this a new format or development idea which requires new applications or online services?"
                error={errors.newTech}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.newTech}
                options={newTechOptions}
                autosaveForm={autosaveForm}
              />

              {values.newTech === "Yes" ? (
                <MultiLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "newTechDetail",
                  }}
                  label="Please list any new processes and/or technology, mobile application and/or online services that will be required as part of this production"
                  error={errors.newTechDetail}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.newTechDetail}
                  placeholder="Type answer here"
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <CheckboxField
                version="v2"
                fieldInfo={{ sectionId: "cyberSecurity", fieldName: "appList" }}
                label="Please identify any existing and new key technology, mobile application or online internet services which will be used on this production and its purpose."
                error={
                  Array.isArray(errors.appList)
                    ? errors.appList[0]
                    : errors.appList
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("appList")}
                isRequired={true}
                value={values.appList}
                options={appListOptions}
                autosaveForm={autosaveForm}
              />

              <SingleLineTextField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "appListDetail",
                }}
                label="Please list any technology not included in the options above:"
                error={errors.appListDetail}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.appListDetail}
                placeholder="Please list"
                autosaveForm={autosaveForm}
              />

              <CheckboxField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "dataStorage",
                }}
                label="Will any existing or new key suppliers or third parties be used to access or store data/content?"
                error={
                  Array.isArray(errors.dataStorage)
                    ? errors.dataStorage[0]
                    : errors.dataStorage
                }
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={checkboxesChangeHandler("dataStorage")}
                isRequired={true}
                value={values.dataStorage}
                options={dataStorageOptions}
                autosaveForm={autosaveForm}
              />

              <SingleLineTextField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "dataStorageSupplier",
                }}
                label="Please list any existing or new suppliers or third parties who will be used to access or store data/content:"
                error={errors.dataStorageSupplier}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.dataStorageSupplier}
                placeholder="Please list"
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "dataLink",
                }}
                label="Will data or content be edited, transferred or accessed remotely from different locations? For example, remote accessing and editing from home, hotel, airport or other insecure location."
                error={errors.dataLink}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.dataLink}
                options={dataLinkOptions}
                autosaveForm={autosaveForm}
              />

              {values.dataLink === "Yes" ? (
                <MultiLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "dataLinkDetail",
                  }}
                  label="Please state all parties and countries involved"
                  error={errors.dataLinkDetail}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.dataLinkDetail}
                  placeholder="Please list"
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "dataAccess",
                }}
                label="Will data or content be edited, stored or accessed from a different country to which it is stored?"
                error={errors.dataAccess}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.dataAccess}
                options={dataAccessOptions}
                autosaveForm={autosaveForm}
              />

              {values.dataAccess === "Yes" ? (
                <MultiLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "dataStorageCountry",
                  }}
                  label="Where is the data/content being stored, by whom and in which country?"
                  error={errors.dataStorageCountry}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={true}
                  value={values.dataStorageCountry}
                  placeholder="Please list"
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "cyberSecurity", fieldName: "breach" }}
                label="Is there the potential for financial impact or reputational damage due to a significant breach of any of the following categories? Loss of sensitive data, Loss of intellectual property, Theft, Loss of contributor, employee or freelancer personally identifiable information."
                error={errors.breach}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.breach}
                options={breachOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "breachImpact",
                }}
                label="What would be the impact of a breach?"
                error={errors.breachImpact}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={false}
                value={values.breachImpact}
                options={breachImpactOptions}
                autosaveForm={autosaveForm}
              />

              <RadioField
                version="v2"
                fieldInfo={{ sectionId: "cyberSecurity", fieldName: "ai" }}
                label="Will you be using generative AI products during any production stages?"
                error={errors.ai}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.ai}
                options={aiOptions}
                autosaveForm={autosaveForm}
              />

              {values.ai === "Yes" ? (
                <SingleLineTextField
                  version="v2"
                  fieldInfo={{
                    sectionId: "cyberSecurity",
                    fieldName: "aiProducts",
                  }}
                  label="What AI products will you be using?"
                  error={errors.aiProducts}
                  shouldDisplayErrors={shouldDisplayErrors}
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  isRequired={false}
                  value={values.aiProducts}
                  autosaveForm={autosaveForm}
                />
              ) : null}

              <div>
                <Typography variant="body1">
                  Please read the following guidance on AI:
                </Typography>
                <ul>
                  <li>
                    <Link
                      href="https://my.itv.com/Home/Index/2be716f9-42e7-4723-b840-802e8497ed6c"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      How to use AI safely and responsibly at ITV
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://docs.google.com/document/d/1gBF1FwGJxts5oRFRogqr9dAlU1mxmRBwo-X4vkVe6xE/edit"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Exploring Generative AI (GenAI) Opportunities Safely &
                      Responsibly
                    </Link>
                  </li>
                </ul>
              </div>

              <RadioField
                version="v2"
                fieldInfo={{
                  sectionId: "cyberSecurity",
                  fieldName: "aiGuidance",
                }}
                label="Have you read the above guidance on AI?"
                error={errors.aiGuidance}
                shouldDisplayErrors={shouldDisplayErrors}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                isRequired={true}
                value={values.aiGuidance}
                options={aiGuidanceOptions}
                autosaveForm={autosaveForm}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}
