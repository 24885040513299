import { BasicInfoSection } from "../containers/NotificationForm/yupSchemas/basicInfoSchema";
import { BasicInfoSectionV3 } from "../containers/NotificationForm/yupSchemas/basicInfoSchemaV3";
import { ComplianceSection } from "../containers/NotificationForm/yupSchemas/complianceSchema";
import { CyberSecuritySection } from "../containers/NotificationForm/yupSchemas/cyberSecuritySchema";
import { DataPrivacySection } from "../containers/NotificationForm/yupSchemas/dataPrivacySchema";
import { DutyOfCareSection } from "../containers/NotificationForm/yupSchemas/dutyOfCareSchema";
import { HealthAndSafetySection } from "../containers/NotificationForm/yupSchemas/healthAndSafetySchema";
import { HealthAndSafetySectionV3 } from "../containers/NotificationForm/yupSchemas/healthAndSafetySchemaV3";
import { InsuranceSection } from "../containers/NotificationForm/yupSchemas/insuranceSchema";
import { InsuranceSectionV3 } from "../containers/NotificationForm/yupSchemas/insuranceSchemaV3";
import { SecuritySection } from "../containers/NotificationForm/yupSchemas/securitySchema";
import { SecuritySectionV3 } from "../containers/NotificationForm/yupSchemas/securitySchemaV3";
import { SustainabilitySection } from "../containers/NotificationForm/yupSchemas/sustainabilitySchema";
import {
  BrowseAssets,
  VideoMetaDataPayload,
} from "../containers/Synopsis/Video";

export type RiskSeverity = "error" | "warning" | "success";

export type RiskMap = {
  High?: {
    severity: RiskSeverity;
    text: string;
    backgroundColor?: string;
    color?: string;
  };
  Medium?: {
    severity: RiskSeverity;
    text: string;
    backgroundColor: string;
    color: string;
  };
  Low?: {
    severity: RiskSeverity;
    text: string;
    backgroundColor?: string;
    color?: string;
  };
  Insignificant?: {
    severity: RiskSeverity;
    text: string;
    backgroundColor?: string;
    color?: string;
  };
};

export type TitleAssets = {
  hasImages: boolean;
  hasScripts: boolean;
  hasFonts: boolean;
  hasVideoAssets: boolean;
  hasDerivedTitles: boolean;
};

export type Title = {
  whoIsThisFor: string;
  productionYear: number;
  intendedSlotLength: number;
  episodeNumber: number;
};

export type TitleDetailsToUpdate = {
  name?: string;
  productionYear?: number;
  intendedSlotLength?: number;
};

export type CreateTitleForm = {
  whoIsThisFor: { value: string; label: string };
  productionYear: string;
  intendedSlotLength: string;
  episodeNumber: string;
};

export type CreateMultipleTitlesForm = {
  whoIsThisFor: { value: string; label: string };
  productionYear: string;
  intendedSlotLength: string;
  episodeCount: string;
};

export interface SeriesDetails {
  ccid: string;
  name: string;
  seriesNumber?: number;
  status?: string;
}

export interface SeriesResponsePayload {
  seriesDetails: SeriesDetails[];
}

export interface TitleDetailsProps {
  ccid: string;
  name?: string;
  status?: string;
  episodeNumber: number;
  episodeId: string;
  productionLabels: Array<string>;
  intendedSlotLength: number;
  productionYear: number;
}

export interface TitlesResponsePayload {
  titleDetails: Array<TitleDetailsProps>;
}

export interface BrandTitleResponsePayload {
  titleDetails: Array<TitleDetailsProps>;
}

export type BrandMetadata = {
  brandName: string;
  brandCcid: string;
  programmeId?: string;
  productionLabel?: string;
  productionLabels: string[];
  seriesCount: number;
  specialsCount?: number;
  status?: string;
  productionYears?: {
    earliest?: number | null;
    latest?: number | null;
  };
};

export type BrandMetadataResponsePayload = {
  brandName: string;
  programmeId?: string;
  productionLabel?: string;
  productionLabels: string[];
  seriesCount: number;
  specialsCount: number;
  status?: string;
  productionYears?: {
    earliest: number | null;
    latest: number | null;
  };
};

export type SeriesMetadata = {
  seriesId?: string;
  seriesCcid: string;
  seriesName?: string; // maybe not optional??
  seriesNumber: number;
  productionLabel?: string;
  productionLabels: string[];
  productionYearSpan?: {
    earliest?: number | null;
    latest?: number | null;
  };
  genre?: string;
  subgenres?: [];
  episodeCount: number;
  intendedSlotLength?: number;
  originatingRequestArea?: string;
};

export type TitleMetadata = {
  titleCcid: string;
  episodeId?: string;
  productionLabel?: string;
  productionLabels: string[];
  productionYear?: number;
  titleName?: string;
  status?: string;
  episodeNumber?: number;
};

export type TitleMetadataResponsePayload = {
  episodeId?: string;
  productionLabel?: string;
  productionLabels: string[];
  productionYear?: number;
  titleName?: string;
  status?: string;
  episodeNumber?: number;
};

export type Metadata = {
  parentCcid?: string;
  parentName?: string;
  brandCcid?: string;
  brandName?: string;
  brandId?: string;
  productionLabel?: string;
  productionLabels: string[];
  seriesCount?: number;
  specialsCount?: number;
  productionYears?: {
    earliest: number | null;
    latest: number | null;
  };
  seriesCcid?: string;
  seriesName?: string;
  seriesId?: string;
  seriesNumber?: number;
  productionYearSpan?: {
    earliest: number | null | undefined;
    latest: number | null | undefined;
  };
  titleCcid?: string;
  titleName?: string;
  titleId?: string;
  productionYear?: number;
  episodeNumber?: number;
  genre?: string;
  subgenres?: [];
  status?: "IN_PRODUCTION" | "IN_DEVELOPMENT" | "ABANDONED" | "OPPORTUNITY";
  productionType:
    | "BRAND"
    | "SERIES"
    | "EPISODE"
    | "FILM"
    | "BRANDED_SPECIAL"
    | "SPECIAL";
};

export type CcidMetadata = {
  parents: string[];
  entryType: string;
};

export interface BrandProps {
  Brand: {
    brandCcid: string;
    brandName: string;
  };
}
export interface OneOffProps {
  OneOff: OneOff;
}

export type Brand = {
  brandCcid: string;
  brandName: string;
};

export type OneOff = {
  titleCcid: string;
  titleName: string;
  titleType: string;
};

export type ProductionType = "series" | "special" | "film" | "oneOff" | "pilot";

export type Production = {
  ccid: string;
  name: string;
  productionType: ProductionType;
};

export interface ContributorProps {
  contributorId?: string;
  forenames: string;
  surname?: string;
  role?: ContributorRoleProps | string;
  character?: string;
  selected?: boolean;
}

export interface Attendee {
  forenames?: string;
  surname?: string;
  role?: string;
  email?: string;
}

export interface ContributorUpdateProps {
  contributorId?: string;
  forenames: string;
  surname?: string;
  role?: string;
  character?: string;
}

export interface ContributorRoleValue {
  value: string;
}

export interface ProgrammeSearchResult {
  Brand?: Brand;
  OneOff?: OneOff;
}

export type ActiveProductionsResponse = {
  ccids: string[];
};

export type AssignedProductionResponse = {
  assignedCcids: string[];
};

export interface ProgrammeSearchPayload {
  results: ProgrammeSearchResult[];
}

export interface VideoAssetsResponsePayload {
  assets: BrowseAssets[];
}

export interface SynopsisResponsePayload {
  synopsisShort: string;
  synopsisMedium: string;
  synopsisLong: string;
  narrative: string;
  userCanEditSynopsis: boolean;
}

export enum TaskGroups {
  Billings = "BILLINGS-AND-SYNOPSIS",
  TimeCodes = "TIMECODES",
  LogosAndFonts = "LOGOS-AND-FONTS",
  StartupAndCloseDownForm = "STARTUP-AND-CLOSE-DOWN-FORM",
  Images = "IMAGES",
  LegalDeliverables = "LEGAL-DELIVERABLES",
  NewProductionNotificationForm = "NEW-PRODUCTION-NOTIFICATION-FORM",
}

export interface TaskGroupAssignmentPayload {
  taskGroup: TaskGroups;
  user: string;
}

export interface ContributorsResponsePayload {
  contributors: ContributorProps[];
  canEditContributors: boolean;
}

export interface ContributorRoleProps {
  value: string;
  displayName: string;
}

export interface ContributorListRoleProps {
  role: string;
  hasCharacter: boolean;
}

export interface NotificationResponsePayload {
  id: number;
  notificationType:
    | "ACCESS_REQUEST"
    | "ACCESS_GRANTED"
    | "RISK_SCORE_COMPLETE"
    | "TASK_ASSIGNMENT"
    | "DRIVE_CREATED"
    | "PRODUCTION_LEAD_ASSIGNED";
  originator: string;
  item: string;
  read: boolean;
  read_at?: string;
  ccid: string;
  created: string;
}

export interface NotificationDetails {
  primaryText: string;
  secondaryText: string;
  getLink: (metadata: CcidMetadata) => string;
  icon: React.ReactNode;
}

export interface NotificationProps {
  primaryText: string;
  secondaryText: string;
  notificationLink: string;
  icon: React.ReactNode;
}

export interface RushURLResponsePayload {
  url: string;
}

export interface EntitlementsResponsePayload {
  hasIconik: boolean;
  email?: string;
}

export interface VideoMetadataResponsePayload {
  versions: VideoMetaDataPayload[];
}

export interface TasksResponsePayload {
  hasSubTasks: boolean;
  tasks: Task[];
}

export type ProductionStage =
  | "preProduction"
  | "inProduction"
  | "postProduction";

export type Task = {
  taskName: string;
  taskStatusUrl: string | null;
};

export type FrontendTask = {
  description: string;
  icon: JSX.Element;
  taskRoute: string;
  taskStatusUrl: string | null;
};

export type FrontendTaskList = {
  [taskName: string]: FrontendTask;
};

export interface TaskStatusPayload {
  completed: boolean;
}

export interface ContributorsFilterProps {
  billingsExcludedRoles: string[];
}

export interface Timecode {
  start: string;
  end: string;
}

export interface TimecodeResponsePayload {
  versionCcid: string;
  timecode: Timecode;
  titleCcid: string;
  tag?: string;
}

export enum OfcomRegion {
  NorthOfEngland = "NORTH",
  NorthernIreland = "NORTHERN-IRELAND",
  MidlandsAndAnglia = "MIDLANDS-AND-ANGLIA",
  Scotland = "SCOTLAND",
  South = "SOUTH",
  Wales = "WALES",
}

export type OfcomFormData = {
  foreignProduction: "Yes" | "No" | "";
  producerCountry: string;
  productionLocation: string;
  substantiveBaseCriteria: boolean;
  productionSpendCriteria: boolean;
  talentCriteria: boolean;
  substantiveBaseAddress: string;
  substantiveBaseRegion: OfcomRegion | "";
  substantiveBaseYear: number;
  substantiveBaseOpenPrior: boolean;
  substantiveBaseCommission?: boolean;
  productionSpendTotalBudget?: number;
  productionSpendTotalQualifyingBudget?: number;
  productionSpendPercentage?: number;
  productionSpendRegion?: OfcomRegion[];
  talentTotalBudget?: number;
  talentBudgetPercentage?: number;
  talentRegion?: OfcomRegion[];
  modifiedTime?: Date;
  submitted?: boolean;
  submittedBy?: string;
};

export type BooleanAsYesNo = "Yes" | "No" | "";

export type OfcomFormDataFormik = {
  submitted: boolean;
  submittedBy: string;
  modifiedTime: string;
  foreignProduction: BooleanAsYesNo;
  producerCountry: string;
  productionLocation: string;
  substantiveBaseCriteria: boolean;
  productionSpendCriteria: boolean;
  talentCriteria: boolean;
  substantiveBaseAddress: string;
  substantiveBaseRegion: OfcomRegion | "";
  substantiveBaseYear: number | "";
  substantiveBaseOpenPrior: BooleanAsYesNo;
  substantiveBaseCommission: BooleanAsYesNo;
  productionSpendTotalBudget: number | "";
  productionSpendTotalQualifyingBudget: number | "";
  productionSpendPercentage: number | "";
  productionSpendRegion: OfcomRegion[];
  talentTotalBudget: number | "";
  talentBudgetPercentage: number | "";
  talentRegion: OfcomRegion[];
};

export type OfcomFormDataServer = {
  submitted: boolean | null;
  submittedBy: string;
  modifiedTime: string;
  foreignProduction: boolean | null;
  producerCountry: string;
  productionLocation: string;
  substantiveBaseCriteria: boolean | null;
  productionSpendCriteria: boolean | null;
  talentCriteria: boolean | null;
  substantiveBaseAddress: string;
  substantiveBaseRegion: OfcomRegion | null;
  substantiveBaseYear: number | null;
  substantiveBaseOpenPrior: boolean | null;
  substantiveBaseCommission: boolean | null;
  productionSpendTotalBudget: number | null;
  productionSpendTotalQualifyingBudget: number | null;
  productionSpendPercentage: number | null;
  productionSpendRegion: OfcomRegion[] | null;
  talentTotalBudget: number | null;
  talentBudgetPercentage: number | null;
  talentRegion: OfcomRegion[] | null;
};

export type AutosaveOfcomFormDataServer = {
  submitted?: boolean | null;
  submittedBy?: string;
  modifiedTime?: string;
  foreignProduction?: boolean | null;
  producerCountry?: string;
  productionLocation?: string;
  substantiveBaseCriteria?: boolean | null;
  productionSpendCriteria?: boolean | null;
  talentCriteria?: boolean | null;
  substantiveBaseAddress?: string;
  substantiveBaseRegion?: OfcomRegion | null;
  substantiveBaseYear?: number | null;
  substantiveBaseOpenPrior?: boolean | null;
  substantiveBaseCommission?: boolean | null;
  productionSpendTotalBudget?: number | null;
  productionSpendTotalQualifyingBudget?: number | null;
  productionSpendPercentage?: number | null;
  productionSpendRegion?: OfcomRegion[] | null;
  talentTotalBudget?: number | null;
  talentBudgetPercentage?: number | null;
  talentRegion?: OfcomRegion[] | null;
};

export type ProductionPrinciplesFormData = {
  submitted: boolean;
  submittedBy: string;
  modifiedTime: string;
  diamondDataConfirmation: string[];
  speakingUpPolicy: string;
  codeOfConduct: string;
  antibullyCommitment: string[];
  disabilityConfirmation: string;
  diversityCommissioningFund: string;
  underrepresentedActor: boolean;
  underrepresentedGroup: boolean;
  underrepresentedStory: boolean;
  PoCHairAndMakeup: boolean;
  underrepresentedCreative: boolean;
  underrepresentedCreativeDetails?: string;
  underrepresentedProductionTeam: boolean;
  underrepresentedProductionTeamDetails?: string;
  underrepresentedCrew: boolean;
  underrepresentedCrewDetails?: string;
  stepUpSixty: boolean;
  stepUpSixtyDetails?: string;
  paidTrainee: boolean;
};

export type ProductionPrinciplesFormDataFormik = {
  submitted: boolean;
  submittedBy: string;
  modifiedTime: string;
  diamondDataConfirmation: boolean;
  speakingUpPolicy: boolean;
  codeOfConduct: boolean;
  antibullyCommitment: string[];
  disabilityConfirmation: boolean;
  diversityCommissioningFund: string;
  underrepresentedActor: BooleanAsYesNo;
  underrepresentedGroup: BooleanAsYesNo;
  underrepresentedStory: BooleanAsYesNo;
  PoCHairAndMakeup: BooleanAsYesNo;
  underrepresentedCreative: BooleanAsYesNo;
  underrepresentedCreativeDetails?: string;
  underrepresentedProductionTeam: BooleanAsYesNo;
  underrepresentedProductionTeamDetails?: string;
  underrepresentedCrew: BooleanAsYesNo;
  underrepresentedCrewDetails?: string;
  stepUpSixty: BooleanAsYesNo;
  stepUpSixtyDetails?: string;
  paidTrainee: BooleanAsYesNo;
};

export type ProductionPrinciplesFormDataAutosave = {
  submitted: boolean | null;
  submittedBy: string;
  modifiedTime: string;
  diamondDataConfirmation: boolean | null;
  speakingUpPolicy: boolean | null;
  codeOfConduct: boolean | null;
  antibullyCommitment: string[] | null;
  disabilityConfirmation: boolean | null;
  diversityCommissioningFund: string | null;
  underrepresentedActor: boolean | null;
  underrepresentedGroup: boolean | null;
  underrepresentedStory: boolean | null;
  PoCHairAndMakeup: boolean | null;
  underrepresentedCreative: boolean | null;
  underrepresentedCreativeDetails?: string | null;
  underrepresentedProductionTeam: boolean | null;
  underrepresentedProductionTeamDetails?: string | null;
  underrepresentedCrew: boolean | null;
  underrepresentedCrewDetails?: string | null;
  stepUpSixty: boolean | null;
  stepUpSixtyDetails?: string | null;
  paidTrainee: boolean | null;
};

export type ProductionPrinciplesFormDataServer = {
  submitted: boolean | null;
  submittedBy: string;
  modifiedTime: string;
  inclusionPolicies: ProductionPrinciplesInclusionPolicies;
  inclusiveProgramming: ProductionPrinciplesInclusiveProgramming;
};

export type ProductionPrinciplesInclusionPolicies = {
  diamondDataConfirmation: boolean | null;
  speakingUpPolicy: boolean | null;
  codeOfConduct: boolean | null;
  antibullyCommitment: string[] | null;
  disabilityConfirmation: boolean | null;
  diversityCommissioningFund: string | null;
};

export type ProductionPrinciplesInclusiveProgramming = {
  underrepresentedActor: boolean | null;
  underrepresentedGroup: boolean | null;
  underrepresentedStory: boolean | null;
  PoCHairAndMakeup: boolean | null;
  underrepresentedCreative: boolean | null;
  underrepresentedCreativeDetails?: string | null;
  underrepresentedProductionTeam: boolean | null;
  underrepresentedProductionTeamDetails?: string | null;
  underrepresentedCrew: boolean | null;
  underrepresentedCrewDetails?: string | null;
  stepUpSixty: boolean | null;
  stepUpSixtyDetails?: string | null;
  paidTrainee: boolean | null;
};

export type AutosaveProductionPrinciplesFormDataServer = {
  submitted?: boolean | null;
  submittedBy?: string;
  modifiedTime?: string;
  inclusionPolicies:
    | ProductionPrinciplesInclusionPolicies
    | Record<string, never>;
  inclusiveProgramming:
    | ProductionPrinciplesInclusiveProgramming
    | Record<string, never>;
};

export interface EssenceTimecodeResponsePayload {
  essenceTimecode: Timecode;
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string | null;
  status?: string;
  lastActive: string;
  permissions: string[];
  accessManager: boolean;
  canEditCCID: boolean;
}

export interface Group {
  id: string;
  name: string;
}

export interface UserProfile {
  firstName: string;
  surname: string;
  productionLabels: string[];
  userType: "ADMIN" | "LABEL" | "MANDE" | "GENERAL";
  viewingAs?: string;
}

export interface AccessPermissionsEntitlements {
  canManageAccess: boolean;
  canCreateCcid: boolean;
  canBulkDownloadImages: boolean;
}

export enum RiskLevel {
  Insignificant = "Insignificant",
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export interface RiskAssessor {
  name: string;
  email: string;
}

export type RiskScoreId =
  | "healthAndSafety"
  | "dutyOfCare"
  | "security"
  | "cyberSecurity"
  | "sustainability"
  | "dataPrivacy"
  | "insurance"
  | "compliance";

export interface RiskData {
  assessor?: RiskAssessor;
  rating?: RiskLevel;
  notes?: string;
}

export type RiskScore = Record<RiskScoreId, RiskData>;

export interface LegalDeliverableData {
  OfcomFormResponse: {
    data: {
      submitted: true;
      submittedBy: "string";
      modifiedTime: "string";
      foreignProduction: true;
      producerCountry: "string";
      productionLocation: "string";
      substantiveBaseCriteria: true;
      productionSpendCriteria: true;
      talentCriteria: true;
      substantiveBaseAddress: "string";
      substantiveBaseRegion: "NORTH";
      substantiveBaseYear: 0;
      substantiveBaseOpenPrior: true;
      substantiveBaseCommission: true;
      productionSpendTotalBudget: 0;
      productionSpendTotalQualifyingBudget: 0;
      productionSpendPercentage: 0;
      productionSpendRegion: ["NORTH"];
      talentTotalBudget: 0;
      talentBudgetPercentage: 0;
      talentRegion: ["NORTH"];
    };
  };
}

export interface DriveResponse {
  url: string;
}

export interface ListDriveResponse {
  productionUrl?: string;
  privateUrl?: string;
}

export interface CcidDropdownResponse {
  producers: { value: string; label: string }[];
  commissioners: string[];
  whoIsThisFor: string[];
  genres: string[];
  subgenres: string[];
}

export type CreateEpisodePayload = {
  whoIsThisFor: string;
  productionYear: number;
  intendedSlotLength: number;
  episodeNumber: number;
};

export type CreateCcidFormDataOld = {
  name?: string;
  brandName?: string;
  productionLabels: string[];
  commissioner: string;
  genre: string;
  whoIsThisFor: string;
  subgenres?: string[];
  productionYear?: number;
  specialName?: string;
  sequence?: string;
  count?: string;
  intendedSlotLength?: number;
};

export type CreateCcidFormData = {
  productionType?: string;
  name?: string;
  brandName?: string;
  productionLabels: Array<{
    label: string;
    value: string;
  }>;
  commissioner: string;
  whoIsThisFor: {
    label: string;
    value: string;
  };
  genre: {
    label: string;
    value: string;
  };
  subgenres?: Array<{
    label: string;
    value: string;
  }>;
  productionYear?: string;
  specialName?: string;
  sequence?: string;
  count?: string;
  intendedSlotLength?: string;
};

export type CreateCcidPayload = {
  productionYear: number;
  intendedSlotLength: number;
  name?: string;
  brandName?: string;
  productionLabels: string[];
  commissioner: string;
  genre: string;
  whoIsThisFor: string;
  subgenres?: string[];
  specialName?: string;
  sequence?: number;
  count?: number;
};

export interface CcidNewBrandSeriesPayload {
  seriesName: string;
  productionLabels: string[];
  commissioner: string;
  whoIsThisFor: string;
  productionYear: number;
  sequence: number;
  count: number;
  intendedSlotLength: number;
  genre: string;
  subgenres: string[];
}

export interface CcidNewBrandedSpecialPayload {
  seriesName: string;
  productionLabels: string[];
  commissioner: string;
  whoIsThisFor: string;
  productionYear: number;
  intendedSlotLength: number;
  genre: string;
  subgenres: string[];
}

export interface CcidCreateSeriesPayload {
  productionLabels: string[];
  commissioner: string;
  sequence: number;
  count: number;
  whoIsThisFor: string;
  productionYear: number;
  intendedSlotLength: number;
  genre: string;
  subgenres: Array<string>;
}

export interface CcidNewOneOffOrFilmPayload {
  name: string;
  productionLabels: string[];
  commissioner: string;
  whoIsThisFor: string;
  productionYear: number;
  intendedSlotLength: number;
  genre: string;
  subgenres: string[];
}

export interface CcidCreateBrandedSpecialPayload {
  specialName: string;
  productionLabels: string[];
  commissioner: string;
  whoIsThisFor: string;
  genre: string;
  subgenres: Array<string>;
  productionYear: number;
  intendedSlotLength: number;
}

export type LevelType = "titles" | "series" | "brands";

export type VersionDetails = {
  versionCcid: string;
  editReasons: string[];
  editReasonComments: string;
  language: string;
  originatingRequestArea: string;
  productionId: string;
  episodeNumber: number;
  externalId: string;
};

export type SeriesVersionsResponse = {
  totalVersions: number;
  versionDetails: VersionDetails[];
};

export type ProductionStatus =
  | "Development"
  | "Pre Production"
  | "Production"
  | "Post Production"
  | "Closed"
  | "Cancelled"
  | "Paused";

export type ProductionCardResponse = {
  productionLead: string | null;
  productionStatus: ProductionStatus | null;
  productionRiskRating: RiskLevel | null;
};

export type CreateMyDrivePayload = {
  startEpisode: number;
  endEpisode: number;
  filmingUnit: number;
  directorName: string;
};

export type ProductionisePayload = {
  productionLabels: string[];
  commissioner: string;
  whoIsThisFor: string;
  productionYear: number;
  intendedSlotLength: number;
  count?: number;
};

export type Tabs =
  | "tasks"
  | "synopsis"
  | "contributors"
  | "txmaster"
  | "preTitleSequence"
  | "iconicKeyArtOneOff"
  | "iconicKeyArtOneOffPresenterContributor"
  | "iconicKeyArtOneOffGallery"
  | "oneOffBehindScenes"
  | "logoOneOff"
  | "notificationForm"
  | "startUpForm"
  | "productionNumbers"
  | "iconicKeyArtSeries"
  | "iconicKeyArtSeriesPresenterContributor"
  | "iconicKeyArtSeriesGallery"
  | "logoSeries"
  | "episodeClipTimecode"
  | "episodicBehindScenes";

export type ClickableComponentProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export type ContactUsComponentProps = {
  ClickableComponent: React.ComponentType<ClickableComponentProps>;
};

export type TaskGroupAssignments = {
  [K in Exclude<TaskGroups, TaskGroups.LegalDeliverables>]: assigneeDetails[];
} & {
  [TaskGroups.LegalDeliverables]?: assigneeDetails[];
};

export type TaskGroupAssignmentResponse = {
  groupAssignments: TaskGroupAssignments;
};

export interface assigneeDetails {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

type TreatmentThumbnail = {
  url: string;
  width: number;
  height: number;
};

type TreatmentThumbnails = {
  small: TreatmentThumbnail;
  large: TreatmentThumbnail;
};

export type Treatment = {
  url: string;
  filename: string;
  size: number;
  mimeType: string;
  thumbnails?: TreatmentThumbnails;
};

export type Treatments = {
  assets: Treatment[];
};

export interface DropdownMetadata {
  productionStatus: Array<string>;
  broadcaster: Array<string>;
  countries: Array<string>;
  securityCountries: Array<string>;
  currencies: Array<string>;
}

export type DropdownMetadataKey = keyof DropdownMetadata;

interface BasicInfoForm {
  productionStatus: string | null;
  productionType: "Broadcast" | "Sizzle" | "Other" | null;
  otherProductionType: string | null;
  contentType: "Scripted" | "Non-Scripted" | null;
  insuranceCover: "ITV" | "Broadcaster" | "Other" | "TBC" | null;
  otherInsuranceCover: string | null;
  broadcaster: string | null;
  otherBroadcaster: string | null;
  narrative: string | null;
  currency: string | null;
  budget: number | null;
  creativeLead: string | null;
  creativeLeadEmail: string | null;
  productionContact: string | null;
  productionContactEmail: string | null;
  recordingType: "PreRecorded" | "Live" | null;
  outsideLocation: boolean | null;
  preProductionDate: string | null;
  principalStartDate: string | null;
  principalFinishDate: string | null;
  startPostDate: string | null;
  productionCloseDate: string | null;
  specialPurposeVehicle: boolean | null;
  specialPurposeVehicleName: string | null;
  castEstimate:
    | "OneToNine"
    | "TenToNineteen"
    | "TwentyToTwentyNine"
    | "ThirtyOrMore"
    | null;
  crewEstimate:
    | "OneToFortyNine"
    | "FiftyToNinetyNine"
    | "HundredToHundredFortyNine"
    | "HundredFiftyOrMore"
    | null;
  disgraceCover: "Yes" | "No" | "TBC" | null;
  disgraceDetails: string | null;
  migratedSubmission: boolean | null;
  submittedBy: string | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface HealthAndSafetyForm {
  responsible: string | null;
  responsibleEmail: string | null;
  responsibleNone: boolean | null;
  training: "NoTraining" | "External" | "Internal" | null;
  trainingCourse: string | null;
  activities: Array<
    | "FilmingWithAnimals"
    | "FoodHandling"
    | "Underground"
    | "Extreme"
    | "JetSkisOrOther"
    | "DivingOrOther"
    | "OtherHighRisk"
    | "NoneOfAbove"
  > | null;
  otherActivities: string | null;
  filmingTechniques:
    | "NoUse"
    | "Under10Percent"
    | "Between10And25Percent"
    | "Over25Percent"
    | null;
  weatherConditions:
    | "NoUse"
    | "Under5Percent"
    | "Between5And10Percent"
    | "Over10Percent"
    | null;
  firearms: "NoFirearms" | "Imitation" | "NonImitation" | "LiveFirearms" | null;
  sfx: "NoSfx" | "LikelySingle" | "LikelyMultiple" | "Significant" | null;
  stunt: "NoStunt" | "LikelySingle" | "LikelyMultiple" | "Significant" | null;
  setBuild: "NoSet" | "Minor" | "Moderate" | "Significant" | null;
  filmingLocation:
    | "Studio"
    | "MajorityInStudio"
    | "HalfInLocation"
    | "MajorityInLocation"
    | null;
  locationType:
    | "InLocation"
    | "SingleShootInLocation"
    | "MultipleShootsInLocation"
    | "NotYetKnown"
    | null;
  audience: boolean | null;
  vulnerable:
    | "NoVulnerableAndChildren"
    | "WithChildren"
    | "WithVulnerableAdults"
    | "Both"
    | null;
  covid: boolean | null;
  covidDetails: string | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface DutyOfCareForm {
  mentalHealthAdvisor: string | null;
  mentalHealthAdvisorEmail: string | null;
  degreeOfControl:
    | "Actuality"
    | "ProducedActuality"
    | "ConstructedReality"
    | "TotalControl"
    | null;
  participantDemographic: Array<
    | "Celebrity"
    | "VulnerableCeleb"
    | "Influencer"
    | "GeneralPublic"
    | "VulnerableGeneralPublic"
  > | null;
  exposure: "Low" | "Medium" | "High" | null;
  participantLocation: "Home" | "AwayWithHomeContact" | "Away" | null;
  livingArrangements:
    | "Alone"
    | "Private"
    | "Shared"
    | "Dormitory"
    | "NotApplicable"
    | null;
  popularityProfile: "Low" | "Medium" | "High" | "Active" | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface SecurityForm {
  additionalSecurity: "NotRequired" | "Required" | "NotYetKnown" | null;
  suppliers?: Array<
    | "AthenaSecuritySolutions"
    | "CapricornSecurity"
    | "GuardForceSecurity"
    | "LocationSecure"
    | "MinimalRisk"
    | "Peregrine"
    | "RockSecuritySolutions"
    | "SafeAndSoundGroup"
    | "SRMSecurity"
    | "TitanRiskManagement"
    | "Other"
  > | null;
  securityProvider: string | null;
  atRiskCountries: Array<string> | null;
  internationalTravel: boolean | null;
  covert: "Yes" | "No" | "TBC" | null;
  covertSynopsis: string | null;
  provocation: "Yes" | "No" | "TBC" | null;
  provocationDetail: string | null;
  risk: "Yes" | "No" | "TBC" | null;
  riskDetail: string | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface CyberSecurityForm {
  cyberResponsible: string | null;
  cyberResponsibleEmail: string | null;
  newTech: "Yes" | "No" | "TBC" | null;
  newTechDetail: string | null;
  appList: Array<
    | "Whatsapp"
    | "Signal"
    | "GoogleDrive"
    | "Dropbox"
    | "OneDrive"
    | "Box"
    | "WeTransfer"
    | "ETribez"
    | "Zoom"
    | "MicrosoftTeams"
    | "Other"
  > | null;
  appListDetail: string | null;
  dataStorage: Array<
    | "AdobeSign"
    | "Iconik"
    | "PIX"
    | "Prodicle"
    | "Scenechronize"
    | "SetKeeper"
    | "TeamEngine"
    | "Other"
  > | null;
  dataStorageSupplier: string | null;
  dataLink: "Yes" | "No" | "TBC" | null;
  dataLinkDetail: string | null;
  dataAccess: "Yes" | "No" | "TBC" | null;
  dataStorageCountry: string | null;
  breach: "Yes" | "No" | "Not yet known" | null;
  breachImpact:
    | "NoImpact"
    | "LowImpact"
    | "ModerateImpact"
    | "SignificantImpact"
    | null;
  ai: "Yes" | "No" | "Not yet known" | null;
  aiProducts: string | null;
  aiGuidance: "Yes" | "No" | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface SustainabilityForm {
  country: string | null;
  environmentList: string | null;
  hazard: "Yes" | "No" | "TBC" | null;
  naturalHazard: Array<
    | "Floods"
    | "RainSnow"
    | "Heat"
    | "Thunderstorms"
    | "HurricanesTyphoons"
    | "Earthquakes"
    | "Volcanic"
    | "Wildfires"
    | "Tsunami"
    | "Landslides"
    | "Drought"
  > | null;
  setbuild: boolean | null;
  filmloc:
    | "Studio"
    | "MajorityInStudio"
    | "HalfInLocation"
    | "MajorityInLocation"
    | null;
  lighting:
    | "AllLowEnergy"
    | "MajorityLowEnergy"
    | "HalfLowEnergy"
    | "MajorityHighEnergy"
    | "NotYetKnown"
    | null;
  gen: "Yes" | "No" | "TBC" | null;
  flying: "None" | "SmallAmount" | "MediumAmount" | "LargeAmount" | null;
  mileage:
    | "VeryShortDistance"
    | "ShortDistance"
    | "MediumDistance"
    | "LargeDistance"
    | null;
  calculator: "Albert" | "Ecoprod" | "GreenShootingMFG" | "Other" | null;
  calculatorDetails: string | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface DataPrivacyForm {
  novelData: "Yes" | "No" | "Not yet known" | null;
  covertActivities: "Yes" | "No" | "Not yet known" | null;
  subjectsAware: "Yes" | "No" | "Not yet known" | null;
  riskOfHarm: "Yes" | "No" | "Not yet known" | null;
  journalisticAims: "Yes" | "No" | "Not yet known" | null;
  personalRelationships: "Yes" | "No" | "Not yet known" | null;
  sensitiveMaterial: "Yes" | "No" | "Not yet known" | null;
  childrenFeatured: "Yes" | "No" | "Not yet known" | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface InsuranceForm {
  talentLoss: boolean | null;
  talentLossList: string | null;
  shutdown: boolean | null;
  shutdownDetails: string | null;
  age: boolean | null;
  jewellery: "Yes" | "No" | "Likely" | "Unlikely" | null;
  personalCash: "Yes" | "No" | "Likely" | "Unlikely" | null;
  productionCash: "Yes" | "No" | "Likely" | "Unlikely" | null;
  post: "Yes" | "No" | "Not yet known" | null;
  ob: "Yes" | "No" | "Not yet known" | null;
  av: "Yes" | "No" | "Not yet known" | null;
  vehicles: "Yes" | "No" | "Not yet known" | null;
  sound: "Yes" | "No" | "Not yet known" | null;
  rigging: "Yes" | "No" | "Not yet known" | null;
  camera: "Yes" | "No" | "Not yet known" | null;
  psc: "Yes" | "No" | "Not yet known" | null;
  source: "Yes" | "No" | "Not yet known" | null;
  lighting: "Yes" | "No" | "Not yet known" | null;
  studio: "Yes" | "No" | "Not yet known" | null;
  set: "Yes" | "No" | "Not yet known" | null;
  security: "Yes" | "No" | "Not yet known" | null;
  catering: "Yes" | "No" | "Not yet known" | null;
  procOther: string | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface ComplianceForm {
  countries: Array<string> | null;
  slavery: boolean | null;
  corruption: boolean | null;
  sanctions: boolean | null;
  tax: boolean | null;
  vat: boolean | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

export interface ProductionNotificationForm {
  basicInfo?: BasicInfoForm;
  healthAndSafety?: HealthAndSafetyForm;
  dutyOfCare?: DutyOfCareForm;
  security?: SecurityForm;
  cyberSecurity?: CyberSecurityForm;
  sustainability?: SustainabilityForm;
  dataPrivacy?: DataPrivacyForm;
  insurance?: InsuranceForm;
  compliance?: ComplianceForm;
}

export type NotificationFormFieldInfo =
  | { sectionId: "basicInfo"; fieldName: keyof BasicInfoSection }
  | { sectionId: "healthAndSafety"; fieldName: keyof HealthAndSafetySection }
  | { sectionId: "dutyOfCare"; fieldName: keyof DutyOfCareSection }
  | { sectionId: "security"; fieldName: keyof SecuritySection }
  | { sectionId: "cyberSecurity"; fieldName: keyof CyberSecuritySection }
  | { sectionId: "sustainability"; fieldName: keyof SustainabilitySection }
  | { sectionId: "dataPrivacy"; fieldName: keyof DataPrivacySection }
  | { sectionId: "insurance"; fieldName: keyof InsuranceSection }
  | { sectionId: "compliance"; fieldName: keyof ComplianceSection };

export type GetBrandsParams = {
  searchTerm: string;
  limit?: number;
  includeOpportunity?: boolean;
  filterByUserLabels?: boolean;
};

export type NotificationFormFieldInfoV3 =
  | {
      sectionId: "basicInfo";
      fieldName: keyof BasicInfoSectionV3;
    }
  | { sectionId: "healthAndSafety"; fieldName: keyof HealthAndSafetySectionV3 }
  | { sectionId: "insurance"; fieldName: keyof InsuranceSectionV3 }
  | { sectionId: "security"; fieldName: keyof SecuritySectionV3 };

export type NotificationFormFieldProps<BaseProps> = BaseProps &
  (
    | { version: "v2"; fieldInfo: NotificationFormFieldInfo }
    | { version: "v3"; fieldInfo: NotificationFormFieldInfoV3 }
  );

interface BasicInfoFormV3 {
  spotId: string | null;
  productionStatus: string | null;
  productionType: "Broadcast" | "Sizzle" | "Other" | null;
  otherProductionType: string | null;
  contentType: "Scripted" | "Non-Scripted" | null;
  broadcaster: string | null;
  otherBroadcaster: string | null;
  narrative: string | null;
  currency: string | null;
  budget: number | null;
  creativeLead: string | null;
  creativeLeadEmail: string | null;
  productionContact: string | null;
  productionContactEmail: string | null;
  recordingType: "PreRecorded" | "Live" | null;
  preProductionDate: string | null;
  principalStartDate: string | null;
  principalFinishDate: string | null;
  startPostDate: string | null;
  productionCloseDate: string | null;
  specialPurposeVehicle: boolean | null;
  specialPurposeVehicleName: string | null;
  castEstimate:
    | "OneToNine"
    | "TenToNineteen"
    | "TwentyToTwentyNine"
    | "ThirtyOrMore"
    | null;
  crewEstimate:
    | "OneToFortyNine"
    | "FiftyToNinetyNine"
    | "HundredToHundredFortyNine"
    | "HundredFiftyOrMore"
    | null;
  disgraceCover: "Yes" | "No" | "TBC" | null;
  disgraceDetails: string | null;
  migratedSubmission: boolean | null;
  submittedBy: string | null;
  submitted: boolean | null;
  lastUpdated: string | null;
  additionalInfo: string | null;
}

interface HealthAndSafetyFormV3 {
  spotId: string | null;
  responsible: string | null;
  responsibleEmail: string | null;
  responsibleNone: boolean | null;
  training:
    | "NoTraining"
    | "External"
    | "Internal"
    | "MarkMilsomeSafetyCourse"
    | null;
  trainingCourse: string | null;
  externalProvider: boolean | null;
  activities: Array<
    | "FilmingWithAnimals"
    | "FoodHandling"
    | "Underground"
    | "Extreme"
    | "JetSkisOrOther"
    | "DivingOrOther"
    | "Firearms"
    | "Drones"
    | "AdverseWeather"
    | "Stunts"
    | "LoneWorking"
    | "OtherHighRisk"
    | "NoneOfAbove"
  > | null;
  otherActivities: string | null;
  setBuild: boolean | null;
  filmingLocation: boolean | null;
  vulnerable: "Yes" | "No" | "TBC" | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface InsuranceFormV3 {
  spotId: string | null;
  insuranceCover: "ITV" | "Broadcaster" | "Other" | "TBC" | null;
  otherInsuranceCover: string | null;
  talentLoss: boolean | null;
  talentLossList: string | null;
  shutdown: boolean | null;
  shutdownDetails: string | null;
  holdings: Array<"Jewellery" | "SmallCash" | "LargeCash" | "None">;
  age: boolean | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

interface SecurityFormV3 {
  spotId: string | null;
  additionalSecurity: "Yes" | "No" | "NotYetKnown" | null;
  suppliers?: Array<
    | "AthenaSecuritySolutions"
    | "CapricornSecurity"
    | "GuardForceSecurity"
    | "LocationSecure"
    | "MinimalRisk"
    | "Peregrine"
    | "RockSecuritySolutions"
    | "SafeAndSoundGroup"
    | "SRMSecurity"
    | "TitanRiskManagement"
    | "Other"
  > | null;
  securityProvider: string | null;
  atRiskCountries: Array<string> | null;
  internationalTravel: boolean | null;
  provocation: "Yes" | "No" | "TBC" | null;
  provocationDetail: string | null;
  risk: "Yes" | "No" | "TBC" | null;
  riskDetail: string | null;
  submitted: boolean | null;
  lastUpdated: string | null;
}

export interface ProductionNotificationFormV3 {
  basicInfo?: BasicInfoFormV3;
  healthAndSafety?: HealthAndSafetyFormV3;
  security?: SecurityFormV3;
  insurance?: InsuranceFormV3;
}
