/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import { PersonRounded } from "@mui/icons-material";
import { RiskAssessor, RiskLevel, RiskMap } from "../../../types/types";

export interface RiskContactProps {
  riskAssessor: RiskAssessor;
  riskLevel?: RiskLevel;
  riskNotes?: string;
}

const riskMap: RiskMap = {
  [RiskLevel.High]: {
    severity: "error",
    text: "High Risk",
  },
  [RiskLevel.Medium]: {
    severity: "warning",
    text: "Medium Risk",
    backgroundColor: "#fdf0e6",
    color: "#5f2b03",
  },
  [RiskLevel.Low]: {
    severity: "success",
    text: "Low Risk",
  },
};

export const RiskContact: FC<RiskContactProps> = ({
  riskAssessor,
  riskLevel,
  riskNotes,
}) => {
  const riskData = useMemo(() => {
    if (riskLevel) {
      return riskMap[riskLevel];
    }

    return false;
  }, [riskLevel]);

  return (
    <Grid container spacing={2} paddingBottom={2}>
      <Grid item xs={6}>
        <Card sx={{ height: "100%", maxHeight: 140, display: "flex" }}>
          <CardHeader
            avatar={<PersonRounded color="disabled" fontSize="large" />}
            title={riskAssessor.name}
            subheader={`Contact for enquiries: ${riskAssessor.email}`}
          />
        </Card>
      </Grid>
      {riskData && (
        <Grid item xs={6}>
          <Alert
            severity={riskData.severity}
            sx={{
              boxShadow: 2,
              maxHeight: 140,
              height: "100%",
            }}
          >
            <AlertTitle>{riskData.text}</AlertTitle>
            {riskNotes && (
              <>
                <Typography fontWeight="bold" variant="body2" component="span">
                  Notes from Group Risk:{" "}
                </Typography>
                <Typography>{riskNotes}</Typography>
              </>
            )}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};
