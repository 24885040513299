import { useState, FC, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  CircularProgress,
  Grid,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { useBrandMetadata, useCcidDropdown } from "../../hooks";
import { CreateCcidFormData } from "../../types/types";
import { CustomAutocompleteField } from "../../componentsV2/FormFields/CustomAutocompleteField";
import { CustomTextField } from "../../componentsV2/FormFields/CustomTextField";
import { WhoIsThisForEnum, WhoIsThisForLabels } from "../../constants";

interface PrePopulatedProps {
  genre?: string;
  subGenre?: string[];
  productionLabels?: string[];
  intendedSlotLength?: number;
  productionYear?: number | undefined | null;
  episodeCount?: number;
  whoIsThisFor?: string;
  seriesNumber?: number;
}

interface CcidCreationFormFormikProps {
  useUpdateSeries: boolean;
  prepopulatedData: PrePopulatedProps;
  excludeCount?: boolean;
}

export const CcidCreationFormFormik: FC<CcidCreationFormFormikProps> = ({
  useUpdateSeries,
  prepopulatedData,
  excludeCount,
}) => {
  const currentYear = new Date().getFullYear();
  const { programmeCcid } = useParams<string>();
  const { isLoading: brandLoading } = useBrandMetadata(programmeCcid, true);
  const { data: dropdownOptions, isLoading: isLoadingDropdownOptions } =
    useCcidDropdown();
  const form = useFormikContext<CreateCcidFormData>();
  const [showWhoisThisFor, setShowWhoisThisFor] = useState<boolean>(false);

  useEffect(() => {
    if (form.values.commissioner && form.values.commissioner === "ITV") {
      setShowWhoisThisFor(true);
    }
    if (form.values.commissioner && form.values.commissioner !== "ITV") {
      setShowWhoisThisFor(false);
    }
  }, [form]);

  const whoIsThisForOptions = [
    WhoIsThisForEnum.BROADCAST,
    WhoIsThisForEnum.ONLINE,
    WhoIsThisForEnum.GLOBAL_DISTRIBUTION,
  ];

  const productionYearOptions = () => {
    const listOfYears: string[] = [];
    for (let i = 0; i < 16; i += 1) {
      listOfYears.push((currentYear - 5 + i).toString());
    }
    return listOfYears;
  };

  const formatOption = (option: string) => {
    const regex = /[^a-zA-Z0-9]/g;
    const lowercaseStr = option.toLowerCase();
    const capitalizedStr =
      lowercaseStr.charAt(0).toUpperCase() + lowercaseStr.slice(1);
    return capitalizedStr.replace(regex, " ");
  };

  return (
    <>
      {isLoadingDropdownOptions || brandLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container rowSpacing={2} columnSpacing={2} my={0}>
            {/* Commissioner */}
            <Grid item xs={6}>
              <Field
                component={CustomAutocompleteField}
                options={dropdownOptions?.commissioners}
                label="Commissioner"
                name="commissioner"
              />
            </Grid>

            {/* productionLabels */}
            <Grid item xs={6}>
              <Field
                error={form.errors.productionLabels}
                component={CustomAutocompleteField}
                options={dropdownOptions?.producers || []}
                label="Producer(s)"
                name="productionLabels"
                multiple
              />
            </Grid>

            {/* Intended Slot length */}
            <Grid item xs={6}>
              <Field
                component={CustomTextField}
                name="intendedSlotLength"
                label="Intended Slot Length"
                required
                endAdornment="minutes"
              />
            </Grid>

            {/* Production Year */}
            <Grid item xs={6}>
              <Field
                component={CustomAutocompleteField}
                options={productionYearOptions()}
                label="Production Year"
                name="productionYear"
              />
              {/* )} */}
            </Grid>

            {/* Special Title */}
            {form.values.productionType === "special" && (
              <Grid item xs={6}>
                <Field
                  component={CustomTextField}
                  name="specialName"
                  label="Special Title"
                  required
                />
              </Grid>
            )}

            {/* Series Number */}
            {form.values.productionType === "series" && !useUpdateSeries && (
              <Grid item xs={6}>
                <Field
                  component={CustomTextField}
                  name="sequence"
                  label="Series Number"
                  required
                  startAdornment="Series"
                />
              </Grid>
            )}

            {/* Episode Count */}
            {!excludeCount && form.values.productionType === "series" && (
              <Grid item xs={6}>
                <Field
                  component={CustomTextField}
                  disabled={
                    prepopulatedData.episodeCount !== undefined &&
                    prepopulatedData.episodeCount !== 0
                  }
                  name="count"
                  label="Episode Count"
                  required
                  endAdornment="Episodes"
                />
              </Grid>
            )}

            {/* Who is this for */}
            {showWhoisThisFor && (
              <Grid item xs={6}>
                <Field
                  component={CustomAutocompleteField}
                  options={whoIsThisForOptions.map((who) => ({
                    label: WhoIsThisForLabels[who],
                    value: who,
                  }))}
                  label="Where is the primary transmission"
                  name="whoIsThisFor"
                />
              </Grid>
            )}

            <Grid
              container
              rowSpacing={2}
              paddingLeft={2}
              columnSpacing={2}
              my={0}
            >
              {/* Genre */}
              <Grid item xs={6}>
                {!useUpdateSeries ? (
                  <Field
                    component={CustomAutocompleteField}
                    options={
                      dropdownOptions?.genres.map((genre) => ({
                        label: formatOption(genre),
                        value: genre,
                      })) || []
                    }
                    label="Genre"
                    name="genre"
                  />
                ) : (
                  <Autocomplete
                    multiple
                    id="tags-readOnly"
                    options={[]}
                    defaultValue={
                      prepopulatedData.genre ? [prepopulatedData.genre] : []
                    }
                    disabled
                    size="small"
                    readOnly
                    renderInput={(params) => (
                      <TextField {...params} label="Genre" />
                    )}
                  />
                )}
              </Grid>

              {/* subgenre */}
              <Grid item xs={6}>
                {!useUpdateSeries ? (
                  <Field
                    component={CustomAutocompleteField}
                    options={
                      dropdownOptions?.subgenres.map((genre) => ({
                        label: formatOption(genre),
                        value: genre,
                      })) || []
                    }
                    label="Subgenres"
                    name="subgenres"
                    multiple
                    filterSelectedOptions
                  />
                ) : (
                  <Autocomplete
                    multiple
                    id="tags-readOnly"
                    size="small"
                    options={[]}
                    defaultValue={prepopulatedData.subGenre?.map(
                      (subGenre: string) => formatOption(subGenre),
                    )}
                    disabled
                    readOnly
                    renderInput={(params) => (
                      <TextField {...params} label="Subgenre" />
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
