import React, { FC, useState, useCallback, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Typography, Stack, Container, Card, Box } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { timeCodeConfig } from "./timecode.config";
import { EpisodesDropdown } from "./EpisodesDropdown";
import { VersionsList } from "./VersionsList";
import { TimeRange } from "./TimeCodeEditor";
import { TimeCodeVideoPlayer } from "./TimeCodeVideoPlayer";
import { ActionBar } from "../../../../../components";
import { useTimecode, useTitleVersions, useTitles } from "../../../../../hooks";
import { useUpdateTimecode } from "../../../../../hooks/mutations/useUpdateTimecode";
import theme from "../../../../../utils/theme";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";

export interface PreviewTimeCodeProps {
  tag?: string;
}

export const PreviewTimeCode: FC<PreviewTimeCodeProps> = ({
  tag = "preview",
}) => {
  const { titleCcid: titleCcidFromUrl, seriesCcid } = useParams<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: titlesData } = useTitles(seriesCcid, "series");
  const config = timeCodeConfig[tag];
  const queryTag = config.queryTag || tag;
  const hasFixedTimecodeLength: boolean = tag === "marketingPromoClip";

  const [titleCcid, setTitleCcid] = useState<string>(titleCcidFromUrl ?? "");
  const [selectedVersionId, setSelectedVersionId] = useState<string>();
  const { data: timecode } = useTimecode(
    titleCcidFromUrl,
    seriesCcid,
    queryTag,
  );
  const [timeRange, setTimeRange] = useState<TimeRange>();
  const [hasChange, setHasChange] = useState<boolean>(false);
  const { data: videoMetaData, isLoading: isLoadingVersion } =
    useTitleVersions(titleCcid);
  const [timecodeError, setTimecodeError] = useState("");

  useEffect(() => {
    if (seriesCcid && !titleCcidFromUrl && tag === "episodeClipTimecode") {
      navigate(`${location.pathname}?tab=txmaster`);
    }
  }, [location.pathname, navigate, seriesCcid, tag, titleCcidFromUrl]);

  useEffect(() => {
    if (titleCcidFromUrl) {
      setTitleCcid(titleCcidFromUrl);
    }
  }, [titleCcidFromUrl]);

  const { mutate, isLoading: isUpdating } = useUpdateTimecode(
    titleCcidFromUrl || "",
    () => {
      enqueueSnackbar("timecode has been successfully updated", {
        variant: "success",
      });
      setHasChange(false);
    },
    () => {
      enqueueSnackbar("here was error on updating timecode", {
        variant: "error",
        action: SnackbarDismiss,
      });
    },
    seriesCcid,
  );

  const handleVersionChange = useCallback(
    (newVersionId: string) => {
      setSelectedVersionId(newVersionId);
      if (newVersionId === timecode?.versionCcid) {
        setTimeRange({
          startTime: timecode?.timecode?.start,
          endTime: timecode?.timecode?.end,
        });
      } else {
        setTimeRange(undefined);
      }
      setTimecodeError("");
      setHasChange(false);
    },
    [timecode?.timecode?.end, timecode?.timecode?.start, timecode?.versionCcid],
  );

  const handleTimeRangeChange = useCallback((newTimeRange: TimeRange) => {
    setTimeRange(newTimeRange);
    setHasChange(true);
  }, []);

  const setTimecode = useCallback(() => {
    if (timecode) {
      setSelectedVersionId(timecode.versionCcid);
      setTimeRange({
        startTime: timecode.timecode.start,
        endTime: timecode.timecode.end,
      });

      if (!titleCcid && !titleCcidFromUrl) {
        setTimeout(() => {
          setTitleCcid(timecode.titleCcid);
        }, 500);
      }
    }
  }, [timecode, titleCcid, titleCcidFromUrl]);

  useEffect(() => {
    setTimecode();
  }, [setTimecode, timecode, titleCcid, titleCcidFromUrl]);

  useEffect(() => {
    if (seriesCcid && !titleCcidFromUrl) {
      setTitleCcid("");
    }
  }, [seriesCcid, titleCcidFromUrl]);

  return (
    <Container sx={{ py: 2, marginBottom: "8rem" }}>
      <Stack direction="column" spacing={2}>
        {seriesCcid && !config.hideWarning ? (
          <Box
            sx={{
              border: 1,
              padding: 2,
              borderColor: theme.palette.java,
              borderRadius: 2,
              bgcolor: theme.palette.hummingBird,
            }}
          >
            If you'd like to see TX Masters for this series, please select a
            specific episode.
          </Box>
        ) : null}
        <Box sx={{ py: 2 }}>
          <Typography variant="h5">{config.title}</Typography>
          <Typography variant="subtitle1">{config.description}</Typography>
        </Box>

        <Stack direction="row" justifyContent="space-around" spacing={2}>
          <Card sx={{ minWidth: "25vw", minHeight: 312, p: 2 }}>
            {titlesData && !config.hideDropdown && (
              <EpisodesDropdown
                titlesData={titlesData}
                titleCcid={titleCcid as string}
                onCcidChange={(newCcid: string) => {
                  setTitleCcid(newCcid);
                  setSelectedVersionId(undefined);
                }}
                disabled={Boolean(titleCcidFromUrl)}
              />
            )}
            {config.versionSelectionDescription && (
              <Typography>{config.versionSelectionDescription}</Typography>
            )}

            {titleCcid && (
              <VersionsList
                videoMetaData={videoMetaData}
                isLoadingVersion={isLoadingVersion}
                titleCcid={titleCcid as string}
                selectedVersionId={selectedVersionId as string}
                onChange={handleVersionChange}
                timeRange={timeRange as TimeRange}
                hasFixedTimecodeLength={hasFixedTimecodeLength}
                onTimeRangeChange={handleTimeRangeChange}
                timecodeError={timecodeError}
                onError={(error: string) => {
                  setTimecodeError(error);
                }}
              />
            )}
          </Card>
          <TimeCodeVideoPlayer
            timeRange={timeRange as TimeRange}
            selectedVersionId={selectedVersionId as string}
            onError={(error: string) => {
              setTimecodeError(error);
            }}
            timecodeError={timecodeError}
          />
        </Stack>
        {hasChange && (
          <ActionBar
            cancelCallback={() => {
              if (timecode) {
                setTimecode();
              } else {
                setTimeRange(undefined);
              }

              setTimecodeError("");
              setHasChange(false);
            }}
            cancelDisabled={isUpdating}
            cancelLabel="Cancel"
            confirmLabel="Save Changes"
            confirmLoading={isUpdating}
            confirmCallback={() => {
              if (!timeRange?.startTime || !timeRange.endTime) {
                setTimecodeError(
                  "Please provide timecodes from both fields before saving.",
                );
                return;
              }

              if (timecodeError) {
                return;
              }

              mutate({
                versionCcid: selectedVersionId,
                timecode: {
                  start: timeRange?.startTime,
                  end: timeRange?.endTime,
                },
                tag: queryTag,
              });
            }}
          />
        )}
      </Stack>
    </Container>
  );
};
