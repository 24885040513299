import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { West, East } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import {
  useContributorRoles,
  useContributors,
  useContributorsFilter,
  useUpdateContributorsFilter,
} from "../../../../../hooks";
import { ContributorProps } from "../../../../../types/types";

import { BillingList, SelectableRole } from "./BillingList/BillingList";
import { noop } from "../../../../../utils/appHelper";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";

interface ExcludeRolesFromBillingProps {
  seriesCcid: string;
}

const modalWidth = "760px";

export const ExcludeRolesFromBilling: FC<ExcludeRolesFromBillingProps> = ({
  seriesCcid,
}) => {
  const queryClient = useQueryClient();
  const { data: contributorsFilter } = useContributorsFilter(seriesCcid);
  const [excludedRoles, setExcludeRoles] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { mutate, isLoading } = useUpdateContributorsFilter(
    seriesCcid,
    {
      billingsExcludedRoles: excludedRoles,
    },
    () => {
      queryClient.invalidateQueries("getFilter");
      enqueueSnackbar("Billing exclude roles has been updated successfully", {
        variant: "success",
      });
      setShowModal(false);
    },
    () => {
      enqueueSnackbar("Failed to update billing exclude roles", {
        variant: "error",
        action: SnackbarDismiss,
      });
    },
  );
  const { data: contributors } = useContributors(seriesCcid, true);
  const { data: contributorRoles } = useContributorRoles();

  const [leftSideRoles, setLeftSideRoles] = useState<SelectableRole[]>([]);
  const [rightSideRoles, setRightSideRoles] = useState<SelectableRole[]>([]);

  useEffect(() => {
    if (contributorsFilter) {
      setExcludeRoles(contributorsFilter.billingsExcludedRoles);
    }
  }, [contributorsFilter]);

  const roleContributorMap = useMemo(() => {
    const initValue: Record<string, number> = {};

    return contributors?.contributors?.reduce(
      (acc, current: ContributorProps) => {
        const role =
          typeof current.role === "string" ? current.role : current.role?.value;

        if (role) {
          if (!acc[role]) {
            acc[role] = 0;
          }

          acc[role] += 1;
        }

        return acc;
      },
      initValue,
    );
  }, [contributors]);

  const addToList = useCallback(() => {
    const extraExcludeRoles = leftSideRoles
      .filter(({ selected }) => selected)
      .map(({ role }) => role);

    const newExcludeRoles = Array.from(
      new Set([...excludedRoles, ...extraExcludeRoles]),
    );

    setExcludeRoles(newExcludeRoles);
  }, [excludedRoles, leftSideRoles]);

  const removeFromList = useCallback(() => {
    const newExcludeRoles = rightSideRoles
      .filter(({ selected }) => !selected)
      .map(({ role }) => role);
    setExcludeRoles(newExcludeRoles);
  }, [rightSideRoles]);

  const selectedRoles = useMemo(
    () =>
      (contributorRoles || [])
        .map(({ role }) => role)
        .filter((role) => !excludedRoles.includes(role)),
    [contributorRoles, excludedRoles],
  );

  return (
    <>
      <Button
        onClick={() => {
          setShowModal(true);
        }}
        color="secondary"
      >
        Billings filtering
      </Button>
      <Dialog open={showModal} onClose={noop} maxWidth="xl">
        <DialogTitle id="alert-dialog-title" sx={{ width: modalWidth }}>
          Billings filtering
          <Typography>
            Please indicate which, if any, roles you would like to be excluded
            from the EBS billings documents, for this entire series.
          </Typography>
        </DialogTitle>
        <Divider />
        <Stack>
          <DialogContent sx={{ width: modalWidth }}>
            <DialogContentText id="alert-dialog-description">
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  height: "calc(100vh - 394px)",
                  overflow: "auto",
                  paddingRight: "1rem",
                  paddingBottom: "3rem",
                }}
              >
                <BillingList
                  selectedRoles={selectedRoles}
                  allRoles={contributorRoles || []}
                  titleLabel="Roles currently included on billings documents"
                  contributorsMap={roleContributorMap || {}}
                  onChange={(roles: SelectableRole[]) => {
                    setLeftSideRoles(roles);
                  }}
                  data-testid="left-side-roles"
                />
                <Stack gap={1} flexDirection="column" alignSelf="center">
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<East />}
                    onClick={() => {
                      addToList();
                    }}
                    data-testid="addBtn"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<West />}
                    onClick={removeFromList}
                    data-testid="removeBtn"
                  />
                </Stack>
                <BillingList
                  selectedRoles={excludedRoles}
                  allRoles={contributorRoles || []}
                  titleLabel="Contributors excluded from the billings document(s)"
                  contributorsMap={roleContributorMap || {}}
                  onChange={(roles: SelectableRole[]) => {
                    setRightSideRoles(roles);
                  }}
                  data-testid="right-side-roles"
                />
              </Stack>
              <Stack flexDirection="row" justifyContent="space-between">
                <Button
                  variant="outlined"
                  color="secondary"
                  disabled={isLoading}
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  loading={isLoading}
                  onClick={() => {
                    mutate("");
                  }}
                >
                  save
                </LoadingButton>
              </Stack>
            </DialogContentText>
          </DialogContent>
        </Stack>
      </Dialog>
    </>
  );
};
