/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import { getSummary, getSummaryNew } from "../../api/spotlight";
import { Summary } from "../../containers/NotificationForm/FormStatus";

export const useRiskSummary = (
  riskId: string | undefined,
  type = "series",
): UseQueryResult<Summary, AxiosError> =>
  useQuery(["risk summary", riskId], () => getSummary(riskId as string, type), {
    enabled: Boolean(riskId),
  });

export const useRiskSummaryNew = ({
  ccid,
  version,
}: {
  ccid: string | undefined;
  version: "v2" | "v3";
}): UseQueryResult<Summary, AxiosError> =>
  useQuery(
    ["risk summary", ccid],
    () => getSummaryNew({ ccid: ccid as string, version }),
    {
      enabled: Boolean(ccid),
    },
  );
