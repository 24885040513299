import { FC } from "react";
import { Stack, Typography, Avatar } from "@mui/material";
import AssignProdLead from "./AssignProdLead";
import theme from "../../utils/theme";

const ProductionCardProdLead: FC<{
  productionLead: string | null | undefined;
  canEdit: boolean;
  ccid: string;
  type: string;
}> = ({ productionLead, canEdit, ccid, type }) => {
  if (canEdit && !productionLead) {
    return <AssignProdLead ccid={ccid} type={type} />;
  }

  const leadInitials = productionLead?.substring(0, 1).toUpperCase() || "--";

  return (
    <>
      <Typography
        variant="caption"
        color={theme.palette.text.secondary}
        letterSpacing="0.4px"
      >
        Production Lead
      </Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
          {leadInitials}
        </Avatar>

        <Typography variant="body1">
          {productionLead || "Unassigned"}
        </Typography>
      </Stack>
    </>
  );
};

export default ProductionCardProdLead;
