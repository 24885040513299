import { Typography } from "@mui/material";
import { RADIO } from "../../../components";

export const dataPrivacyFormConfig = [
  {
    sectionTitle: <Typography variant="h5">Data Privacy</Typography>,
    key: "Data Privacy",
    formFields: [
      {
        type: RADIO,
        label:
          "Will the production methods and techniques involve new or innovative technology, or any other novel approach?",
        id: "novelData",
        questionBubble: "For example: Virtual production, AI software",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: `Will you carry out covert or secret filming? `,
        id: "covertActivities",
        questionBubble:
          "For example: Filming someone without them knowing. Covert filming. Secret background research into somebody without them knowing about it. Collecting information about someone’s health or criminal record. Tracking their location or behaviour without their knowledge.",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Do the subjects know you are making a programme about them?",
        id: "subjectsAware",
        questionBubble:
          "For example: Did you try to contact them and could not get permission? Are you planning on surprising them (e.g. prize or prank) or otherwise deceiving or misleading them about the production and their involvement in what the programme is about?",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Is there a potential risk to life or other harm?",
        questionBubble:
          "For example: An endurance/survival show. Participants being exposed to difficult, risky, stressful or other compromising conditions. Living people being portrayed in a scripted drama in a way which puts them at risk in real life.",
        id: "riskOfHarm",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Is the production journalistic in nature? Do you have journalistic aims? ",
        id: "journalisticAims",
        questionBubble:
          "For example: Does the programme involve current affairs content, expose material or catch someone out in a lie or deception? Do you need to do research without alerting the person?",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Does the programme feature footage or information about a real person’s sex life, sexual orientation or love life? ",
        id: "personalRelationships",
        questionBubble:
          "For example: Dating shows or scripted dramas which portray real-life relationships.",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Does the programme feature other sensitive material or content?",
        id: "sensitiveMaterial",
        questionBubble:
          "For example: Is the programme  based on real-life events which were notorious,scandalous, tragic, painful, difficult, controversial or horrific or which focus on very private or sensitive information.",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
      {
        type: RADIO,
        label: "Does the programme feature children or vulnerable individuals?",
        questionBubble:
          "For example - non-scripted programmes about real-life or physical or mental health, filming someone when ill or being medically treated, distressed or unable to understand they are being filmed/recorded.",
        id: "childrenFeatured",
        value: undefined,
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not yet known",
            value: "Not yet known",
          },
        ],
      },
    ],
  },
];
