import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import theme from "../../../utils/theme";
import { NotificationFormFieldProps } from "../../../types/types";
import { UseUpdateRiskArgs } from "../../../hooks";

interface Props {
  label: string;
  error: string | undefined;
  shouldDisplayErrors: boolean;
  handleChange: (selectedValues: Array<string>) => void;
  isRequired: boolean;
  value: Array<string> | undefined;
  options: Array<{ label: string; value: string }>;
  autosaveForm: (formData: UseUpdateRiskArgs["formData"]) => void;
}

type CheckboxFieldProps = NotificationFormFieldProps<Props>;

export function CheckboxField({
  fieldInfo,
  label,
  error,
  shouldDisplayErrors,
  handleChange,
  isRequired,
  value,
  options,
  autosaveForm,
}: CheckboxFieldProps) {
  const selectedValues = value || [];
  const inputId = `${fieldInfo.sectionId}.${fieldInfo.fieldName}`;

  return (
    <FormControl required={isRequired} component="fieldset">
      <FormLabel
        component="legend"
        sx={{
          marginBottom: "16px",
          "& .MuiFormLabel-asterisk": {
            color: theme.palette.error.main,
          },
        }}
      >
        {label}
      </FormLabel>

      <FormGroup
        aria-describedby={`${inputId}-error`}
        sx={{ width: "80%", paddingLeft: "24px" }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            value={option.value}
            sx={{ marginBottom: "8px" }}
            control={
              <Checkbox
                checked={selectedValues.includes(option.value)}
                onChange={(_, checked) => {
                  const newValues = checked
                    ? selectedValues.slice().concat(option.value)
                    : selectedValues.filter((val) => val !== option.value);

                  handleChange(newValues);

                  autosaveForm({
                    [fieldInfo.sectionId]: { [fieldInfo.fieldName]: newValues },
                  });

                  if (checked) {
                    const newValues = selectedValues
                      .slice()
                      .concat(option.value);

                    handleChange(newValues);
                  } else {
                    handleChange(
                      selectedValues.filter((val) => val !== option.value),
                    );
                  }
                }}
              />
            }
          />
        ))}
      </FormGroup>

      {shouldDisplayErrors && error ? (
        <FormHelperText id={`${inputId}-error`} error={true} sx={{ margin: 0 }}>
          {error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}
