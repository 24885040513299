import { PersonOutline, MailOutline } from "@mui/icons-material";
import {
  TEXT,
  DATE,
  RADIO,
  SELECT,
  TEXTAREA,
  // GROUP_CHECKBOXES,
  getEmptyField,
} from "../../../components/DynamicForm";

// eslint-disable-next-line
const emailRegex = new RegExp("[a-z0-9]+@[a-z0-9]+.[a-z]{2,3}");

export const basicFormConfig = [
  {
    sectionTitle: "Basic Information",
    key: "Basic Information",
    description:
      "This information includes rudimentary and technical information regarding your production",
    formFields: [
      {
        type: TEXTAREA,
        label: "Series narrative",
        id: "narrative",
        value: undefined,
        placeholder: "Please write a brief description of the production here",
        columnCount: 12,
        required: true,
        disabled: false,
      },
      {
        type: SELECT,
        label: "Production status",
        id: "productionStatus",
        value: undefined,
        placeholder: "Please select an option",
        columnCount: 6,
        required: true,
        disabled: false,
        options: [],
      },
      getEmptyField(),
      {
        type: RADIO,
        label: "Production Type",
        id: "productionType",
        value: "showForBroadcast",
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Show for broadcast",
            value: "Broadcast",
          },
          {
            label: "Development/pilot/taster show/sizzle",
            value: "Sizzle",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
      },
      {
        type: TEXT,
        label: "Other Production Type",
        id: "otherProductionType",
        value: "",
        columnCount: 12,
        required: true,
        disabled: false,
        visibleDependencies: {
          productionType: "Other",
        },
      },
      {
        type: SELECT,
        label: "Broadcaster / Network Name",
        id: "broadcaster",
        value: undefined,
        placeholder: "Please select an option",
        columnCount: 6,
        required: true,
        disabled: false,
        options: [],
      },
      getEmptyField(),
      {
        type: TEXT,
        label: "Other Broadcaster",
        id: "otherBroadcaster",
        columnCount: 6,
        required: true,
        disabled: false,
        visibleDependencies: {
          broadcaster: "Other",
        },
      },
      {
        type: RADIO,
        label: "Does the Network require Disgrace cover for key individuals?",
        id: "disgraceCover",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: "Yes",
          },
          {
            label: "No",
            value: "No",
          },
          {
            label: "Not Yet Known",
            value: "TBC",
          },
        ],
      },
      {
        type: TEXT,
        label: "Please provide details on the Disgrace Cover:",
        id: "disgraceDetails",
        columnCount: 6,
        required: true,
        disabled: false,
        visibleDependencies: {
          disgraceCover: "Yes",
        },
      },
      getEmptyField(),
      {
        type: RADIO,
        label: "Is this show scripted or non-scripted?",
        id: "contentType",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Scripted",
            value: "Scripted",
          },
          {
            label: "Non-scripted",
            value: "Non-Scripted",
          },
        ],
      },
      {
        type: RADIO,
        label: "Is this show prerecorded or live?",
        id: "recordingType",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: false,
        disabled: false,
        options: [
          {
            label: "Pre-recorded",
            value: "PreRecorded",
          },
          {
            label: "Live",
            value: "Live",
          },
        ],
      },
      {
        type: RADIO,
        label:
          "Will any production be taking place outside of your base location?",
        id: "outsideLocation",
        value: undefined,
        placeholder: "please select",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      // getEmptyField(),
      {
        type: SELECT,
        label: "Currency",
        id: "currency",
        optionsMapTo: "currencies",
        value: {
          label: "GBP (United Kingdom Pound)",
          value: "GBP (United Kingdom Pound)",
        },
        placeholder: "Please select an option",
        columnCount: 6,
        required: true,
        disabled: false,
        options: [],
      },
      {
        type: TEXT,
        inputType: "number",
        label: "Budget",
        id: "budget",
        helperText:
          "Please provide a best estimate of the total budget for the series or feature. If the budget is in multiple currencies, please convert all to the currency of the main budget.",
        columnCount: 5,
        required: true,
        disabled: false,
      },
      {
        type: RADIO,
        label:
          "Are you setting up a Special Purpose Vehicle/Entity (SPV/SPE) for this production?",
        id: "specialPurposeVehicle",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "Yes",
            value: true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
      {
        type: TEXT,
        label: "Special purpose vehicle name",
        id: "specialPurposeVehicleName",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          specialPurposeVehicle: true,
        },
      },
      {
        type: SELECT,
        label: "Cast Number",
        id: "castEstimate",
        placeholder: "Please select an option",
        columnCount: 6,
        required: true,
        disabled: false,
        options: [
          {
            label: "1 - 9",
            value: "OneToNine",
          },
          {
            label: "10 - 19",
            value: "TenToNineteen",
          },
          {
            label: "20 - 29",
            value: "TwentyToTwentyNine",
          },
          {
            label: "30 or more",
            value: "ThirtyOrMore",
          },
        ],
      },
      {
        type: SELECT,
        label: "Crew Number",
        id: "crewEstimate",
        placeholder: "Please select an option",
        columnCount: 6,
        required: true,
        disabled: false,
        options: [
          {
            label: "1 - 49",
            value: "OneToFortyNine",
          },
          {
            label: "50 - 99",
            value: "FiftyToNinetyNine",
          },
          {
            label: "100 - 149",
            value: "HundredToHundredFortyNine",
          },
          {
            label: "150 or more",
            value: "HundredFiftyOrMore",
          },
        ],
      },
      {
        type: RADIO,
        label: "Which insurance cover will you be using?",
        id: "insuranceCover",
        columnCount: 12,
        required: true,
        disabled: false,
        options: [
          {
            label: "ITV",
            value: "ITV",
          },
          {
            label: "Broadcaster/Network",
            value: "Broadcaster",
          },
          {
            label: "TBC",
            value: "TBC",
          },
          {
            label: "Other",
            value: "Other",
          },
        ],
      },
      {
        type: TEXT,
        label: "Other Insurance Cover",
        id: "otherInsuranceCover",
        columnCount: 12,
        required: false,
        disabled: false,
        visibleDependencies: {
          insuranceCover: "Other",
        },
      },
    ],
  },
  {
    sectionTitle: "Production Team Details",
    key: "Production Team Details",
    description: "Who is the core team working on this production?",
    formFields: [
      {
        type: TEXT,
        label: "Production contact name",
        id: "productionContact",
        placeholder: "Forename Surname",
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <PersonOutline />,
      },
      {
        type: TEXT,
        label: "Production contact email",
        id: "productionContactEmail",
        placeholder: "example@itv.com",
        validationRegex: emailRegex,
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <MailOutline />,
      },
      // {
      //   type: TEXT,
      //   label: "Production contact number",
      //   id: "productionContactNumber",
      //   placeholder: "+44",
      //   value: undefined,
      //   columnCount: 4,
      //   required: true,
      //   disabled: false,
      //   icon: <PhoneOutlined />,
      // },
      {
        type: TEXT,
        label: "Production creative lead name",
        id: "creativeLead",
        placeholder: "Forename Surname",
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <PersonOutline />,
      },
      {
        type: TEXT,
        label: "Production creative lead email",
        id: "creativeLeadEmail",
        placeholder: "example@itv.com",
        validationRegex: emailRegex,
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
        icon: <MailOutline />,
      },
      // getEmptyField(4),
    ],
  },
  {
    sectionTitle: "Dates",
    description:
      "When are the most important phases of production scheduled to be?",
    key: "When are the most important phases of production scheduled to be?",
    formFields: [
      {
        type: DATE,
        label: "Pre-production",
        id: "preProductionDate",
        placeholder: "DD/MM/YYYY",
        value: undefined,
        columnCount: 6,
        required: true,
        disabled: false,
      },
      {
        type: DATE,
        label: "Principal start",
        id: "principalStartDate",
        value: undefined,
        columnCount: 6,
        placeholder: "DD/MM/YYYY",
        required: true,
        disabled: false,
      },
      {
        type: DATE,
        label: "Principal finish",
        id: "principalFinishDate",
        value: undefined,
        columnCount: 6,
        placeholder: "DD/MM/YYYY",
        required: true,
        disabled: false,
      },
      {
        type: DATE,
        label: "Start post",
        id: "startPostDate",
        value: undefined,
        columnCount: 6,
        placeholder: "DD/MM/YYYY",
        required: true,
        disabled: false,
      },
      {
        type: DATE,
        label: "Production close",
        id: "productionCloseDate",
        value: undefined,
        columnCount: 6,
        placeholder: "DD/MM/YYYY",
        required: true,
        disabled: false,
      },
    ],
  },
];
