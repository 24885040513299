/* eslint-disable @typescript-eslint/no-explicit-any */
import { HelpOutline } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";

const incorrectImageFormat =
  "Incorrect file type, please note you can only upload .jpeg,.jpg,.png,.tiff,.ai,.psd,or .eps file type";
const incorrectFontFormat =
  "Incorrect file type, please note you can only upload .ttf,.otf,.fnt";
const incorrectScriptFormat =
  "Incorrect file type, please note you can only upload .doc, .docx";
const imageFormats =
  'image/jpeg,image/png,image/tiff,image/webp,image/x-eps,application/illustrator",application/postscript,image/vnd.adobe.photoshop,image/svg+xml,application/vnd.3gpp.pic-bw-small,.psb,.psd,.ttf,.otf,.fnt';
const fontFormats = ".ttf,.otf,.fnt,.woff2";
const scriptFormats = ".doc, .docx";

const deliverableGuidelines = (
  <>
    <p>
      {" "}
      More information regarding deliverable guidelines here:{" "}
      <a
        href="https://www.itv.com/commissioning/articles/itv-deliverables"
        target="_blank"
        rel="noreferrer"
      >
        &nbsp;ITV Deliverables
      </a>
      <br />
      and photography guidelines here:{" "}
      <a
        href="https://downloads.ctfassets.net/870j9u9p7ed7/2uTUF1e6KYGsD7WlTEZ5IS/60e2f02bec7daba2521314c001bdcd5e/ITVX_Photography_Guidelines_5.7.pdf"
        target="_blank"
        rel="noreferrer"
      >
        ITVX Photography Guidelines 5.7
      </a>
    </p>
  </>
);

export const uploadConfig: any = {
  scripts: {
    headerTitle: "Upload script(s)",
    acceptFormat: scriptFormats,
    breadCrumbTitle: "Uploading script(s)",
    formatErrorMessage: incorrectScriptFormat,
    titleCopy1: "Scripts",
    descriptionCopy1: (
      <div>
        Scripts should be sent in a .doc format defined in the{" "}
        <a
          href="https://docs.google.com/document/d/1RKM1UcklYTlNDDKgz4EfzhxYch_ELFwlMNlMJU7cCpo"
          target="_new"
        >
          ITV Access Services Script Specification
        </a>
      </div>
    ),
    titleCopy2: "",
    descriptionCopy2: "",
    viewTitle: "scripts",
    imageTag: "script",
    level: "titles",
    disableView: true,
    viewAndDownloadCopy:
      "You can remove, download, and edit information for each scripts below.",
  },
  logoSeries: {
    headerTitle: "Upload series logo(s)",
    acceptFormat: imageFormats,
    breadCrumbTitle: "Uploading logos",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Series Logo",
    descriptionCopy1:
      "For all programme series you are required to deliver the following:",
    titleCopy2: "1 x Title/Programme logo",
    descriptionCopy2:
      "Preferably .ai Vector file or otherwise a layered psd/transparent PNG. Please name the file with programme title and series number where applicable and any additional information in the case of multiple versions eg transparent/white/main titles.",
    viewTitle: "series logo(s)",
    imageTag: "logo",
    level: "series",
  },
  fontSeries: {
    type: "font",
    acceptFormat: fontFormats,
    headerTitle: "Upload series primary font",
    breadCrumbTitle: "Uploading fonts",
    formatErrorMessage: incorrectFontFormat,
    titleCopy1: "Series Fonts",
    descriptionCopy1:
      "For all programme series you are required to deliver the following:",
    titleCopy2: "Primary font",
    descriptionCopy2:
      "Based on logo or subheading (NB font must be fully cleared for all marketing and publicity use in perpetuity without additional payment by ITV) To be delivered ideally 14 days prior to and no later than contractual delivery date.",
    viewTitle: "series primary font",
    imageTag: "font",
    level: "series",
    disableView: true,
  },
  logoOneOff: {
    headerTitle: "Upload programme logo(s)",
    acceptFormat: imageFormats,
    breadCrumbTitle: "Uploading programme logo(s)",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Programme logo(s)",
    descriptionCopy1:
      "For this programme you are required to deliver the following:",
    titleCopy2: "1 x Title/Programme logo ",
    descriptionCopy2: (
      <div>
        <div>
          Preferably .ai Vector file or otherwise a layered psd /transparent
          PNG.
        </div>
        <div>
          Please name the file with programme title and series number where
          applicable and any additional information in the case of multiple
          versions eg transparent/white/main titles.
        </div>
      </div>
    ),
    viewTitle: "programme logo(s)",
    imageTag: "logo",
    level: "titles",
  },
  fontOneOff: {
    headerTitle: "Upload programme primary font",
    acceptFormat: fontFormats,
    type: "font",
    breadCrumbTitle: "Uploading programme primary font",
    formatErrorMessage: incorrectFontFormat,
    titleCopy1: "Programme font(s)",
    descriptionCopy1:
      "For this programme you are required to deliver the following:",
    titleCopy2: "1 x Title/Programme primary font",
    descriptionCopy2: (
      <div>
        <div>
          Based on logo or subheading (NB font must be fully cleared for all
          marketing and publicity use in perpetuity without additional payment
          by ITV)
        </div>
        <div>
          To be delivered ideally 14 days prior to and no later than contractual
          delivery date.
        </div>
      </div>
    ),
    viewTitle: "programme primary font",
    imageTag: "font",
    level: "titles",
    disableView: true,
  },
  iconicKeyArtSeries: {
    headerTitle: "Upload Iconic Key Art - Series Image(s)",
    breadCrumbTitle: "Uploading Iconic Key Art - Series Image(s)",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Iconic Series Image(s)",
    descriptionCopy1:
      "For all episodes within this programme series you are required to deliver the following:",
    titleCopy2: "At least 1 iconic image that best represents the whole series",
    descriptionCopy2: (
      <div>
        <p>
          This image, to be delivered in both landscape and portrait
          orientations, should best represent the whole series for an ITV
          Audience. If this image is a digital artwork then a layered file must
          be provided.
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "iconic key art - series image(s)",
    imageTag: "keyArtImage",
    level: "series",
  },
  iconicKeyArtOneOff: {
    headerTitle: "Upload ‘Iconic Key Art - Programme’ Image(s)",
    breadCrumbTitle: "Uploading ‘Iconic Key Art - Programme’ Image(s)",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Iconic programme image(s)",
    descriptionCopy1:
      "For this programme you are required to deliver the following:",
    titleCopy2: "1+ x Iconic programme image(s)",
    descriptionCopy2: (
      <div>
        <p>
          This image, to be delivered in both landscape and portrait
          orientations, should best represent the whole series for an ITV
          Audience. If this image is a digital artwork then a layered file must
          be provided.
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "‘Iconic Key Art - Programme’ Image(s)",
    imageTag: "keyArtImage",
    level: "titles",
  },
  iconicKeyArtSeriesGallery: {
    headerTitle: "Upload Iconic Key Art - Series Gallery Image(s)",
    breadCrumbTitle: "Upload Iconic Key Art - Series Gallery Image(s)",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Iconic Series Gallery image(s)",
    descriptionCopy1:
      "For all episodes within this programme series you are required to deliver the following:",
    titleCopy2: "A minimum of 10 frames per person.",
    descriptionCopy2: (
      <div>
        <p>
          Gallery or special shots that are generic in nature of all key
          Presenters/Contributors on their relevant sets/locations
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "iconic key art - series gallery image(s)",
    imageTag: "keyArtContributorsOnSetGallery",
    level: "series",
  },
  iconicKeyArtOneOffGallery: {
    headerTitle: "Upload ‘Iconic Key Art - Programme Gallery’ image(s)",
    breadCrumbTitle: "Uploading ‘Iconic Key Art - Programme Gallery’ image(s)",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Iconic programme gallery images",
    descriptionCopy1:
      "For this programme series you are required to deliver the following:",
    titleCopy2: "A minimum of 10 frames per person.",
    descriptionCopy2: (
      <div>
        <p>
          Gallery or special shots that are generic in nature of all key
          Presenters/Contributors on their relevant sets/locations
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "‘Iconic Key Art - Programme Gallery’ image(s)",
    imageTag: "keyArtContributorsOnSetGallery",
    level: "titles",
  },
  iconicKeyArtSeriesPresenterContributor: {
    headerTitle: "Upload Iconic Key Art - Key Presenters/Contributors",
    breadCrumbTitle: "Uploading Iconic Key Art - Key Presenters/Contributors",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Iconic Key Art - Key Presenters/Contributors",
    descriptionCopy1:
      "For all programme series you are required to deliver the following:",
    titleCopy2: "A minimum of 10 frames per person.",
    descriptionCopy2: (
      <div>
        <p>
          A range of set-up shots of all of the key Presenters/Contributors
          (single portraits and full length shots in costume and make up)
          against a plain background to allow later art-working
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "iconic key art - key presenters/contributors",
    imageTag: "keyArtContributorsSetUp",
    level: "series",
  },
  iconicKeyArtOneOffPresenterContributor: {
    headerTitle: "Upload ‘Iconic Key Art - Key Presenters/Contributors’ images",
    breadCrumbTitle:
      "Uploading ‘Iconic Key Art - Key Presenters/Contributors’ images",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Iconic Key Art - Key presenters/contributors",
    descriptionCopy1:
      "For all programme series you are required to deliver the following:",
    titleCopy2: "A minimum of 10 frames per person.",
    descriptionCopy2: (
      <div>
        <p>
          A range of set-up shots of all of the key Presenters/Contributors
          (single portraits and full length shots in costume and make up)
          against a plain background to allow later art-working
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "‘Iconic Key Art - Key Presenters/Contributors’ images",
    imageTag: "keyArtContributorsSetUp",
    level: "titles",
  },
  oneOffFeatureImage: {
    breadCrumbTitle: "Uploading images",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Series images",
    descriptionCopy1:
      "For all programme series you are required to deliver the following:",
    titleCopy2: "1 x Title/Programme images",
    descriptionCopy2:
      "Preferably .ai Vector file or otherwise a layered psd/transparent PNG. Please name the file with programme title and series number where applicable and any additional information in the case of multiple versions eg transparent/white/main titles.",
    imageTag: "unitStills",
    level: "titles",
  },
  oneOffBehindScenes: {
    headerTitle: "Upload behind the scenes images",
    breadCrumbTitle: "Uploading behind the scenes images",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Behind the scenes images",
    descriptionCopy1: "5-30 x Behind the scenes images",
    titleCopy2: "1 x Title/Programme images",
    descriptionCopy2: (
      <div>
        <p>
          These are images showing key cast and production crew across all
          episodes:
          <ul>
            <li>
              Artists in hair and make-up (especially if
              prosthetic/costume/disguise are being used).
            </li>
            <li>
              Clean (ie not featuring cast) back-plates of key locations/sets
              for later art-working
            </li>
          </ul>
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "behind the scenes images",
    imageTag: "behindTheScenes",
    level: "titles",
  },
  episodic: {
    headerTitle: "Upload episodic images",
    breadCrumbTitle: "Uploading episodic images",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: (
      <div>
        Episodic images
        <Tooltip
          title="key contact for logo queries: Partnerships & Distribution picture Desk paypicture@itv.com"
          placement="top"
        >
          <Button size="small">
            <HelpOutline
              aria-label="Help icon"
              // eslint-disable-next-line jsx-a11y/aria-role
              role="graphics-symbol"
              color="primary"
            />
          </Button>
        </Tooltip>
      </div>
    ),
    descriptionCopy1:
      "For all episodes within this programme series you are required to deliver the following:",
    titleCopy2: (
      <div>
        10-30 x Episodic stills
        <Tooltip
          title="key contact for logo queries: Partnerships & Distribution picture Desk paypicture@itv.com"
          placement="top"
        >
          <Button size="small">
            <HelpOutline
              aria-label="Help icon"
              // eslint-disable-next-line jsx-a11y/aria-role
              role="graphics-symbol"
              color="primary"
            />
          </Button>
        </Tooltip>
      </div>
    ),
    descriptionCopy2: (
      <div>
        <p>
          Images you provide are needed for a range of key purposes and places -
          on ITV’s VOD services and third-party platforms, ITV channels, devices
          and for press print and digital use - and are therefore essential in
          promoting and driving viewing to your shows. Professional photography
          is required.
        </p>
        {deliverableGuidelines}
      </div>
    ),
    imageTag: "unitStills",
    level: "titles",
  },
  episodicBehindScenes: {
    headerTitle: "Upload episodic behind scenes images",
    breadCrumbTitle: "Uploading episodic behind scenes images",
    formatErrorMessage: incorrectImageFormat,
    titleCopy1: "Behind the scenes images",
    descriptionCopy1:
      "For all episodes within this programme series you are required to deliver the following:",
    titleCopy2: "5-30 x Behind the scenes images",
    descriptionCopy2: (
      <div>
        <p>
          These are images showing key cast and production crew across all
          episodes:
          <ul>
            <li>
              Artists in hair and make-up (especially if
              prosthetic/costume/disguise are being used).
            </li>
            <li>
              Clean (ie not featuring cast) back-plates of key locations/sets
              for later art-working
            </li>
          </ul>
        </p>
        {deliverableGuidelines}
      </div>
    ),
    viewTitle: "episodic behind scenes images",
    imageTag: "behindTheScenes",
    level: "titles",
  },
};
