import { IMeta, IFileWithMeta } from "react-dropzone-uploader";

export interface MetaProps extends IMeta {
  embargoDateTime?: Date;
  thumbnailUri?: string;
  errorOnThumbnail?: boolean;
  contentType?: string;
}
export interface FilesWithMetaProps extends IFileWithMeta {
  fileUploadId?: string;
  meta: MetaProps;
  isSelected?: boolean;
  errorOnUpload?: boolean;
}

export interface FilesWithMetaInitialState {
  filesWithMetaData: FilesWithMetaProps[];
}

export type FilesWithMetaAction =
  | { type: "GET_FILES"; payload: FilesWithMetaProps }
  | { type: "REMOVE_FILES"; payload: string[] }
  | { type: "SET_TO_INITAL_STATE" }
  | { type: "SET_EMBARGO_DATE"; payload: Date }
  | { type: "REMOVE_EMBARGO_DATE" }
  | { type: "TOGGLE_SELECT_FILE"; payload?: string }
  | { type: "TOGGLE_SELECT_ALL_FILES"; payload: "select" | "remove" }
  | {
      type: "SET_FILE_UPLOAD_ID";
      payload: { fileId: string; fileUploadId: string };
    }
  | {
      type: "SET_THUMBNAIL_URL";
      payload: { fileId: string; thumbnailUri: string };
    }
  | {
      type: "SET_THUMBNAIL_ERROR";
      payload: { fileId: string };
    };

export const filesWithMetaDataReducer = (
  state: FilesWithMetaInitialState,
  action: FilesWithMetaAction,
): FilesWithMetaInitialState => {
  switch (action.type) {
    case "GET_FILES": {
      const fileIndex = state.filesWithMetaData.findIndex(
        (f) => f.meta.id === action.payload.meta.id,
      );
      return {
        ...state,
        filesWithMetaData:
          fileIndex > -1
            ? [
                ...state.filesWithMetaData.slice(0, fileIndex),
                action.payload,
                ...state.filesWithMetaData.slice(fileIndex + 1),
              ]
            : [...state.filesWithMetaData, action.payload],
      };
    }
    case "REMOVE_FILES":
      return {
        ...state,
        filesWithMetaData: state.filesWithMetaData.filter(
          (f) => !action.payload.includes(f.meta.id),
        ),
      };
    case "SET_TO_INITAL_STATE":
      return {
        ...state,
        filesWithMetaData: [],
      };
    case "TOGGLE_SELECT_FILE":
      return {
        ...state,
        filesWithMetaData: state.filesWithMetaData.map((f) => {
          if (f.meta.id === action.payload) {
            return {
              ...f,
              isSelected: !f.isSelected,
            };
          }
          return f;
        }),
      };
    case "TOGGLE_SELECT_ALL_FILES":
      return {
        ...state,
        filesWithMetaData: state.filesWithMetaData.map((f) => ({
          ...f,
          isSelected: action.payload === "select",
        })),
      };
    case "SET_EMBARGO_DATE":
      return {
        ...state,
        filesWithMetaData: state.filesWithMetaData.map((f) => {
          if (f.isSelected) {
            return {
              ...f,
              isSelected: false,
              meta: {
                ...f.meta,
                embargoDateTime: action.payload,
              },
            };
          }
          return f;
        }),
      };
    case "REMOVE_EMBARGO_DATE":
      return {
        ...state,
        filesWithMetaData: state.filesWithMetaData.map((f) => {
          if (f.isSelected) {
            return {
              ...f,
              isSelected: false,
              meta: {
                ...f.meta,
                embargoDateTime: undefined,
              },
            };
          }
          return f;
        }),
      };
    case "SET_THUMBNAIL_URL": {
      return {
        ...state,
        filesWithMetaData: state.filesWithMetaData.map((f) => {
          if (f.meta.id === action.payload.fileId) {
            return {
              ...f,
              meta: {
                ...f.meta,
                thumbnailUri: action.payload.thumbnailUri,
              },
            };
          }
          return f;
        }),
      };
    }
    case "SET_THUMBNAIL_ERROR": {
      return {
        ...state,
        filesWithMetaData: state.filesWithMetaData.map((f) => {
          if (f.meta.id === action.payload.fileId) {
            return {
              ...f,
              meta: {
                ...f.meta,
                errorOnThumbnail: true,
              },
            };
          }
          return f;
        }),
      };
    }
    default:
      return state;
  }
};
