/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useQueryClient } from "react-query";

import { useNavigate, useParams } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { FormHeader, SideNav } from "../../../../../components";
import SynopsisGroup from "../../Synopsis/SynopsisGroup";
import Contributors from "../../Contributors";
import {
  NotificationService,
  SYNOPSIS_CHANGE,
  GO_TO_NEXT_FROM_SYNOPSIS,
  CONTRIBUTORS_CHANGE,
} from "../../../../../services";
import { ContributorUpdateProps } from "../../../../../types/types";
import {
  useUpdateSynopsis,
  useUpdateContributors,
} from "../../../../../hooks/mutations";
import { clone, objHasData } from "../../../../../utils/appHelper";
import { cleanContributors } from "../../Contributors/contributor.helper";
import { useContributors, useSynopsis } from "../../../../../hooks";
import {
  getContributorItemStatus,
  getSynopsisItemStatus,
} from "./Billings.helper";
import { SnackbarDismiss } from "../../../../../componentsV2/SnackBarDismiss";

const groupMenuSetting = [
  {
    label: "Synopsis",
    id: "synopsis",
  },
  {
    label: "Contributors",
    id: "contributors",
  },
];

const tabMap: Record<string, any> = {
  synopsis: SynopsisGroup,
  contributors: Contributors,
};

export const Billings: FC = () => {
  const navigate = useNavigate();
  const { programmeCcid, seriesCcid, titleCcid } = useParams<string>();

  const activeCcid = titleCcid || seriesCcid || programmeCcid;
  const brandsOrSeriesUrl = programmeCcid === activeCcid ? "brands" : "series";
  const synopsisUrlLevel =
    titleCcid === activeCcid ? "titles" : brandsOrSeriesUrl;

  const formContainer = useRef<HTMLDivElement>();

  const { data: contributorData, isLoading: isContributorsDataLoading } =
    useContributors(titleCcid);
  const { data: synopsisData, isLoading: isLoadingSynopsisData } = useSynopsis(
    activeCcid,
    synopsisUrlLevel,
  );

  const [currentTab, setCurrentTab] = useState("synopsis");
  const [step, setStep] = useState<number>(1);

  const [synopsisValues, setSynopsisValues] = useState<Record<string, any>>({
    synopsisShort: "",
    synopsisMedium: "",
    synopsisLong: "",
  });

  const [latestSynopsisValues, setLatestSynopsisValues] =
    useState<Record<string, string>>();
  const [latestContributors, setLatestContributors] = useState();
  const [synopsisChanged, setSynopsisChanged] = useState<boolean>(false);

  const [contributors, setContributors] = useState<ContributorUpdateProps[]>();
  const [contributorsChanged, setContributorsChanged] =
    useState<boolean>(false);

  const submitButtonDisabled = useMemo(
    () => !synopsisChanged && !contributorsChanged,
    [synopsisChanged, contributorsChanged],
  );
  const mutationTriggeredBySubmitButton = useRef<boolean>(false);

  const queryClient = useQueryClient();

  const {
    mutate: saveSynopsis,
    isLoading: savingSynopsis,
    isSuccess: isSaveSynopsisSuccess,
  } = useUpdateSynopsis(
    synopsisUrlLevel,
    activeCcid as string,
    () => {
      queryClient.invalidateQueries(synopsisUrlLevel);
      setTimeout(() => {
        setSynopsisChanged(false);
      }, 500);
      enqueueSnackbar("Billings data has been saved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("Error saving synopsis", {
        variant: "error",
        action: SnackbarDismiss,
      });
    },
  );

  const {
    mutate: saveContributors,
    isLoading: savingContributors,
    isSuccess: isSaveContributorsSuccess,
  } = useUpdateContributors(
    activeCcid as string,
    cleanContributors(clone(contributorData?.contributors || [])),
    cleanContributors(contributors as ContributorUpdateProps[], true),
    () => {
      queryClient.invalidateQueries("getContributors");
      setTimeout(() => {
        setContributorsChanged(false);
      }, 500);
      enqueueSnackbar("Billings data has been saved", { variant: "success" });
    },
    () => {
      enqueueSnackbar("Error saving synopsis", {
        variant: "error",
        action: SnackbarDismiss,
      });
    },
  );

  const locationChangeHandler = useCallback(() => {
    mutationTriggeredBySubmitButton.current = false;
  }, []);

  const navigateToTaskPage = useCallback(() => {
    let url = "";

    if (programmeCcid) {
      url = `/programmes/${programmeCcid}`;
      if (seriesCcid) url = `${url}/series/${seriesCcid}`;
      if (titleCcid) url = `${url}/titles/${titleCcid}`;
    } else url = `/special/${titleCcid}`;

    navigate(url);
  }, [navigate, programmeCcid, seriesCcid, titleCcid]);

  useEffect(
    () => () => {
      locationChangeHandler();
    },
    [locationChangeHandler],
  );

  useEffect(() => {
    setStep(groupMenuSetting.findIndex(({ id }) => id === currentTab) + 1);
  }, [currentTab]);

  useEffect(() => {
    if (synopsisData) {
      setLatestSynopsisValues(synopsisData as any);
    }
  }, [synopsisData]);

  useEffect(() => {
    if (contributorData) {
      setLatestContributors(contributorData as any);
      setContributors(
        (contributorData?.contributors || []) as ContributorUpdateProps[],
      );
    }
  }, [contributorData]);

  useEffect(() => {
    let finished = true;

    if (synopsisChanged && isSaveSynopsisSuccess) {
      finished = finished && isSaveSynopsisSuccess;
    }

    if (contributorsChanged && isSaveContributorsSuccess) {
      finished = finished && isSaveContributorsSuccess;
    }

    if (finished && mutationTriggeredBySubmitButton.current) {
      navigateToTaskPage();
    }
  }, [
    contributorsChanged,
    isSaveContributorsSuccess,
    isSaveSynopsisSuccess,
    navigateToTaskPage,
    synopsisChanged,
  ]);

  useEffect(() => {
    const handleSynopsisChange = ({ synopsisValues: data }: any) => {
      setSynopsisValues(data);
      setSynopsisChanged(true);
    };

    const handleGoToNextFromSynopsis = () => {
      setCurrentTab(groupMenuSetting[1].id);
    };

    const handleContributorsChange = ({
      contributors: newContributors,
    }: any) => {
      setContributors(newContributors);
      setContributorsChanged(true);
    };

    NotificationService.sub(SYNOPSIS_CHANGE, handleSynopsisChange);

    NotificationService.sub(
      GO_TO_NEXT_FROM_SYNOPSIS,
      handleGoToNextFromSynopsis,
    );
    NotificationService.sub(CONTRIBUTORS_CHANGE, handleContributorsChange);

    return () => {
      NotificationService.destroy([
        SYNOPSIS_CHANGE,
        GO_TO_NEXT_FROM_SYNOPSIS,
        CONTRIBUTORS_CHANGE,
      ]);
    };
  }, []);

  const submitFormHandler = useCallback(() => {
    mutationTriggeredBySubmitButton.current = true;

    // check if there is error in the form
    const errorRef: HTMLDivElement | null =
      document.querySelector(".Mui-error");

    if (formContainer.current && errorRef) {
      const boundClientRect = errorRef.getBoundingClientRect();

      formContainer.current.scrollTop =
        boundClientRect.top - formContainer.current.offsetTop;

      return;
    }

    if (synopsisChanged) {
      saveSynopsis({
        synopsisShort: synopsisValues.synopsisShort.trim(),
        synopsisMedium: synopsisValues.synopsisMedium.trim(),
        synopsisLong: synopsisValues.synopsisLong.trim(),
      });
    }

    if (contributorsChanged) {
      saveContributors({});
    }
  }, [
    contributorsChanged,
    saveContributors,
    saveSynopsis,
    synopsisChanged,
    synopsisValues.synopsisLong,
    synopsisValues.synopsisMedium,
    synopsisValues.synopsisShort,
  ]);

  const itemClickHandler = useCallback(
    (id: React.SetStateAction<string>) => {
      if (currentTab === id) {
        return;
      }

      setCurrentTab(id);

      if (formContainer.current) {
        formContainer.current.scrollTop = 0;
      }

      if (id === "contributors") {
        if (synopsisChanged) {
          setLatestSynopsisValues({
            ...(synopsisData as any),
            ...synopsisValues,
          });
        }
      } else if (contributorsChanged) {
        setLatestContributors({ ...(contributorData as any), contributors });
      }
    },
    [
      currentTab,
      contributorsChanged,
      synopsisChanged,
      synopsisData,
      synopsisValues,
      contributorData,
      contributors,
    ],
  );

  const synopsisItemStatus = useMemo(
    () => getSynopsisItemStatus(latestSynopsisValues || {}),
    [latestSynopsisValues],
  );
  const contributorItemStatus = useMemo(
    () => getContributorItemStatus(contributors || []),
    [contributors],
  );

  const getStepsItems = useCallback(() => {
    const items = [
      {
        ...groupMenuSetting[0],
        isActive: currentTab === groupMenuSetting[0].id,
        status: synopsisItemStatus,
      },
      {
        ...groupMenuSetting[1],
        isActive: currentTab === groupMenuSetting[1].id,
        status: contributorItemStatus,
      },
    ];

    return items;
  }, [contributorItemStatus, currentTab, synopsisItemStatus]);

  const TabComponent = tabMap[currentTab];

  return (
    <>
      <FormHeader formTitle="Billings" showFormStatus={false} />
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 192px)",
          overflowY: "auto",
        }}
        ref={formContainer}
      >
        <Container fixed disableGutters>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <SideNav
                formData={{
                  synopsisValues,
                  contributors,
                }}
                groupMenuSetting={groupMenuSetting}
                onStepChange={itemClickHandler}
                activeStep={currentTab}
                onSubmitForm={submitFormHandler}
                submitButtonDisabled={submitButtonDisabled}
                isSubmitting={savingSynopsis || savingContributors}
                getStepsItems={getStepsItems}
              />
            </Grid>
            <Grid item xs={9}>
              <Box>
                <Container>
                  <Typography variant="h5">
                    Section {step} of {groupMenuSetting.length}:{" "}
                    {groupMenuSetting[step - 1].label}{" "}
                  </Typography>
                </Container>
                <TabComponent
                  large
                  inModal
                  synopsisUrlLevel={synopsisUrlLevel}
                  submitButtonDisabled={!objHasData(synopsisValues)}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  isContributorsDataLoading={isContributorsDataLoading}
                  isLoadingSynopsisData={isLoadingSynopsisData}
                  contributorsData={latestContributors}
                  synopsisData={latestSynopsisValues}
                  hasContributorsChanged={contributorsChanged}
                  titleCcid={titleCcid}
                  seriesCcid={seriesCcid}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
