import { FC, useCallback, useState, SyntheticEvent, useEffect } from "react";
import {
  Typography,
  Stack,
  Box,
  Autocomplete,
  Container,
  TextField,
  Button,
  Card,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import { Close } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import {
  useUpdateStartupProductionDetails,
  useSearchLabelUsers,
  useProductionDetails,
} from "../../hooks";
import { User } from "../../types/types";
import { SnackbarDismiss } from "../../componentsV2/SnackBarDismiss";

interface ProdLead {
  name: string;
  email: string;
  role?: string | null;
}

export const AssignProductionLead: FC = () => {
  const { seriesCcid, titleCcid } = useParams<string>();
  const ccid = titleCcid || seriesCcid;
  const type = seriesCcid ? "series" : "titles";
  const { data: startupFormProductionData } = useProductionDetails(ccid, type);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { oktaAuth } = useOktaAuth();
  const {
    mutate: updateProductionLead,
    isLoading: loadingUpdateProductionStatus,
  } = useUpdateStartupProductionDetails(
    () => {
      queryClient.invalidateQueries(["startupProductionDetails", type, ccid]);
      enqueueSnackbar("Production lead updated", { variant: "success" });
      navigate("../");
    },
    () => {
      enqueueSnackbar(
        `there was an error saving the production lead for this show`,
        { variant: "error", action: SnackbarDismiss },
      );
    },
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { data: usersFromSearch, isLoading } = useSearchLabelUsers(
    searchTerm,
    15,
  );

  const [selectedUser, setSelectedUser] = useState<ProdLead | undefined>();
  const [localUserDetails, setLocalUserDetails] = useState<
    ProdLead | undefined
  >();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputChangeHandler = useCallback((event: any) => {
    if (event?.target?.value) {
      setSearchTerm(event.target.value);
    } else {
      setSearchTerm("");
    }
  }, []);

  useEffect(() => {
    const setLocalUser = async () => {
      const loggedInUser = await oktaAuth.getUser();
      setLocalUserDetails({
        name: loggedInUser.name as string,
        email: loggedInUser.email as string,
      });
    };
    setLocalUser();
  }, [oktaAuth]);

  useEffect(() => {
    if (
      startupFormProductionData &&
      startupFormProductionData.productionContactName
    ) {
      setSelectedUser({
        name: startupFormProductionData.productionContactName,
        email: startupFormProductionData.productionContactEmail,
      });
    }
  }, [startupFormProductionData]);

  const handleSelect = (
    _event: SyntheticEvent<Element, Event>,
    value: { label: string; user: User } | null,
  ) => {
    if (value?.user)
      setSelectedUser({
        name: `${value.user.firstName} ${value.user.lastName}`,
        email: value.user.email,
        role: value.user.role,
      });
    setSearchTerm("");
  };

  const handleAddProdLead = async () => {
    if (ccid && selectedUser) {
      updateProductionLead({
        ccid,
        formData: {
          productionContactName: selectedUser.name,
          productionContactEmail: selectedUser.email,
        },
        type,
      });
    }
  };

  return (
    <Container sx={{ p: 2 }}>
      <Stack
        direction="column"
        spacing={2}
        sx={{ backgroundColor: "white", p: 2 }}
      >
        <Typography display="inline" variant="h4">
          Add a Production Lead:
        </Typography>
        <Typography display="inline">
          The production lead would be responsible for completing the new
          production notification form and assigning other collaborators to this
          series.
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography display="inline" variant="h5">
              Search and find a user
            </Typography>
          </Stack>
        </Stack>
        <Box p={1}>
          <Stack spacing={2} direction="column">
            <Autocomplete
              id="search-users"
              inputValue={searchTerm}
              clearIcon={null}
              popupIcon={null}
              options={
                usersFromSearch
                  ? usersFromSearch.map((user) => ({
                      label: `${user.firstName} ${user.lastName} ${
                        user.role ? `(${user.role})` : ""
                      }`,
                      user,
                    }))
                  : []
              }
              onInputChange={inputChangeHandler}
              onChange={handleSelect}
              loading={isLoading || loadingUpdateProductionStatus}
              renderInput={(params) => (
                <TextField {...params} label="Search for a user" />
              )}
            />
            <Typography
              display="inline"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
                px: 1,
                opacity: "85%",
              }}
              variant="body1"
              onClick={() => {
                if (localUserDetails)
                  setSelectedUser({
                    name: localUserDetails.name,
                    email: localUserDetails.email,
                  });
              }}
            >
              Assign to myself
            </Typography>
          </Stack>
          {selectedUser ? (
            <Card key={selectedUser?.email} sx={{ my: 1 }}>
              <ListItem
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => setSelectedUser(undefined)}
                  >
                    <Close />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={selectedUser?.name}
                  secondary={selectedUser?.email}
                />
              </ListItem>
            </Card>
          ) : (
            <></>
          )}
        </Box>
        <Stack direction="row" justifyContent="right" spacing={2} pb={2}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => navigate("/")}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddProdLead}
          >
            Assign
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};
