import { useMutation } from "react-query";
import { updateRiskFormData, updateRiskFormDataNew } from "../../api/spotlight";
import { type NotificationFormValues } from "../../containers/NotificationForm/getInitialNotificationFormValues";
import { noop } from "../../utils/appHelper";

type PartialFormData = {
  [FormId in keyof NotificationFormValues]?: Partial<
    NotificationFormValues[FormId]
  >;
};

export interface UseUpdateRiskArgs {
  riskCcid: string;
  formData: PartialFormData;
  productionType: "series" | "titles";
}

export const useUpdateRisk = (onSuccess = noop, onError = noop) =>
  useMutation(
    ({ riskCcid, formData, productionType }: UseUpdateRiskArgs) =>
      updateRiskFormData(riskCcid, formData, productionType),
    { onSuccess, onError },
  );

export interface UseUpdateRiskArgsNew {
  riskCcid: string;
  formData: PartialFormData;
  version: "v2" | "v3";
}

export const useUpdateRiskNew = ({ onSuccess = noop, onError = noop }) =>
  useMutation(
    ({ riskCcid, formData, version }: UseUpdateRiskArgsNew) =>
      updateRiskFormDataNew({ ccid: riskCcid, formData, version }),
    { onSuccess, onError },
  );
